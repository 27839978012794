import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { ThreadFilterEnum } from "src/generated/api_types";
import { nsMedicaidInbox } from "src/i18n/Namespaces";
import { EmptyInbox } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListContainer from "src/pages/inbox/inboxComponents/ThreadListContainer";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import AssistiveRoleViewThread from "src/pages/inbox/views/AssistiveRoleViewThread";

const ThreadsWithUser = () => {
  const { isAssistiveRoleView } = useUserSession();
  const { setSelectedThreadId, setContextOtherUserId, setSearchQuery, setInboxSidePanelUserId } = useInboxContext();
  const { userId: paramOtherUserId } = useParams<{ userId: string }>();
  const { t, ready } = useTranslation([nsMedicaidInbox]);

  if (isAssistiveRoleView()) {
    if (!paramOtherUserId) return <AssistiveRoleViewThread />;
    setContextOtherUserId(paramOtherUserId);
    setSelectedThreadId(undefined);
    setSearchQuery("");
    setInboxSidePanelUserId(undefined);
    return <Redirect to={MEDICAID_INBOX_ROUTES.allThreads} />;
  }

  return (
    <ThreadListContainer
      threadFilter={ThreadFilterEnum.ALL}
      loadingText={ready ? t("loading.conversations.label", { ns: nsMedicaidInbox }) : ""}
    >
      <EmptyInbox title={t("no_messages_found.label", { ns: nsMedicaidInbox })} />
    </ThreadListContainer>
  );
};

export default ThreadsWithUser;
