import { Redirect, Route, Switch } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import Inbox from "src/pages/inbox/Inbox";
import { InboxTabState } from "src/pages/inbox/InboxContext";
import AllThreadsTabView from "src/pages/inbox/views/AllThreadsTabView";
import ArchivedThreadsTabView from "src/pages/inbox/views/ArchivedThreadsTabView";
import AssistiveRoleThreadsByClientView from "src/pages/inbox/views/AssistiveRoleThreadsByClientView";
import StarredThreadsTabView from "src/pages/inbox/views/StarredThreadsTabView";
import ThreadsWithUser from "src/pages/inbox/views/ThreadsWithUser";
import UnreadThreadsTabView from "src/pages/inbox/views/UnreadThreadsTabView";
import ViewThread from "./views/ViewThread";

export const MEDICAID_INBOX_PREFIX = "/homecare/medicaid/inbox";
export const MEDICAID_INBOX_ROUTES = {
  root: `${MEDICAID_INBOX_PREFIX}`,
  allThreads: `${MEDICAID_INBOX_PREFIX}/all`,
  unreadThreads: `${MEDICAID_INBOX_PREFIX}/unread`,
  starredThreads: `${MEDICAID_INBOX_PREFIX}/starred`,
  archivedThreads: `${MEDICAID_INBOX_PREFIX}/archived`,
  viewThread: `${MEDICAID_INBOX_PREFIX}/thread/:threadId`,
  threadsWithUser: `${MEDICAID_INBOX_PREFIX}/threads/user/:userId`,
  clientThreads: `${MEDICAID_INBOX_PREFIX}/threads/client`,
};

const InboxRouter = () => {
  const { isAssistiveRoleView } = useUserSession();

  return (
    <Switch>
      <Redirect exact path={MEDICAID_INBOX_ROUTES.root} to={MEDICAID_INBOX_ROUTES.allThreads} />
      <Route exact path={MEDICAID_INBOX_ROUTES.allThreads}>
        <Inbox tab={InboxTabState.all_threads}>
          <AllThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.unreadThreads}>
        <Inbox tab={InboxTabState.unread_threads}>
          <UnreadThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.starredThreads}>
        <Inbox tab={InboxTabState.starred_threads}>
          <StarredThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.archivedThreads}>
        <Inbox tab={InboxTabState.archived_threads}>
          <ArchivedThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.viewThread}>
        <Inbox
          // TODO: Tab state should be determined by thread's archived state: if thread is archived, set tab to archived
          // https://github.com/CarinaWeb/CarinaCore/issues/998
          tab={InboxTabState.all_threads}
        >
          <ViewThread />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.threadsWithUser}>
        <Inbox tab={InboxTabState.all_threads}>
          <ThreadsWithUser />
        </Inbox>
      </Route>

      {isAssistiveRoleView() && (
        <Route exact path={MEDICAID_INBOX_ROUTES.clientThreads}>
          <Inbox tab={InboxTabState.client_threads}>
            <AssistiveRoleThreadsByClientView />
          </Inbox>
        </Route>
      )}
      <Redirect to={MEDICAID_INBOX_ROUTES.allThreads} />
    </Switch>
  );
};

export default InboxRouter;
