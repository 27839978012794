import { alpha, Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { nsMedicaidInbox } from "src/i18n/Namespaces";
import { EmptyInbox } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListContainer from "src/pages/inbox/inboxComponents/ThreadListContainer";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import ThreadOtherUserDetails from "src/pages/inbox/views/ThreadOtherUserDetails";
import ViewThread from "src/pages/inbox/views/ViewThread";
import Constraint from "src/reusable_view_elements/Constraint";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const ThreadsForJobClientTabView = () => {
  const {
    inboxSidePanelUserId,
    totalThreads,
    loadingThreads,
    searchedTerm,
    wereThreadsSearched,
    setWereThreadsSearched,
    searchQuery,
    setSearchQuery,
    byClientJobNumber,
  } = useInboxContext();
  const { t, ready } = useTranslation(nsMedicaidInbox);
  useEffect(() => {
    byClientJobNumber && setSearchQuery(byClientJobNumber);
    setWereThreadsSearched(false);
    return () => setSearchQuery(""); // Cleanup so it does not affect other tabs
  }, []);
  if (!searchQuery) return <LoadingCircle />;
  if (wereThreadsSearched && loadingThreads === false && totalThreads === 0 && searchedTerm.length === 0) {
    return (
      <Constraint
        height="809px"
        columns={12}
        sx={{ marginBottom: "40px", border: `1px solid ${alpha(CivColors.mediumGray, 0.5)}` }}
      >
        <Box height="100%" width="100%" alignContent="center" textAlign="center">
          {ready ? (
            <>
              <SectionTitle>{t("no_conversations_in_tab.description", { ns: nsMedicaidInbox })}</SectionTitle>
            </>
          ) : (
            <LoadingCircle />
          )}
        </Box>
      </Constraint>
    );
  }
  return (
    <Constraint
      maxWidth="1280px"
      columns={12}
      sx={{ marginBottom: "40px", border: `1px solid ${alpha(CivColors.mediumGray, 0.5)}` }}
    >
      <Grid container sx={{ minHeight: "809px" }}>
        {/************************************** Left Panel *****************************************/}
        <Grid item xs={3} sx={{ height: "100%" }}>
          <ThreadListContainer loadingText={ready ? t("loading.all_conversations.label", { ns: nsMedicaidInbox }) : ""}>
            <EmptyInbox title={t("you_have_zero_messages.label", { ns: nsMedicaidInbox })} />
          </ThreadListContainer>
        </Grid>

        {/************************************** Middle Panel ***************************************/}
        <Grid
          item
          xs={inboxSidePanelUserId ? 6 : 9}
          sx={{ borderLeft: `1px solid ${alpha(CivColors.mediumGray, 0.5)}`, height: "auto" }}
        >
          <ViewThread />
        </Grid>

        {/************************************** Right Panel ****************************************/}
        {inboxSidePanelUserId && (
          <Grid item xs={3} sx={{ overflowY: "auto", height: "809px" }}>
            <ThreadOtherUserDetails profileId={inboxSidePanelUserId} />
          </Grid>
        )}
      </Grid>
    </Constraint>
  );
};

export default ThreadsForJobClientTabView;
