import { Redirect, Route, Switch } from "react-router-dom";
import ThreadMatchingSurvey from "src/pages/inbox/ThreadMatchingSurvey";
import CreateEditRepostJob from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJob";
import { JobFormType } from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJobForm";
import DeleteJobSurvey from "src/pages/medicaidAccount/consumerAccount/DeleteJobSurvey";
import MyDeletedJobList from "src/pages/medicaidAccount/consumerAccount/MyDeletedJobList";
import MyJobList from "src/pages/medicaidAccount/consumerAccount/MyJobList";
import { RenewJobPost } from "src/pages/medicaidAccount/consumerAccount/RenewJobPost";
import { PreVerifyJobPost } from "src/pages/medicaidAccount/coordinatorAccount/PreVerifyJobPost";
import JobDetails from "src/pages/medicaidAccount/JobDetails";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import ClientTutorial from "src/pages/tutorials/ClientTutorial";

export interface CaseManagerRoutes {
  root: string;
  tutorial: string;
  matchSurvey: string;
  myJobList: string;
  myJobDetails: string;
  jobEdit: string;
  jobDelete: string;
  jobRenew: string;
  preVerifyJobPost: string;
  preVerifyJobRepost: string;
  jobCreate: string;
  myDeletedJobs: string;
  myDeletedJobDetails: string;
  jobRepost: string;
}

const MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX = "/homecare/medicaid/casemanager";
export const Medicaid_Case_Manager_Routes: CaseManagerRoutes = {
  root: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}`,
  tutorial: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/tutorial`,
  matchSurvey: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/match-survey/thread/:id`,

  myJobList: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/jobs`,
  myJobDetails: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/jobs/:id`,
  jobEdit: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/jobs/:id/edit`,
  jobDelete: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/jobs/:id/delete`,
  jobRenew: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/jobs/:id/renew`,
  preVerifyJobPost: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/job/preverify`,
  preVerifyJobRepost: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/job/preverify/:id`,
  jobCreate: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/job/create/:verificationId?`,

  myDeletedJobs: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/deleted/jobs`,
  myDeletedJobDetails: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/deleted/jobs/:id`,
  jobRepost: `${MEDICAID_ACCOUNT_CASE_MANAGER_PREFIX}/deleted/jobs/repost/:id/:verificationId`,
};

const CaseManagerRouter = () => {
  return (
    <Switch>
      <Route exact path={Medicaid_Case_Manager_Routes.tutorial}>
        <ClientTutorial />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.myJobList}>
        <MyJobList />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.matchSurvey}>
        <ThreadMatchingSurvey />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.myJobDetails}>
        <JobDetails />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.myDeletedJobs}>
        <MyDeletedJobList />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.myDeletedJobDetails}>
        <JobDetails />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.preVerifyJobPost}>
        <PreVerifyJobPost />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.preVerifyJobRepost}>
        <PreVerifyJobPost />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.jobCreate}>
        <CreateEditRepostJob formType={JobFormType.JOB_CREATE} />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.jobEdit}>
        <CreateEditRepostJob formType={JobFormType.JOB_EDIT} />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.jobRepost}>
        <CreateEditRepostJob formType={JobFormType.JOB_REPOST} />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.jobDelete}>
        <DeleteJobSurvey />
      </Route>
      <Route exact path={Medicaid_Case_Manager_Routes.jobRenew}>
        <RenewJobPost />
      </Route>
      <Redirect to={MEDICAID_ACCOUNT_ROUTES.homepage} />
    </Switch>
  );
};

export default CaseManagerRouter;
