import { GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { SortDirection, SortInfo } from "src/generated/api_types";

/**
 * Helper Functions for MUI DataGrid
 **/

export function convertSortInfoToSortModel(sortInfo?: SortInfo): GridSortModel {
  if (!sortInfo) {
    return [];
  }

  return [
    {
      field: sortInfo.columnName,
      sort: sortInfo.direction.toLowerCase() as GridSortDirection,
    },
  ];
}

export function convertSortModelToSortInfo(model: GridSortModel): SortInfo | undefined {
  if (model.length === 0 || !model[0].sort) {
    return undefined;
  }

  return {
    columnName: model[0].field,
    direction: model[0].sort.toUpperCase() as SortDirection,
  };
}
