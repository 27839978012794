import { FastFieldProps } from "formik";
import { useState } from "react";
import {
  ControllableFastField,
  ControllableFastFieldProps,
} from "src/reusable_view_elements/form_fields/ControllableFastField";
import TextField, { TextFieldProps } from "src/reusable_view_elements/form_fields/TextField";

type FormTextFieldProps = {
  name: string;
} & TextFieldProps &
  Omit<ControllableFastFieldProps<any>, "languagePeek">;

export const FormTextField = (props: FormTextFieldProps) => {
  const { controlledRerender } = props;
  //currentLanguagePeek is used to detect when the consuming component changes displayed language.
  const [currentLanguagePeek, setCurrentLanguagePeek] = useState<string>(props.languagePeek);
  // Notice that 'incomingPeek' is used by 'FormTextField' and by 'ControllableFastField'. While in the first component it
  // triggers a field validation (thus fetching the updated localized error), in the second it triggers a re-render of the
  // children of the component
  const incomingPeek = props.label || props.placeholder || "--";
  return (
    <ControllableFastField name={props.name} languagePeek={incomingPeek} controlledRerender={controlledRerender}>
      {({ field, meta, form }: FastFieldProps<string>) => {
        //If the language has changed, and this field has a validation error and this field has been edited/touched...
        if (currentLanguagePeek !== props.label && meta.error && meta.touched) {
          // ...apply 'setFieldTouched'. This triggers validation again, which gets the updated localized error message.
          form.setFieldTouched(props.name);
          setCurrentLanguagePeek(incomingPeek);
        }
        return (
          //TODO: Evaluate possibility of abstract a component that could be used here and in MultiAutocompleteAndChipsField
          <TextField
            {...props}
            id={props.name.replace(/\.([a-z])/, (match, capture) => capture.toUpperCase())}
            inputProps={{ ...props.inputProps, "data-testid": `${props.name}-field` }}
            value={field.value}
            maximumlength={props.maximumlength}
            onChange={(e) => {
              //Next line enables error to be displayed for multiline fields when the user reaches maxLength
              props.multiline && !meta.touched && form.setFieldTouched(props.name);
              field.onChange(e);
            }}
            onBlur={field.onBlur}
            helperText={meta.touched && meta.error ? meta.error : props.helperText}
            error={Boolean(meta.touched && meta.error)}
            onClick={() => {
              //Next line is a workaround to trigger 'touched' on 'select' fields when the user just opens the menu
              props.select && props.name && form.setFieldTouched(props.name);
            }}
          />
        );
      }}
    </ControllableFastField>
  );
};
