/* eslint-disable max-len */
import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link, useHistory } from "react-router-dom";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  MedicaidProviderProfile_DTO_Request_ProfileDetails,
  MedicaidProviderProfile_DTO_Response_ProfileDetails,
  ProviderProfileTagEnum,
} from "src/generated/api_types";
import {
  nsCommonAlt,
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidProviderProfile,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { Medicaid_Provider_Dashboard_Routes } from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import { updateProfilePhoto, updateProviderProfile } from "src/pages/medicaidAccount/providerAccount/Queries";
import { Medicaid_Proxy_Provider_Routes } from "src/pages/medicaidAccount/ProxyProvider/ProxyProviderRouter";
import { getTagsObject } from "src/pages/medicaidAccount/sharedComponents/ProviderProfileTagHelpers";
import { getProviderProfile } from "src/pages/medicaidAccount/sharedComponents/Queries";
import {
  AgeRangeOptions,
  AvailabilityOptions,
  CareFrequencyOptions,
  GeneralSkillsOptions,
  handleConsumerGenderChange,
  handleProviderGenderChange,
  isConsumerGenderCheckboxDisabled,
  isProviderGenderCheckboxDisabled,
  LegacyLanguage,
  MedicaidProfileFormFields,
  MovementSkillsOptions,
  PetOptions,
  PreferredConsumerGenderOptions,
  PronounsOptions,
  ProviderGenderOptions,
  SpecializedSkillsOptions,
  TransportSkillsOptions,
  TypeOfSupportOptions,
  useMedicaidProfileForm,
  WorkEnvOptions,
} from "src/pages/onboarding/step_pages/MedicaidProfile";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Avatar from "src/reusable_view_elements/Avatar";
import Constraint from "src/reusable_view_elements/Constraint";
import DashSection from "src/reusable_view_elements/DashSection";
import Footer from "src/reusable_view_elements/Footer";
import { FormMultiSelectChipsField, FormRadioGroupField, FormTextField } from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import { FormCheckboxGroupField } from "src/reusable_view_elements/form_fields/FormCheckboxGroupField";
import LongForm from "src/reusable_view_elements/form_fields/LongForm";
import { MultiAutocompleteAndChipsField } from "src/reusable_view_elements/form_fields/MultiAutocompleteAndChipsField";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import { Button } from "src/reusable_view_elements/Button";
import GenderPopover from "src/reusable_view_elements/popovers/GenderPopover";
import LgbtPopover from "src/reusable_view_elements/popovers/LgbtPopover";
import NurseDelegationPopover from "src/reusable_view_elements/popovers/NurseDelegationPopover";
import PronounsPopover from "src/reusable_view_elements/popovers/PronounsPopover";
import Section from "src/reusable_view_elements/Section";
import InteractiveWrapper from "src/reusable_view_elements/InteractiveWrapper";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import CivTheme from "src/themes/civilization/CivTheme";
import { cleanBase64String, imageExists, isRequiredField } from "src/utilities/GeneralUtilities";
import { number, object, string } from "yup";

export function getUserFriendlyFileSize(bytes: number): string {
  if (bytes <= 1000) {
    // if size is under 1kb, show as bytes
    return `${bytes} bytes`;
  }
  if (bytes <= 1000 * 1000) {
    // if size is under 1mb, show as kb
    return `${(bytes / 1000).toFixed(1)} KB`;
  }
  // otherwise show as mb
  return `${(bytes / (1000 * 1000)).toFixed(1)} MB`;
}

const EditProviderProfile = () => {
  const { userSession } = useUserSession();

  if (userSession?.id) {
    return <ProviderProfileEdit id={userSession.id} />;
  }

  return (
    <main id="main-content">
      <Box minHeight="50vh">
        <Section>
          <Constraint columns={8} textAlign="center">
            <SectionTitle paragraph>Request Forbidden</SectionTitle>
            <Body paragraph>No user session id found.</Body>
            <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.homepage}>
              Back to Home
            </LinkStyledAsButton>
          </Constraint>
        </Section>
      </Box>
    </main>
  );
};

interface EditProviderProfileProps {
  id: string;
}

const ProviderProfileEdit = ({ id }: EditProviderProfileProps) => {
  const { isInEnv } = useConfig();
  const { showSnackbar } = useNotification();
  const { isUserType } = useUserSession();
  const history = useHistory();
  const { t, ready } = useTranslation([
    nsMedicaidProviderProfile,
    nsCommonFormsBtns,
    nsCommonToasts,
    nsMedicaidTagsEnums,
    nsCommonAlt,
  ]);

  const [isLoadingProfile, setLoadingProfile] = useState(false);
  const [isSavingProfile, setSavingProfile] = useState(false);
  const [isUploadingPhoto, setUploadingPhoto] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [fileString, setFileString] = useState<string>("");
  const [fileSizeBytes, setFileSizeBytes] = useState<number | undefined>();
  const [fileSizeError, setFileSizeError] = useState<string | undefined>();

  const [providerFirstName, setProviderFirstName] = useState("");
  const [providerLastName, setProviderLastName] = useState("");
  const [providerHasImg, setProviderHasImg] = useState(false);
  const [providerProfile, setProviderProfile] = useState<MedicaidProviderProfile_DTO_Response_ProfileDetails>();

  const { SCHEDULE_MAX, FURTHER_INTRO_MAX } = useMedicaidProfileForm();

  const validationSchema = object({
    hours: number()
      .label(t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns }))
      .when("availability", {
        is: (val: ProviderProfileTagEnum) => val === ProviderProfileTagEnum.SEEKING,
        then: number()
          .label(t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns }))
          .required(t("field.available_monthly_hours.error", { ns: nsCommonFormsBtns }))
          .max(320, t("field.available_monthly_hours.error_max", { ns: nsCommonFormsBtns }))
          .min(0, t("field.available_monthly_hours.error_min", { ns: nsCommonFormsBtns })),
      })
      .nullable(),
    scheduleNotes: string()
      .label(t("field.care_schedule.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(SCHEDULE_MAX, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    gender: string()
      .label(t("gender_s.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    consumerGender: string()
      .label(t("consumer_gender_s.description", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    languages: string()
      .label(t("field.languages.label", { ns: nsCommonFormsBtns }))
      .required(t("field.languages.error", { ns: nsCommonFormsBtns })),
    typeOfCare: string()
      .label(t("general_info.willing_to_provide.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    ageRange: string()
      .label(t("general_info.willing_to_care_for.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    typeOfSupport: string()
      .label(
        t("general_info.willing_to_provide_support_for.label", {
          ns: nsMedicaidProviderProfile,
        }),
      )
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    generalSkills: string()
      .label(t("skills_and_experience.general_care.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    workEnvironment: string()
      .label(t("work_environment.scents_smoking_pref.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    specializedSkills: string()
      .label(t("field.specialized_skill.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    furtherIntroduction: string()
      .label(t("field.hobbies_and_further_info.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(FURTHER_INTRO_MAX, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
  });

  useEffect(() => {
    (async () => {
      try {
        setLoadingProfile(true);

        const res = await getProviderProfile(id);

        if (res.data.hasImg) {
          setProviderHasImg(true);
          const profileImage = `https://s3.us-west-2.amazonaws.com/${
            isInEnv(EnvName.PROD) ? "production.carina.profile.photos" : "carina.profile.photos"
          }/${id}.jpg`;
          imageExists(profileImage, (exists) => {
            if (exists) {
              setImageUrl(profileImage);
            }
          });
        }

        setProviderProfile(res.data);
        setProviderFirstName(res.data.provider.firstName);
        setProviderLastName(res.data.provider.lastName);
        setProviderFirstName(res.data.provider.firstName);
        setProviderLastName(res.data.provider.lastName);
      } catch (e) {
        showSnackbar(
          t("error.loading_your_profile", "Something went wrong while loading your profile", { ns: nsCommonToasts }),
          "error",
        );
      } finally {
        setLoadingProfile(false);
      }
    })();
  }, []);

  const tagsObj = getTagsObject(providerProfile);
  const initialValues: MedicaidProfileFormFields = providerProfile
    ? {
        availability: providerProfile.availability,
        consumerGender: tagsObj.consumerGenderTag,
        furtherIntroduction: providerProfile.furtherIntroNotes,
        gender: tagsObj.providerGenderTag,
        hours: providerProfile.hours,
        hoursFlexible: providerProfile.hoursFlexible,
        languages: tagsObj.languageTags,
        pets: tagsObj.petTags,
        scheduleFlexible: providerProfile.schedulingFlexible,
        scheduleNotes: providerProfile.schedulingNotes,
        generalSkills: tagsObj.generalSkillTags,
        movementSkills: tagsObj.movementSkillTags,
        transportSkills: tagsObj.transportSkillTags,
        specializedSkills: tagsObj.specializedSkillTags,
        typeOfCare: tagsObj.typeOfCareTag,
        typeOfSupport: tagsObj.typeOfSupportTags,
        ageRange: tagsObj.ageRangeTags,
        generalWorkEnvironment: tagsObj.generalWorkEnvironmentTags,
        workEnvironment: tagsObj.workEnvironmentTags,
        pronouns: tagsObj.pronounTags,
      }
    : {
        availability: ProviderProfileTagEnum.SEEKING,
        consumerGender: [],
        furtherIntroduction: "",
        gender: [],
        hours: 0,
        hoursFlexible: false,
        languages: [],
        pets: [],
        scheduleFlexible: false,
        scheduleNotes: "",
        generalSkills: [],
        movementSkills: [],
        transportSkills: [],
        specializedSkills: [],
        typeOfCare: [],
        typeOfSupport: [],
        ageRange: [],
        generalWorkEnvironment: [],
        workEnvironment: [],
        pronouns: [],
      };

  /**
   * Photo Upload Functions
   */

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      loadImageAsBase64String(selectedImage);
    }
  }, [selectedImage]);

  const loadImageAsBase64String = (file: File) => {
    let base64String = "";
    const reader = new FileReader();
    reader.onload = function fn() {
      if (typeof reader.result === "string") {
        base64String = cleanBase64String(reader.result);
        setFileString(base64String);
      }
    };
    reader.readAsDataURL(file);
  };

  /**
   * Handlers
   */

  const handlePhotoSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const fileSizeLimitMB = 3;
    const fileSizeLimitBytes = fileSizeLimitMB * 1000 * 1000;
    const file = e.target.files ? e.target.files[0] : null;
    if (file?.size && file.size <= fileSizeLimitBytes) {
      setFileSizeBytes(file.size);
      setSelectedImage(file);
      setFileSizeError(undefined);
    } else {
      setFileSizeError(
        ready
          ? t("field.upload_profile_picture.error", { ns: nsCommonFormsBtns, size: fileSizeLimitMB })
          : `Image must be ${fileSizeLimitMB} MB or less`,
      );
    }
  };

  const imageUploader = useRef<any>(null);

  const handleSubmit = async (values: MedicaidProfileFormFields) => {
    const updateProfileTagArr = [
      ...values.gender,
      ...values.consumerGender,
      ...values.typeOfCare,
      ...values.typeOfSupport,
      ...values.ageRange,
      ...values.languages,
      ...values.generalSkills,
      ...values.movementSkills,
      ...values.transportSkills,
      ...values.generalWorkEnvironment,
      ...values.workEnvironment,
      ...values.pets,
      ...values.specializedSkills,
      ...values.pronouns,
    ].map((it) => {
      return { tag: it as ProviderProfileTagEnum };
    });
    let updateVals: MedicaidProviderProfile_DTO_Request_ProfileDetails = {
      availability: values.availability,
      hours: values.hours!,
      hoursFlexible: values.hoursFlexible,
      schedulingNotes: values.scheduleNotes,
      schedulingFlexible: values.scheduleFlexible,
      furtherIntroNotes: values.furtherIntroduction,
      providerProfileTags: updateProfileTagArr,
      hasImg: providerHasImg,
    };

    try {
      if (fileString) {
        setUploadingPhoto(true);
        const res = await updateProfilePhoto(fileString);
        if (res.status === 200) {
          updateVals = { ...updateVals, hasImg: true };
        }
      }

      setSavingProfile(true);
      await updateProviderProfile(id, updateVals);
      const redirectPath = isUserType(UserType.ProxyProvider)
        ? Medicaid_Proxy_Provider_Routes.viewMyProfile
        : Medicaid_Provider_Dashboard_Routes.viewMyProfile;
      showSnackbar(
        {
          messageTitle: t("success.profile_updated_title", { ns: nsCommonToasts }),
          messageBody: t("success.profile_updated_description", { ns: nsCommonToasts }),
        },
        "success",
      );
      history.push(generatePath(redirectPath));
    } catch (e) {
      if (fileString) {
        showSnackbar(
          t(
            "error.couldnt_upload_photo",
            "Sorry, we couldn't upload your photo. Please check that it's a valid image type (JPEG) and less than 3 MB, then try again",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } else {
        showSnackbar(
          t("error.something_wrong_saving_profile", "Something went wrong saving your profile", {
            ns: nsCommonToasts,
          }),
          "error",
        );
      }
      setUploadingPhoto(false);
      setSavingProfile(false);
    }
  };

  const saveButtonCopy =
    ((isUploadingPhoto || isSavingProfile) && t("button.saving_profile", { ns: nsCommonFormsBtns })) ||
    t("button.save", { ns: nsCommonFormsBtns });
  const uploadPhotoButtonCopy = t("button.upload_profile_picture", { ns: nsCommonFormsBtns });

  if (!ready) {
    return (
      <>
        <Helmet>
          <title>Carina | Medicaid Profile Edit</title>
        </Helmet>
        <MedicaidNavbar />
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minHeight="80vh">
            <Constraint columns={8} textAlign="center">
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
        <Footer />
      </>
    );
  }

  const profileLanguages = LegacyLanguage.map((option) => ({
    label: t(option.locizeKey, { ns: option.namespace }),
    value: option.value,
  }));

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title.profile_edit", { ns: nsMedicaidProviderProfile })}</title>
      </Helmet>

      <MedicaidNavbar />

      <main id="main-content">
        <Section bgcolor={CivColors.lightGray} textAlign="center" style={{ paddingBottom: 0 }}>
          <Constraint columns={6}>
            <SectionTitle>{t("edit_my_profile.label", { ns: nsMedicaidProviderProfile })}</SectionTitle>
            <Body>{t("edit_my_profile.description", { ns: nsMedicaidProviderProfile })}</Body>
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray} style={{ paddingTop: 30 }}>
          <Constraint columns={8}>
            {isLoadingProfile ? (
              <Section bgcolor={CivColors.lightGray}>
                <LoadingCircle />
              </Section>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <LongForm localizationReady={ready}>
                      <Box textAlign="center">
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ marginBottom: "30px" }}
                          disabled={isSavingProfile || isUploadingPhoto}
                        >
                          {saveButtonCopy}
                        </Button>
                      </Box>
                      <Box textAlign="center">
                        <SectionTitle>
                          {providerFirstName} {providerLastName.charAt(0)}.
                        </SectionTitle>
                        <Avatar
                          src={imageUrl}
                          alt={
                            imageUrl
                              ? t("standard.headshot_alt", {
                                  ns: nsCommonAlt,
                                  name: providerFirstName + providerLastName.charAt(0),
                                })
                              : ""
                          }
                          style={{
                            width: "185px",
                            height: "185px",
                            margin: "0px auto",
                            fontSize: "3.2rem",
                          }}
                        >
                          {providerFirstName.charAt(0)}
                          {providerLastName.charAt(0)}
                        </Avatar>
                        {fileSizeBytes && (
                          <Body>
                            {t("file_size.label", { ns: nsMedicaidProviderProfile })}{" "}
                            {getUserFriendlyFileSize(fileSizeBytes)}
                          </Body>
                        )}
                        <label htmlFor="select-profile-picture">
                          <input
                            ref={imageUploader}
                            accept="image/jpeg"
                            type="file"
                            id="select-profile-picture"
                            onChange={handlePhotoSelection}
                            style={{ display: "none", height: "100px", width: "100px" }}
                          />
                          <Button
                            variant="contained"
                            type="button"
                            sx={{ marginTop: "24px", marginBottom: "12px" }}
                            disabled={isSavingProfile || isUploadingPhoto}
                            onClick={() =>
                              imageUploader.current && imageUploader.current.click && imageUploader.current.click()
                            }
                          >
                            {uploadPhotoButtonCopy}
                          </Button>
                          <Body paragraph>
                            {t("edit_my_profile.upload_prof_pic.description", { ns: nsMedicaidProviderProfile })}
                          </Body>
                          {fileSizeError && <Body color="error">{fileSizeError}</Body>}
                        </label>
                      </Box>
                      <Body>{t("indicated_required_field.label", { ns: nsMedicaidProviderProfile })}</Body>
                      <DashSection
                        bodyTitleLabel
                        label={t("availability.label", { ns: nsMedicaidProviderProfile })}
                        topBorder={false}
                      >
                        <FormRadioGroupField<string>
                          name="availability"
                          label={`${t("availability.are_you_available_provide_care.label", {
                            ns: nsMedicaidProviderProfile,
                          })} *`}
                          labelAlwaysOnTop
                          options={AvailabilityOptions}
                        />
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("schedule.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                      >
                        {values.availability === "SEEKING" && (
                          <>
                            <Grid item xs={12} id="hours-grid">
                              <FormTextField
                                type="number"
                                name="hours"
                                label={`${t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns })} *`}
                                inputProps={{ min: 0 }}
                                fullWidth
                                helperText={t("field.hrs_looking_work.helper_text", { ns: nsCommonFormsBtns })}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CheckboxField
                                name="hoursFlexible"
                                id="hoursFlexible"
                                label={t("field.avail_month_hours.hours_flexible.label", { ns: nsCommonFormsBtns })}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} id="scheduleNotes-grid">
                          <FormTextField
                            label={t("field.care_schedule.label", { ns: nsCommonFormsBtns })}
                            name="scheduleNotes"
                            placeholder={t("field.care_schedule.placeholder", { ns: nsCommonFormsBtns })}
                            multiline
                            maximumlength={SCHEDULE_MAX}
                            minRows={5}
                            helperText={""}
                            required={isRequiredField("scheduleNotes", validationSchema)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxField
                            name="scheduleFlexible"
                            id="scheduleFlexible"
                            label={t("field.care_schedule.days_times_flexible.label", { ns: nsCommonFormsBtns })}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("gender_s.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                      >
                        <Grid item xs={12} id="gender-grid">
                          <InteractiveWrapper
                            fieldName="gender"
                            sx={{ marginBottom: "24px" }}
                            languagePeek={t("gender_s.label", { ns: nsMedicaidProviderProfile })}
                          >
                            <Trans
                              t={t}
                              i18nKey="gender_s.description.check_all_apply"
                              ns={nsMedicaidProviderProfile}
                              components={{ popover: <GenderPopover /> }}
                              parent={Body}
                            />
                          </InteractiveWrapper>
                          <FormCheckboxGroupField<keyof typeof ProviderProfileTagEnum>
                            name="gender"
                            label={t("gender_s.label", { ns: nsMedicaidProviderProfile })}
                            options={ProviderGenderOptions}
                            checkboxesProps={{
                              onChange: (e) => handleProviderGenderChange(e, { values, setFieldValue }),
                              disabled: (checkboxValue) => isProviderGenderCheckboxDisabled(checkboxValue, { values }),
                            }}
                            required={isRequiredField("gender", validationSchema)}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("pronouns.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                      >
                        <Grid item xs={12}>
                          <InteractiveWrapper
                            fieldName="pronouns"
                            sx={{ marginBottom: "24px" }}
                            languagePeek={t("field.pronouns.label", { ns: nsMedicaidProviderProfile })}
                          >
                            <Trans
                              t={t}
                              i18nKey="pronouns.description.enter_your_pronouns"
                              ns={nsMedicaidProviderProfile}
                              components={{ popover: <PronounsPopover /> }}
                              parent={Body}
                            />
                          </InteractiveWrapper>
                          <Body paragraph>
                            {t("pronouns.description.you_can_add", { ns: nsMedicaidProviderProfile })}
                          </Body>
                          <Body paragraph>
                            {t("pronouns.description.pronouns_are_optional", { ns: nsMedicaidProviderProfile })}
                          </Body>
                          <MultiAutocompleteAndChipsField<ProviderProfileTagEnum>
                            name="pronouns"
                            label={t("field.pronouns.label", { ns: nsMedicaidProviderProfile })}
                            maxSelections={2}
                            noOptionsText={t("field.pronouns.no_options_text", { ns: nsMedicaidProviderProfile })}
                            reachedMaxText={t("field.pronouns.remove_existing_pronoun_text", {
                              ns: nsMedicaidProviderProfile,
                            })}
                            selectOptions={PronounsOptions.map((tag) => ({
                              label: t(tag.locizeKey, { ns: tag.namespace }),
                              value: tag.value,
                            }))}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("consumer_gender_s.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                      >
                        <Grid item xs={12} id="consumerGender-grid">
                          <FormCheckboxGroupField<keyof typeof ProviderProfileTagEnum>
                            name="consumerGender"
                            label={t("consumer_gender_s.description", { ns: nsMedicaidProviderProfile })}
                            options={PreferredConsumerGenderOptions}
                            checkboxesProps={{
                              onChange: (e) => handleConsumerGenderChange(e, { values, setFieldValue }),
                              disabled: (checkboxValue) => isConsumerGenderCheckboxDisabled(checkboxValue, { values }),
                            }}
                            required={isRequiredField("consumerGender", validationSchema)}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={`${t("languages.label", { ns: nsMedicaidProviderProfile })}`}
                        topBorder
                        container
                      >
                        <Grid item xs={12} id="languages-grid">
                          <MultiAutocompleteAndChipsField<ProviderProfileTagEnum>
                            name="languages"
                            label={`${t("field.languages.label", { ns: nsCommonFormsBtns })} *`}
                            disableDefaultSort
                            maxSelections={profileLanguages.length}
                            selectOptions={[
                              [...profileLanguages.filter((v) => v.value === ProviderProfileTagEnum.ENGLISH)],
                              [...profileLanguages.filter((v) => v.value !== ProviderProfileTagEnum.ENGLISH)].sort(
                                (a, b) => a.label.localeCompare(b.label),
                              ),
                            ].flat()}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("caregiving_prefs.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                      >
                        <Grid item xs={12} id="typeOfCare-grid">
                          <Body paragraph>
                            {`(${t("check_all_that_apply.label", {
                              ns: nsMedicaidProviderProfile,
                            })})`}
                          </Body>
                          <FormCheckboxGroupField<string>
                            name="typeOfCare"
                            label={t("general_info.willing_to_provide.label", { ns: nsMedicaidProviderProfile })}
                            options={CareFrequencyOptions}
                            required={isRequiredField("typeOfCare", validationSchema)}
                          />
                        </Grid>
                        <Grid item xs={12} id="ageRange-grid">
                          <FormCheckboxGroupField<string>
                            name="ageRange"
                            label={t("general_info.willing_to_care_for.label", { ns: nsMedicaidProviderProfile })}
                            options={AgeRangeOptions}
                            required={isRequiredField("ageRange", validationSchema)}
                          />
                        </Grid>
                        <Grid item xs={12} id="typeOfSupport-grid">
                          <FormCheckboxGroupField<string>
                            name="typeOfSupport"
                            label={t("general_info.willing_to_provide_support_for.label", {
                              ns: nsMedicaidProviderProfile,
                            })}
                            options={TypeOfSupportOptions}
                            required={isRequiredField("typeOfSupport", validationSchema)}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("care_skills.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                      >
                        <Grid item xs={12} id="generalSkills-grid">
                          <Body paragraph>
                            {t("skills_and_experience.description", { ns: nsMedicaidProviderProfile })}
                          </Body>
                          <FormCheckboxGroupField<string>
                            name="generalSkills"
                            label={t("skills_and_experience.general_care.label", { ns: nsMedicaidProviderProfile })}
                            options={GeneralSkillsOptions.map((tag) =>
                              tag.value === ProviderProfileTagEnum.NURSE_DELEGATION
                                ? {
                                    value: tag.value,
                                    label: (
                                      <NurseDelegationPopover>
                                        {t(tag.locizeKey, { ns: tag.namespace })}
                                      </NurseDelegationPopover>
                                    ),
                                  }
                                : tag,
                            )}
                            required={isRequiredField("generalSkills", validationSchema)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckboxGroupField<string>
                            name="movementSkills"
                            label={t("skills_and_experience.movement.label", { ns: nsMedicaidProviderProfile })}
                            options={MovementSkillsOptions}
                            required={isRequiredField("movementSkills", validationSchema)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckboxGroupField<string>
                            name="transportSkills"
                            label={t("skills_and_experience.transportation.label", { ns: nsMedicaidProviderProfile })}
                            options={TransportSkillsOptions}
                            required={isRequiredField("transportSkills", validationSchema)}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("work_environment.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                      >
                        <Grid item xs={12}>
                          <Body gutterBottom>
                            {`(${t("check_all_that_apply.label", {
                              ns: nsMedicaidProviderProfile,
                            })})`}
                          </Body>
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckboxGroupField<string>
                            name="generalWorkEnvironment"
                            label={t("work_environment.general.label", { ns: nsMedicaidProviderProfile })}
                            options={[
                              {
                                value: ProviderProfileTagEnum.MULTIGENERATIONAL_HOUSEHOLDS,
                                label: t("multigenerational_households", { ns: nsMedicaidTagsEnums }),
                              },
                              {
                                value: ProviderProfileTagEnum.MULTILINGUAL_HOUSEHOLDS,
                                label: t("multilingual_households", { ns: nsMedicaidTagsEnums }),
                              },
                              {
                                value: ProviderProfileTagEnum.LGBTQIA_HOUSEHOLDS,
                                label: (
                                  <LgbtPopover>{t("lgbtqia_households", { ns: nsMedicaidTagsEnums })}</LgbtPopover>
                                ),
                              },
                            ]}
                            required={isRequiredField("generalWorkEnvironment", validationSchema)}
                          />
                        </Grid>
                        <Grid item xs={12} id="workEnvironment-grid">
                          <FormCheckboxGroupField<string>
                            name="workEnvironment"
                            label={t("work_environment.scents_smoking_pref.label", { ns: nsMedicaidProviderProfile })}
                            options={WorkEnvOptions}
                            required={isRequiredField("workEnvironment", validationSchema)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckboxGroupField<string>
                            name="pets"
                            label={t("work_environment.pets_comfortable_with.label", { ns: nsMedicaidProviderProfile })}
                            options={PetOptions}
                            required={isRequiredField("pets", validationSchema)}
                          />
                        </Grid>
                      </DashSection>

                      <DashSection
                        bodyTitleLabel
                        label={t("personality.label", { ns: nsMedicaidProviderProfile })}
                        topBorder
                        container
                        bottomBorder={false}
                      >
                        <Grid item xs={12} id="specializedSkills-grid">
                          <Body style={{ marginBottom: CivTheme.spacing(2) }}>
                            {t("personality.description.specialized_care_skills", { ns: nsMedicaidProviderProfile })}
                          </Body>
                          <FormMultiSelectChipsField
                            name="specializedSkills"
                            label={`${t("field.specialized_skill.label", { ns: nsCommonFormsBtns })} *`}
                            selectOptions={SpecializedSkillsOptions}
                            helperText={t("field.specialized_skill.helper_text", { ns: nsCommonFormsBtns })}
                          />
                        </Grid>
                        <Grid item xs={12} id="furtherIntroduction-grid">
                          <FormTextField
                            label={t("field.hobbies_and_further_info.label", { ns: nsCommonFormsBtns })}
                            name="furtherIntroduction"
                            placeholder={t("field.hobbies_and_further_info.placeholder", { ns: nsCommonFormsBtns })}
                            multiline
                            minRows={5}
                            helperText={""}
                            maximumlength={FURTHER_INTRO_MAX}
                            required={isRequiredField("furtherIntroduction", validationSchema)}
                          />
                        </Grid>
                      </DashSection>
                      <FormAlert schema={validationSchema} sx={{ marginBottom: "64px" }} />
                      <Box textAlign="center" pt={3} pb={3}>
                        <LinkStyledAsButton variant="outlined" component={Link} to={MEDICAID_ACCOUNT_ROUTES.homepage}>
                          {t("button.cancel", { ns: nsCommonFormsBtns })}
                        </LinkStyledAsButton>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ marginLeft: "30px" }}
                          disabled={isSavingProfile || isUploadingPhoto}
                        >
                          {saveButtonCopy}
                        </Button>
                      </Box>
                    </LongForm>
                  );
                }}
              </Formik>
            )}
          </Constraint>
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default EditProviderProfile;
