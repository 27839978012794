import { BoxProps } from "@mui/material";
import { useUserSession } from "src/context/UserSessionContext";
import { RoleType } from "src/generated/api_types";
import AssistiveRoleThreadListCard from "src/pages/inbox/inboxComponents/AssistiveRoleThreadListCard";
import RegularThreadListCard from "src/pages/inbox/inboxComponents/RegularThreadListCard";

export interface ThreadListCardProps extends BoxProps {
  firstName: string;
  lastInitial?: string;
  organization?: string;
  roleType: RoleType;
  date: number;
  jobNum?: number; // Display friendly number
  jobId?: string; // Database id for job
  jobIsResolved?: boolean; // Determines whether to link to job details
  clientName?: string;
  avatarId?: string;
  message: string;
  unreadMessages?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
  hasImg?: boolean;
  threadId?: string;
}

const ThreadListCard = (props: ThreadListCardProps) => {
  const { isAssistiveRoleView } = useUserSession();

  if (isAssistiveRoleView()) return <AssistiveRoleThreadListCard {...props} />;

  return <RegularThreadListCard {...props} />;
};

export default ThreadListCard;
