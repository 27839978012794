import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Drawer, DrawerProps, Grid, IconButton, Tooltip as MuiTooltip, useMediaQuery } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { Hyperlink2Icon } from "src/assets/icons/StreamlineIcons";
import { useNotification } from "src/context/NotificationContext";
import { nsCommonAria, nsMedicaidProviderProfile, nsCommonToasts } from "src/i18n/Namespaces";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import ProviderProfileDetailsContent from "src/pages/medicaidAccount/sharedComponents/ProviderProfileDetailsContent";
import { BodyTitle } from "src/reusable_view_elements/Typography";
import theme from "src/themes/civilization/CivTheme";

interface ProviderProfileDrawerProps extends Omit<DrawerProps, "onClose"> {
  onClose: () => void;
  providerId?: string;
}

const ProviderProfileDrawer = (props: ProviderProfileDrawerProps) => {
  const { showSnackbar } = useNotification();
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsMedicaidProviderProfile, nsCommonAria, nsCommonToasts]);
  const profilePath = props.providerId
    ? generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, {
        id: props.providerId,
      })
    : "";

  const copyProfileToClipboard = async () => {
    await navigator.clipboard.writeText(window.location.origin + profilePath);
    showSnackbar(t("success.copied_profile_link", { ns: nsCommonToasts }), "success"); // TODO
  };

  const CopyToClipboardButton = (
    <MuiTooltip title={t("icon.copy_profile_link", { ns: nsCommonAria })} enterTouchDelay={0} leaveTouchDelay={8000}>
      <IconButton size="large" onClick={copyProfileToClipboard}>
        <Hyperlink2Icon />
      </IconButton>
    </MuiTooltip>
  );

  const GoToProfileButton = (
    <MuiTooltip
      title={t("icon.open_profile_new_window", { ns: nsCommonAria })}
      enterTouchDelay={0}
      leaveTouchDelay={8000}
    >
      <IconButton size="large" component={RouterLink} to={profilePath} target="_blank">
        <LaunchIcon />
      </IconButton>
    </MuiTooltip>
  );

  const CloseButton = (
    <IconButton onClick={props.onClose} size="large" aria-label={t("icon.close", { ns: nsCommonAria })}>
      <CloseRoundedIcon />
    </IconButton>
  );

  return (
    <Drawer open={props.open} onClose={props.onClose} anchor={onDesktop ? "right" : "bottom"}>
      {/* Header */}
      {ready && (
        <Box borderBottom="1px solid #989898CC" padding="18px">
          <Grid container alignItems="center" justifyContent="space-between">
            <BodyTitle>{t("profile_side_drawer.title", { ns: nsMedicaidProviderProfile })}</BodyTitle>
            {onDesktop ? (
              <Grid item>
                {CopyToClipboardButton}
                {GoToProfileButton}
                {CloseButton}
              </Grid>
            ) : (
              CloseButton
            )}
          </Grid>

          {!onDesktop && (
            <Grid container alignItems="center" justifyContent="center" spacing={4}>
              <Grid item>{CopyToClipboardButton}</Grid>
              <Grid item>{GoToProfileButton}</Grid>
            </Grid>
          )}
        </Box>
      )}

      {/* Profile */}
      <Box pl={onDesktop ? "84px" : "8px"} pr={onDesktop ? "84px" : "8px"}>
        {props.providerId && <ProviderProfileDetailsContent profileId={props.providerId} isDrawer />}
      </Box>
    </Drawer>
  );
};

export default ProviderProfileDrawer;
