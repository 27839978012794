import { Box, Card, CardContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import {
  CalendarIcon,
  ChatTranslateIcon,
  FireworksPeopleWatchIcon,
  PinLocation1Icon,
  TimeClockCircleAlternateIcon,
} from "src/assets/icons/StreamlineIcons";
import { ProviderProfileTagEnum } from "src/generated/api_types";
import { nsCommonAria, nsMedicaidProviderProfile, nsMedicaidSearch, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedProviderProfileTag } from "src/i18n/TagHelpers";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import {
  getTagsObject,
  getTagsObjectFromTags,
} from "src/pages/medicaidAccount/sharedComponents/ProviderProfileTagHelpers";
import IconList from "src/reusable_view_elements/IconList";
import IconListItemForCard from "src/reusable_view_elements/IconListItemForCard";
import { InternalLink } from "src/reusable_view_elements/Link";
import { ProviderCardProps } from "src/reusable_view_elements/provider_card/ProviderCard";
import TogglingBookmark from "src/reusable_view_elements/TogglingBookmark";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { getDaysSinceLastUpdate, getDistanceInMiles } from "src/utilities/GeneralUtilities";

const RegularProviderCard: React.FC<ProviderCardProps> = ({
  previewPageCard,
  profile,
  hideBookmark,
  buttonSection,
  onBookmark,
}) => {
  const { t, ready } = useTranslation([nsMedicaidProviderProfile, nsMedicaidSearch, nsMedicaidTagsEnums, nsCommonAria]);

  // Consolidating data from both ProviderListDTO and ProviderNotificationDTO types
  // "entity" property exists in ProviderListDTO, not in ProviderNotificationDTO
  const data = {
    id: "entity" in profile ? profile.entity.provider.id : profile.providerId,
    firstName: "entity" in profile ? profile.entity.provider.firstName : profile.firstName,
    lastName: "entity" in profile && profile.entity.provider.lastName,
    bookmarked: "entity" in profile && profile.entity.bookmarked,
    availability: "entity" in profile ? profile.entity.availability : profile.availability,
    geo: {
      city: "entity" in profile ? profile.entity.geoDataCityState.city : profile.city,
      state: "entity" in profile ? profile.entity.geoDataCityState.state : profile.state,
    },
    distance: "entity" in profile ? profile.distance : profile.distance,
    hours: "entity" in profile ? profile.entity.hours : profile.hours,
    hoursFlexible: "entity" in profile ? profile.entity.hoursFlexible : profile.hoursFlexible,
    schedulingNotes: "entity" in profile && profile.entity.schedulingNotes,
    schedulingFlexible: "entity" in profile ? profile.entity.schedulingFlexible : profile.schedulingFlexible,
    updatedAt: "entity" in profile && profile.entity.updatedAt,
    role: "entity" in profile && profile.entity.provider.role,
  };

  const tagsObject = "entity" in profile ? getTagsObject(profile.entity) : getTagsObjectFromTags(profile.profileTags);

  const SkillsAndExperience: ProviderProfileTagEnum[] = [
    ...tagsObject.generalSkillTags,
    ...tagsObject.movementSkillTags,
    ...tagsObject.transportSkillTags,
  ];

  function getTranslatedProviderTag(tag: ProviderProfileTagEnum): string {
    return t(getLocalizedProviderProfileTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  if (!ready) {
    return <></>;
  }

  return (
    <>
      <Card style={{ display: "grid", width: "100%", boxShadow: "none", borderRadius: 0 }}>
        <CardContent>
          <Grid container>
            <Grid item xs>
              <BodyTitle style={{ marginBottom: 4 }}>
                <InternalLink
                  to={generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, {
                    id: data.id,
                  })}
                >
                  {data.firstName} {data.lastName && `${data.lastName.charAt(0)}.`}
                </InternalLink>
              </BodyTitle>
              {tagsObject.pronounTags.length > 0 && !previewPageCard && (
                <Body style={{ marginTop: 12 }}>
                  {tagsObject.pronounTags.map((tag) => getTranslatedProviderTag(tag)).join(", ")}
                </Body>
              )}
              <Box
                px={0.5}
                py={0.5}
                mt={1}
                mb={1.5}
                bgcolor={
                  data.availability === ProviderProfileTagEnum.SEEKING ? CivColors.coreGold : CivColors.lightGray
                }
                borderRadius="4px"
                display="inline-block"
              >
                <BodyEmphasis>{getTranslatedProviderTag(data.availability)}</BodyEmphasis>
              </Box>
            </Grid>
            <Grid item>
              {!hideBookmark && onBookmark !== undefined && (
                <TogglingBookmark
                  aria-label={
                    data.bookmarked
                      ? t("icon.unsave_provider", { ns: nsCommonAria })
                      : t("icon.save_provider", { ns: nsCommonAria })
                  }
                  data-testid={`bookmark-icon-${data.id}`}
                  isSelected={!!data.bookmarked}
                  onClick={onBookmark}
                />
              )}
            </Grid>
          </Grid>
          <IconList>
            {/*If a provider has chosen "Prefer not to say" for their gender or card appears on preview page, hide this section. */}
            {!tagsObject.providerGenderTag.includes(ProviderProfileTagEnum.NO_GENDER_PREFERENCE) &&
              !previewPageCard && (
                <IconListItemForCard
                  icon={FireworksPeopleWatchIcon}
                  titleAccess={t("summary.providers_gender.label", { ns: nsMedicaidProviderProfile })}
                  secondary={
                    <Body>{tagsObject.providerGenderTag.map((tag) => getTranslatedProviderTag(tag)).join(", ")}</Body>
                  }
                />
              )}
            <IconListItemForCard
              icon={PinLocation1Icon}
              titleAccess={t("summary.location.label", { ns: nsMedicaidProviderProfile })}
              secondary={
                <Body>
                  {data.geo.city}, {data.geo.state} (
                  {t("count_miles_away.label", { ns: nsMedicaidSearch, count: getDistanceInMiles(profile.distance) })})
                </Body>
              }
            />
            <IconListItemForCard
              icon={TimeClockCircleAlternateIcon}
              titleAccess={t("summary.monthly_hours.label", { ns: nsMedicaidProviderProfile })}
              secondary={
                <Body>
                  {t("count_hours_per_month.label", {
                    ns: nsMedicaidProviderProfile,
                    count: data.hours,
                  })}{" "}
                  {data.hoursFlexible && <>({t("flexible.label", { ns: nsMedicaidSearch })})</>}
                </Body>
              }
            />
            {data.schedulingNotes && (
              <IconListItemForCard
                icon={CalendarIcon}
                titleAccess={t("summary.schedule.label", { ns: nsMedicaidProviderProfile })}
                secondary={
                  <Body style={{ overflowWrap: "anywhere" }}>
                    {data.schedulingNotes}{" "}
                    {data.schedulingFlexible && <>({t("flexible.label", { ns: nsMedicaidSearch })})</>}
                  </Body>
                }
              />
            )}
            <IconListItemForCard
              icon={ChatTranslateIcon}
              titleAccess={t("summary.languages.label", { ns: nsMedicaidProviderProfile })}
              secondary={<Body>{tagsObject.languageTags.map(getTranslatedProviderTag).join(", ")}</Body>}
            />
          </IconList>
          <BodyEmphasis style={{ marginTop: "8px" }}>
            {`${t("care_skills.label", { ns: nsMedicaidProviderProfile })}:`}
          </BodyEmphasis>
          <Body paragraph>{SkillsAndExperience.map((skill) => getTranslatedProviderTag(skill)).join(" • ")}</Body>
          {data.updatedAt && (
            <Body
              style={{
                textAlign: "right",
                color: CivColors.coreGray,
              }}
            >
              {t("last_updated_count_days_ago.card", {
                ns: nsMedicaidProviderProfile,
                count: getDaysSinceLastUpdate(data.updatedAt),
              })}
            </Body>
          )}
        </CardContent>
        <Box style={{ alignSelf: "self-end" }} p={2}>
          {buttonSection}
        </Box>
      </Card>
    </>
  );
};

export default RegularProviderCard;
