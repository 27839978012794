import { Box, BoxProps, Card, CardContent, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ChatTranslateIcon,
  CertifiedRibbon1Icon,
  PinLocation1Icon,
  TimeClockCircleAlternateIcon,
} from "src/assets/icons/StreamlineIcons";
import { ProviderCredential_DTO_Response_Details, ProviderProfileTagEnum } from "src/generated/api_types";
import { nsCommonAria, nsMedicaidProviderProfile, nsMedicaidSearch, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedProviderProfileTag } from "src/i18n/TagHelpers";
import { getTranslatedProviderCred } from "src/pages/medicaidAccount/sharedComponents/ProviderProfileDetailsContent";
import {
  getTagsObject,
  getTagsObjectFromTags,
  TagsObjectType,
} from "src/pages/medicaidAccount/sharedComponents/ProviderProfileTagHelpers";
import IconList from "src/reusable_view_elements/IconList";
import IconListItemForCard from "src/reusable_view_elements/IconListItemForCard";
import { ProviderCardProps } from "src/reusable_view_elements/provider_card/ProviderCard";
import TogglingBookmark from "src/reusable_view_elements/TogglingBookmark";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import stateAbbreviated, { getDaysSinceLastUpdate, getDistanceInMiles } from "src/utilities/GeneralUtilities";

const AssistiveRoleRegularProviderCard: React.FC<ProviderCardProps> = ({
  previewPageCard,
  profile,
  hideBookmark,
  buttonSection,
  onBookmark,
}) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsMedicaidProviderProfile, nsMedicaidSearch, nsMedicaidTagsEnums, nsCommonAria]);

  // Consolidating data from both ProviderListDTO and ProviderNotificationDTO types
  // "entity" property exists in ProviderListDTO, not in ProviderNotificationDTO
  const data = {
    id: "entity" in profile ? profile.entity.provider.id : profile.providerId,
    firstName: "entity" in profile ? profile.entity.provider.firstName : profile.firstName,
    lastName: "entity" in profile && profile.entity.provider.lastName,
    bookmarked: "entity" in profile && profile.entity.bookmarked,
    availability: "entity" in profile ? profile.entity.availability : profile.availability,
    geo: {
      city: "entity" in profile ? profile.entity.geoDataCityState.city : profile.city,
      state: "entity" in profile ? profile.entity.geoDataCityState.state : profile.state,
    },
    distance: "entity" in profile ? profile.distance : profile.distance,
    hours: "entity" in profile ? profile.entity.hours : profile.hours,
    hoursFlexible: "entity" in profile ? profile.entity.hoursFlexible : profile.hoursFlexible,
    schedulingNotes: "entity" in profile && profile.entity.schedulingNotes,
    schedulingFlexible: "entity" in profile ? profile.entity.schedulingFlexible : profile.schedulingFlexible,
    updatedAt: "entity" in profile && profile.entity.updatedAt,
    role: "entity" in profile && profile.entity.provider.role,
  };

  const tagsObject = "entity" in profile ? getTagsObject(profile.entity) : getTagsObjectFromTags(profile.profileTags);

  const credentials = "entity" in profile ? profile.entity.credentials : [];

  function getTranslatedProviderTag(tag: ProviderProfileTagEnum): string {
    return t(getLocalizedProviderProfileTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  if (!ready) {
    return <></>;
  }

  const AvailabilityTag = (props: { tag: string } & BoxProps) => (
    <Box
      px={0.5}
      ml={desktopSize ? 3 : 0}
      bgcolor={data.availability === ProviderProfileTagEnum.SEEKING ? CivColors.coreGold : CivColors.lightGray}
      borderRadius="4px"
      alignContent="center"
      display="inline-block"
      {...props}
    >
      <BodyEmphasis>{props.tag}</BodyEmphasis>
    </Box>
  );

  const genderAndPronouns = (tot: TagsObjectType): string => {
    const genAndPronArray = [];
    const genders =
      tagsObject.pronounTags.length > 0 && !previewPageCard
        ? tot.pronounTags.map((tag) => getTranslatedProviderTag(tag)).join(", ")
        : "";

    //If a provider has chosen "Prefer not to say" for their gender or card appears on preview page, hide this section.
    const pronouns =
      !tot.providerGenderTag.includes(ProviderProfileTagEnum.NO_GENDER_PREFERENCE) && !previewPageCard
        ? tot.providerGenderTag.map((tag) => getTranslatedProviderTag(tag)).join(", ")
        : "";
    if (genders.length > 0) genAndPronArray.push(genders);
    if (pronouns.length > 0) genAndPronArray.push(pronouns);
    return genAndPronArray.join(" • ");
  };

  const getCredential = (credential: ProviderCredential_DTO_Response_Details): string => {
    if (credential.code === "COMPLIANTRESPITE") {
      return t("training_credential.label_compliantrespite", { ns: nsMedicaidProviderProfile });
    } else if (credential.code === "FAMILY") {
      return t("training_credential.label_family", { ns: nsMedicaidProviderProfile });
    }
    const credLabelArray = t(getTranslatedProviderCred(credential).nameKey, { ns: nsMedicaidProviderProfile }).split(
      " ",
    );
    if (credLabelArray.length == 0) return "";
    const label = credLabelArray[0];
    if (label.length > 5) return credential.code;
    const isAbbreviation = label.charAt(label.length - 1) >= "A" && label.charAt(label.length - 1) <= "Z";
    return isAbbreviation ? credLabelArray[0] : credential.code;
  };

  const excludedCredentials = ["NEW", "UNKNOWN", "COMPLIANTLIMITED", "NONCOMPLIANT", "UNCERTIFIED"];

  const getCredentials = (creds: ProviderCredential_DTO_Response_Details[]): string => {
    let credsString = creds
      .filter((cred) => !excludedCredentials.includes(cred.code))
      .filter((cred) => cred.badge)
      .map((cr) => getCredential(cr))
      .join(", ");
    if (credsString.length > 15) credsString = credsString.substring(0, 15) + "...";
    return credsString;
  };

  const locationText = (): string => {
    let text = `${data.geo.city}, ${stateAbbreviated(data.geo.state)} (
      ${t("count_miles_away.label", {
        ns: nsMedicaidSearch,
        count: getDistanceInMiles(profile.distance),
      })})`;
    if (text.length > 40) text = `${text.substring(0, 38)}...`;
    return text;
  };

  return (
    <>
      <Card style={{ display: "grid", width: "100%", boxShadow: "none", borderRadius: 0 }}>
        <Box display="flex">
          <CardContent sx={{ padding: desktopSize ? 3 : 2, width: "100%" }}>
            <Grid container justifyContent="space-between">
              <Grid item xs>
                <Grid container justifyContent="space-between">
                  <Grid item xs={11} container direction="column">
                    <Grid item display="inline-flex">
                      <BodyTitle>
                        {data.firstName} {data.lastName && `${data.lastName.charAt(0)}.`}
                      </BodyTitle>
                      {desktopSize && <AvailabilityTag tag={getTranslatedProviderTag(data.availability)} />}
                    </Grid>
                    <Grid item>
                      {genderAndPronouns(tagsObject) && <Body mt="4px">{genderAndPronouns(tagsObject)}</Body>}
                      {!desktopSize && <AvailabilityTag tag={getTranslatedProviderTag(data.availability)} mt={1} />}
                    </Grid>
                  </Grid>
                  {!desktopSize && (
                    <Grid
                      item
                      xs={1}
                      mt={desktopSize ? -1.5 : 0}
                      mr={desktopSize ? -1.5 : 0}
                      display="flex"
                      justifyContent="right"
                      alignSelf="start"
                    >
                      {!hideBookmark && onBookmark !== undefined && (
                        <TogglingBookmark
                          aria-label={
                            data.bookmarked
                              ? t("icon.unsave_provider", { ns: nsCommonAria })
                              : t("icon.save_provider", { ns: nsCommonAria })
                          }
                          data-testid={`bookmark-icon-${data.id}`}
                          isSelected={!!data.bookmarked}
                          onClick={onBookmark}
                          // sx={{ p: 0 }}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>

                <Grid container justifyContent="space-between" direction={desktopSize ? "row" : "column"} mt="12px">
                  <Grid item xs={12} container gap="12px" mb={desktopSize ? 0 : 1}>
                    <Grid item xs={12} container gap={3}>
                      <Grid item xs>
                        <IconList>
                          <IconListItemForCard
                            icon={PinLocation1Icon}
                            titleAccess={t("summary.location.label", { ns: nsMedicaidProviderProfile })}
                            secondary={<Body>{locationText()}</Body>}
                            style={{ paddingBottom: "10px", paddingTop: "10px" }}
                          />
                          {!desktopSize && (
                            <>
                              <IconListItemForCard
                                icon={TimeClockCircleAlternateIcon}
                                titleAccess={t("summary.monthly_hours.label", { ns: nsMedicaidProviderProfile })}
                                secondary={
                                  <Body>
                                    {t("count_hours_per_month.label", {
                                      ns: nsMedicaidProviderProfile,
                                      count: data.hours,
                                    })}{" "}
                                    {data.hoursFlexible && <>({t("flexible.label", { ns: nsMedicaidSearch })})</>}
                                  </Body>
                                }
                              />
                              {getCredentials(credentials).length > 0 && !previewPageCard && (
                                <IconListItemForCard
                                  icon={CertifiedRibbon1Icon}
                                  titleAccess={t("summary.providers_gender.label", { ns: nsMedicaidProviderProfile })}
                                  secondary={<Body>{getCredentials(credentials)}</Body>}
                                />
                              )}
                            </>
                          )}
                          <IconListItemForCard
                            icon={ChatTranslateIcon}
                            titleAccess={t("summary.languages.label", { ns: nsMedicaidProviderProfile })}
                            secondary={<Body>{tagsObject.languageTags.map(getTranslatedProviderTag).join(", ")}</Body>}
                          />
                        </IconList>
                      </Grid>
                      {desktopSize && (
                        <Grid item xs>
                          <IconList>
                            {getCredentials(credentials).length > 0 && !previewPageCard && (
                              <IconListItemForCard
                                icon={CertifiedRibbon1Icon}
                                titleAccess={t("summary.providers_gender.label", { ns: nsMedicaidProviderProfile })}
                                secondary={<Body>{getCredentials(credentials)}</Body>}
                              />
                            )}
                            <IconListItemForCard
                              icon={TimeClockCircleAlternateIcon}
                              titleAccess={t("summary.monthly_hours.label", { ns: nsMedicaidProviderProfile })}
                              secondary={
                                <Body>
                                  {t("count_hours_per_month.label", {
                                    ns: nsMedicaidProviderProfile,
                                    count: data.hours,
                                  })}{" "}
                                  {data.hoursFlexible && <>({t("flexible.label", { ns: nsMedicaidSearch })})</>}
                                </Body>
                              }
                            />
                          </IconList>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {data.updatedAt && (
                        <Body style={{ textAlign: desktopSize ? "left" : "right", color: CivColors.coreGray }}>
                          {t("last_updated_count_days_ago.card", {
                            ns: nsMedicaidProviderProfile,
                            count: getDaysSinceLastUpdate(data.updatedAt),
                          })}
                        </Body>
                      )}
                    </Grid>
                  </Grid>
                  {!desktopSize && (
                    <Grid item xs={12} style={{ alignSelf: "center", maxWidth: "194px" }} p={2} pr="2px">
                      {buttonSection}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {desktopSize && (
                <Grid item xs={2} sx={{ justifyContent: "end", display: "flex" }} direction="column">
                  <Box display="block" justifyContent="right" alignSelf="end">
                    {!hideBookmark && onBookmark !== undefined && (
                      <TogglingBookmark
                        aria-label={
                          data.bookmarked
                            ? t("icon.unsave_provider", { ns: nsCommonAria })
                            : t("icon.save_provider", { ns: nsCommonAria })
                        }
                        data-testid={`bookmark-icon-${data.id}`}
                        isSelected={!!data.bookmarked}
                        onClick={onBookmark}
                        sx={{ "&.MuiButtonBase-root": { top: "-12px", right: "-12px" } }}
                      />
                    )}
                  </Box>
                  <Box style={{ alignSelf: "end", maxWidth: "194px" }} p={2} pr="2px">
                    {buttonSection}
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default AssistiveRoleRegularProviderCard;
