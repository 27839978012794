import { Grid, ListItem as MuiListItem, ListItemProps, ListItemTextProps, SvgIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IconListItemProps extends ListItemProps, Pick<ListItemTextProps, "primary" | "secondary"> {
  icon: React.ComponentType<SvgIconProps>;
  iconProps?: SvgIconProps;
}

const ListItem = styled(MuiListItem)({
  padding: "12px 0px",
  alignItems: "flex-start",
  lineHeight: 0, // Removes extra padding when containing Typography components
  "& svg": {
    width: 24,
    height: 24,
    marginRight: 10,
  },
});

// Used in Medicaid JobDetails and ProviderProfileDetails, usually as children of IconList
const IconListItem = ({ icon: Component, primary, secondary, iconProps, ...listItemProps }: IconListItemProps) => {
  return (
    <ListItem {...listItemProps}>
      <Component {...iconProps} />
      <Grid container spacing={1}>
        <Grid item>{primary}</Grid>
        {secondary && <Grid item>{secondary}</Grid>}
      </Grid>
    </ListItem>
  );
};

export default IconListItem;
