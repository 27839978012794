import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Drawer, DrawerProps, Grid, IconButton } from "@mui/material";
import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { nsCommonAria, nsMedicaidInbox } from "src/i18n/Namespaces";
import JobPostDetailsContent from "src/pages/medicaidAccount/sharedComponents/JobPostDetailsContent";
import ProviderProfileDetailsContent from "src/pages/medicaidAccount/sharedComponents/ProviderProfileDetailsContent";
import { BodyTitle } from "src/reusable_view_elements/Typography";

interface SideBySideJobPostAndProfileProps extends Omit<DrawerProps, "onClose"> {
  onClose: () => void;
  providerId?: string;
  jobPostId?: string;
}

const BORDER_STYLE = "1px solid #989898CC";
const PANEL_STYLE: CSSProperties = { height: "70vh", overflowY: "auto" };

const SideBySideJobPostAndProfile = (props: SideBySideJobPostAndProfileProps) => {
  const { t, ready } = useTranslation([nsCommonAria, nsMedicaidInbox]);

  return (
    <Drawer open={props.open} onClose={props.onClose} anchor="bottom">
      {/* Heading */}
      <Grid container alignItems="center" justifyContent="space-between" padding="18px">
        <BodyTitle>{t("side_by_side.compare_profile_with_job.title", { ns: nsMedicaidInbox })}</BodyTitle>

        <IconButton
          onClick={props.onClose}
          size="large"
          aria-label={ready ? t("icon.close", { ns: nsCommonAria }) : "Close"}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Grid>

      {/* Left and Right Panels */}
      <Grid container borderTop={BORDER_STYLE}>
        <Grid item xs={6} borderRight={BORDER_STYLE}>
          {props.jobPostId && <JobPostPanel jobPostId={props.jobPostId} />}
        </Grid>
        <Grid item xs={6}>
          {props.providerId && <ProviderPanel providerId={props.providerId} />}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SideBySideJobPostAndProfile;

interface JobPostPanelProps {
  jobPostId: string;
}

const JobPostPanel = (props: JobPostPanelProps) => {
  const { t, ready } = useTranslation(nsMedicaidInbox);
  const [firstName, setFirstName] = useState<string>();
  const [jobNumber, setJobNumber] = useState<number>();
  const title = t("job_number.label", { ns: nsMedicaidInbox, job_num: jobNumber }) + ` (${firstName})`;

  return (
    <>
      <Box textAlign="center" py={4} borderBottom={BORDER_STYLE}>
        {ready && <BodyTitle>{title}</BodyTitle>}
      </Box>
      <Box style={{ ...PANEL_STYLE, paddingTop: "50px" }}>
        {props.jobPostId && (
          <JobPostDetailsContent
            onlyDetails
            jobPostId={props.jobPostId}
            setFirstName={setFirstName}
            setJobNumber={setJobNumber}
          />
        )}
      </Box>
    </>
  );
};

interface ProviderPanelProps {
  providerId: string;
}

const ProviderPanel = (props: ProviderPanelProps) => {
  const { t, ready } = useTranslation(nsMedicaidInbox);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const title = t("side_by_side.provider_panel.title", {
    ns: nsMedicaidInbox,
    first_name: firstName,
    last_name: lastName,
  });

  return (
    <>
      <Box textAlign="center" py={4} borderBottom={BORDER_STYLE}>
        {ready && firstName && <BodyTitle>{title}</BodyTitle>}
      </Box>
      <Box style={PANEL_STYLE}>
        {props.providerId && (
          <ProviderProfileDetailsContent
            hideActions
            profileId={props.providerId}
            setFirstName={setFirstName}
            setLastName={setLastName}
          />
        )}
      </Box>
    </>
  );
};
