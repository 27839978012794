import _ from "lodash";
import { UserType } from "src/context/UserSessionContext";
import { NotificationFrequency, RoleType } from "src/generated/api_types";
import { ObjectSchema } from "yup";

// "AMERICAN_SIGN_LANGUAGE" => "American Sign Language"
export const toTitleCase = (string: string): string => {
  return _.startCase(string.split("_").join(" ").toLowerCase());
};

// "MEDICATION_MANAGEMENT" => "Medication management"
export const toSentenceCase = (string: string): string => {
  return _.upperFirst(string.split("_").join(" ").toLowerCase());
};

// https://github.com/Kallil-Belmonte/helpers/blob/main/functions/image/checkIfImageExists.ts
export const imageExists = (url: string, callback: (exists: boolean) => void) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

/**
 * Utility to strip out "data:image/{image type};base64," from a given string
 *   - ^   means  At the start of a line
 *   - .+  means  One or more wildcard characters
 *   - ,   means  ,
 *
 * More info at https://github.com/CarinaWeb/CarinaCore/pull/348#discussion_r798865389
 */
export const cleanBase64String = (input: string): string => {
  return input.replace("data:", "").replace(/^.+,/, "");
};

// TODO: https://github.com/CarinaWeb/CarinaCore/issues/1015: All regular expressions need tests
export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const getDistanceInMiles = (distanceInMeters: number): number => {
  const distanceInMilesTypeLong = distanceInMeters / 1609.34;
  return Math.round(distanceInMilesTypeLong);
};

export const getSemanticMilesAway = (distanceInMiles?: number): string => {
  if (distanceInMiles === undefined) return "";
  if (distanceInMiles === 1) return `1 mile away`;
  if (distanceInMiles < 1) return `Less than a mile away`;
  return `${distanceInMiles} miles away`;
};

export const extractEmailDomain = (email: string): string => {
  return email.split("@")[1] || "";
};

export function getFriendlyRoleType(roleType: RoleType): string {
  switch (roleType) {
    case RoleType.PROVIDER:
      return "Provider";
    case RoleType.CONSUMER:
      return "Consumer";
    case RoleType.PROXY_PROVIDER:
      return "Proxy IP";
    case RoleType.CASE_WORKER:
      return "Case Worker";
    case RoleType.MEDICAID_CASE_MANAGER:
      return "Case Manager";
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
      return "Medicaid Referral Coordinator";
    case RoleType.USER_SUPPORT_AGENT:
      return "User Support Agent";
    case RoleType.USER_SUPPORT_MANAGER:
      return "Carina User Support Manager";
    case RoleType.SUPPORT:
      return "Support";
    default:
      return "";
  }
}

export const getUserType = (roleType: RoleType): UserType => {
  switch (roleType) {
    case RoleType.CONSUMER:
      return UserType.Consumer;
    case RoleType.PROVIDER:
      return UserType.Provider;
    case RoleType.PROXY_PROVIDER:
      return UserType.ProxyProvider;
    case RoleType.CHILDCARE_PROVIDER:
      return UserType.ChildcareProvider;
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
      return UserType.MedicaidReferralCoordinator;
    case RoleType.MEDICAID_CASE_MANAGER:
      return UserType.MedicaidCaseManager;
    case RoleType.CASE_WORKER:
      return UserType.CaseWorker;
    case RoleType.USER_SUPPORT_AGENT:
      return UserType.UserSupportAgent;
    case RoleType.USER_SUPPORT_MANAGER:
      return UserType.UserSupportManager;
    case RoleType.SUPPORT:
      return UserType.CaseWorker;
    default:
      return UserType.Consumer;
  }
};

export function roleCanBeReported(roleType: RoleType): boolean {
  switch (roleType) {
    case RoleType.CASE_WORKER:
    case RoleType.USER_SUPPORT_AGENT:
    case RoleType.USER_SUPPORT_MANAGER:
      return false;
    case RoleType.PROVIDER:
    case RoleType.CONSUMER:
    case RoleType.PROXY_PROVIDER:
    case RoleType.MEDICAID_CASE_MANAGER:
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
    case RoleType.SUPPORT:
    default:
      return true;
  }
}

export function roleCanShowVerifiedBadge(roleType: RoleType): boolean {
  switch (roleType) {
    case RoleType.USER_SUPPORT_MANAGER:
    case RoleType.USER_SUPPORT_AGENT:
      return true;
    case RoleType.PROVIDER:
    case RoleType.CONSUMER:
    case RoleType.PROXY_PROVIDER:
    case RoleType.MEDICAID_CASE_MANAGER:
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
    case RoleType.CASE_WORKER:
    default:
      return false;
  }
}

export function roleCanPostAJob(userType: string): boolean {
  switch (userType) {
    case UserType.Consumer:
    case UserType.ProxyProvider:
    case UserType.MedicaidReferralCoordinator:
    case UserType.MedicaidCaseManager:
      return true;
    default:
      return false;
  }
}

export function roleCanPostJobsForOthers(roleType: RoleType): boolean {
  switch (roleType) {
    case RoleType.PROXY_PROVIDER:
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
    case RoleType.MEDICAID_CASE_MANAGER:
      return true;
    case RoleType.PROVIDER:
    case RoleType.CONSUMER:
    case RoleType.CASE_WORKER:
    case RoleType.USER_SUPPORT_MANAGER:
    case RoleType.USER_SUPPORT_AGENT:
    default:
      return false;
  }
}

export function roleCanHaveProviderProfile(roleType: RoleType): boolean {
  switch (roleType) {
    case RoleType.PROVIDER:
    case RoleType.PROXY_PROVIDER:
      return true;
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
    case RoleType.MEDICAID_CASE_MANAGER:
    case RoleType.CONSUMER:
    case RoleType.CASE_WORKER:
    case RoleType.USER_SUPPORT_MANAGER:
    case RoleType.USER_SUPPORT_AGENT:
    default:
      return false;
  }
}

export function getDisplayRoleOrAgency(role: string, organization?: string): string {
  if (role === UserType.MedicaidReferralCoordinator && !!organization) {
    return organization;
  }
  if (role === UserType.MedicaidCaseManager) {
    return "Case Manager";
  }
  if (role === UserType.ProxyProvider) {
    return "Proxy IP";
  }
  return addSpaceBetweenWords(role);
}

export function addSpaceBetweenWords(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function getNotificationFrequencyFriendlyName(frequency: NotificationFrequency): string {
  switch (frequency) {
    case NotificationFrequency.DAILY:
      return "daily";
    case NotificationFrequency.WEEKLY:
      return "weekly";
    case NotificationFrequency.MONTHLY:
      return "monthly";
    default:
      return "";
  }
}

export function insertBetween(value: any, array: Array<any>): Array<any> {
  return array.flatMap((x) => [value, x]).slice(1);
}

//TODO: Update to handle nested fields (e.g. Consumer Details-> CareRecipient.address)
export function isRequiredField(fieldName: string, schema: ObjectSchema): boolean {
  return (
    (schema.fields &&
      Object.entries(schema.fields)
        .filter((entry) => Object.keys(entry[1]["_exclusive"]).includes("required"))
        .map((entry) => entry[0])
        .includes(fieldName)) ||
    false
  );
}

export function getDaysSinceLastUpdate(updatedAt: number): number {
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - updatedAt;
  return Math.floor(timeDifference / (1000 * 3600 * 24));
}

export default function stateAbbreviated(name: string): string {
  const lowerCaseAbbreviationKey = name
    .trim()
    .replace(/[^\w ]/g, "")
    .toLowerCase(); // Trim, lowercase and remove all non-word characters with the exception of spaces
  return states[lowerCaseAbbreviationKey] ?? name; // Return name if record is not found
}

const states: Record<string, string> = {
  arizona: "AZ",
  alabama: "AL",
  alaska: "AK",
  arkansas: "AR",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  "district of columbia": "DC",
  delaware: "DE",
  florida: "FL",
  georgia: "GA",
  hawaii: "HI",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  iowa: "IA",
  kansas: "KS",
  kentucky: "KY",
  louisiana: "LA",
  maine: "ME",
  maryland: "MD",
  massachusetts: "MA",
  michigan: "MI",
  minnesota: "MN",
  mississippi: "MS",
  missouri: "MO",
  montana: "MT",
  nebraska: "NE",
  nevada: "NV",
  "new hampshire": "NH",
  "new jersey": "NJ",
  "new mexico": "NM",
  "new york": "NY",
  "north carolina": "NC",
  "north dakota": "ND",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvania: "PA",
  "rhode island": "RI",
  "south carolina": "SC",
  "south dakota": "SD",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  vermont: "VT",
  virginia: "VA",
  washington: "WA",
  "west virginia": "WV",
  wisconsin: "WI",
  wyoming: "WY",
  "american samoa": "AS",
  guam: "GU",
  "northern mariana islands": "MP",
  "puerto rico": "PR",
  "us virgin islands": "VI",
  "us minor outlying islands": "UM",
};
