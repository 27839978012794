import { useUserSession } from "src/context/UserSessionContext";
import { ThreadDetailsDTO } from "src/generated/api_types";
import AssistiveRoleThreadDetailsSection from "src/pages/inbox/views/AssistiveRoleThreadDetailsSection";
import RegularThreadDetailsSection from "src/pages/inbox/views/RegularThreadDetailsSection";

export type ThreadDetailsSectionProps = {
  setStarred?: (newStatus: boolean) => void;
  setArchived?: (newStatus: boolean) => void;
} & ThreadDetailsDTO;

const ThreadDetailsSection = (props: ThreadDetailsSectionProps) => {
  const { isAssistiveRoleView } = useUserSession();

  if (isAssistiveRoleView()) return <AssistiveRoleThreadDetailsSection {...props} />;

  return <RegularThreadDetailsSection {...props} />;
};
export default ThreadDetailsSection;
