import { alpha, Box, MenuItem as MuiMenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RoleType } from "src/generated/api_types";
import { nsMedicaidInbox, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getShortRoleName } from "src/i18n/Utilities";
import { ThreadListCardProps } from "src/pages/inbox/inboxComponents/ThreadListCard";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { ellipsisTrim, firstNameAndInitial } from "src/pages/inbox/InboxHelpers";
import { BodyEmphasis, QuoteSentence, QuoteSentenceEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const StyledMenuItem = styled(MuiMenuItem)({
  height: "108px",
  padding: "12px 16px 12px 24px",
  borderBottom: `1px solid ${alpha(CivColors.mediumGray, 0.5)}`,
  display: "block",
  "&.Mui-selected": {
    backgroundColor: alpha(CivColors.coreOrange, 0.08),
    borderLeft: `4px solid ${CivColors.coreOrange}`,
    "&:hover": {
      backgroundColor: alpha(CivColors.coreOrange, 0.14),
    },
  },
  "&.Mui-focusVisible": {
    backgroundColor: alpha(CivColors.coreDarkNavy, 0.12),
  },
  "&.Mui-selected.Mui-focusVisible": {
    backgroundColor: alpha(CivColors.coreOrange, 0.14),
  },
});

const CountBadge = ({ number }: { number: number }) => (
  <Box
    height="24px"
    borderRadius="20px"
    p="0px 8px"
    display="inline-block"
    alignContent="center"
    textAlign="center"
    bgcolor={CivColors.tangerine}
    color={CivColors.white}
  >
    <BodyEmphasis>{number}</BodyEmphasis>
  </Box>
);

export const nameAndRole = (t: TFunction, roleType: RoleType, firstName: string, lastInitial?: string): string => {
  let text = firstNameAndInitial(firstName, lastInitial ? lastInitial : undefined);
  if (roleType !== RoleType.PROVIDER) text += ` (${getShortRoleName(roleType, t)})`;
  return text;
};

const AssistiveRoleThreadListCard = ({
  firstName,
  lastInitial = undefined,
  roleType,
  date,
  jobNum = undefined,
  clientName = undefined,
  onClick = undefined,
  unreadMessages = 0,
  threadId,
}: ThreadListCardProps) => {
  const { t } = useTranslation([nsMedicaidInbox, nsMedicaidTagsEnums]);
  const { selectedThreadId } = useInboxContext();
  const [hideBadge, setHideBadge] = useState(false);

  return (
    <StyledMenuItem
      tabIndex={0} //Make this element focusable. Not sure why it isn't, by default here
      onClick={(e) => {
        if (onClick) onClick(e);
        setHideBadge(true); // As soon as thread list card is clicked, user should no longer see unread count badge
      }}
      selected={selectedThreadId != undefined && threadId != undefined && selectedThreadId === threadId}
    >
      <Box display="flex" justifyContent="space-between">
        <BodyEmphasis sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {nameAndRole(t, roleType, firstName, lastInitial)}
        </BodyEmphasis>
        {!!unreadMessages && !hideBadge && <CountBadge number={unreadMessages} />}
      </Box>
      <QuoteSentence sx={{ width: "100%", mt: "8px" }}>{format(date, "MM/dd/yyyy")}</QuoteSentence>
      {jobNum && clientName && (
        <QuoteSentenceEmphasis sx={{ width: "100%", mt: "8px" }}>
          {ellipsisTrim(`${t("job_number.label", { ns: nsMedicaidInbox, job_num: jobNum })} (${clientName})`, 35)}
        </QuoteSentenceEmphasis>
      )}
    </StyledMenuItem>
  );
};

export default AssistiveRoleThreadListCard;
