import { Box, BoxProps, Collapse, Grid, GridProps, SvgIconProps, useMediaQuery, Link as MuiLink } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { UserType } from "src/context/UserSessionContext";
import {
  LocationDTO,
  ProviderProfileTagEnum,
  ReportSourceEnum,
  RoleType,
  ThreadDetailsDTO,
} from "src/generated/api_types";
import {
  nsCommonAlt,
  nsCommonFormsBtns,
  nsMedicaidInbox,
  nsMedicaidSearch,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedJobPostTag, getLocalizedProviderProfileTag } from "src/i18n/TagHelpers";
import { getLocalizedRoleType } from "src/i18n/Utilities";
import {
  CalendarIcon,
  CheckBadgeIcon,
  TimeClockCircleAlternateIcon,
  Flag1Icon,
  PinLocation1Icon,
} from "src/assets/icons/StreamlineIcons";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { formatPhoneNumber } from "src/pages/agencyAdmin/dashboard/consumers/ConsumersList";
import { DesktopMinWidth } from "src/pages/inbox/Inbox";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { NameOrgRoleTitle } from "src/pages/inbox/InboxHelpers";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { UserReportModal } from "src/pages/medicaidAccount/sharedComponents/ReportUserModal";
import Avatar from "src/reusable_view_elements/Avatar";
import Constraint from "src/reusable_view_elements/Constraint";
import { ExpandMoreButton } from "src/reusable_view_elements/ExpandMoreButton";
import { InlineDivider } from "src/reusable_view_elements/InlineDivider";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import {
  getDistanceInMiles,
  getUserType,
  imageExists,
  roleCanBeReported,
  roleCanHaveProviderProfile,
  roleCanPostJobsForOthers,
  roleCanShowVerifiedBadge,
} from "src/utilities/GeneralUtilities";

interface TextAndIconProps {
  text: string;
  icon?: React.ComponentType<SvgIconProps>;
}

const TextAndIcon = ({ text, icon: Component }: TextAndIconProps) => (
  <Grid container alignItems="center" spacing={1}>
    <Grid item>
      <Body gutterBottom>{text}</Body>
    </Grid>
    {Component && (
      <Grid item>
        <Component />
      </Grid>
    )}
  </Grid>
);

type IconAndTextProps = {
  icon: React.ComponentType<SvgIconProps>;
  text: string;
} & GridProps;

const IconAndText = ({ icon: Component, text, ...gridProps }: IconAndTextProps) => {
  return (
    <Grid container alignItems="flex-start" {...gridProps}>
      <Grid item>
        <Component style={{ width: "24px", height: "24px", marginRight: "6px" }} />
      </Grid>
      <Grid item xs>
        <Body paragraph>{text}</Body>
      </Grid>
    </Grid>
  );
};

const AvailableNowTag = (boxProps: BoxProps) => {
  const { t, ready } = useTranslation(nsMedicaidTagsEnums);

  return (
    <Box
      bgcolor={CivColors.coreGold}
      p="4px"
      textAlign="center"
      borderRadius="4px"
      mb={1}
      display="inline-block"
      {...boxProps}
    >
      {ready && <BodyEmphasis>{t("seeking.available_now", { ns: nsMedicaidTagsEnums })}</BodyEmphasis>}
    </Box>
  );
};

const PhoneComponent = ({ phone }: { phone: string }) => {
  return <IconAndText icon={PhoneRoundedIcon} text={formatPhoneNumber(phone) || phone} />;
};

const LocationComponent = ({ location }: { location: LocationDTO }) => {
  const { t, ready } = useTranslation(nsMedicaidSearch);
  let __text = `${location.city}, ${location.state}`;
  if (location.distance && ready) {
    __text += ` (${t("count_miles_away.label", {
      ns: nsMedicaidSearch,
      count: getDistanceInMiles(location.distance),
    })})`;
  }
  return <IconAndText icon={PinLocation1Icon} text={__text} />;
};

const ScheduleComponent = ({ schedule }: { schedule: string }) => {
  return <IconAndText icon={CalendarIcon} text={schedule} style={{ overflowWrap: "anywhere" }} />;
};

const HoursComponent = ({ hours }: { hours: number }) => {
  const { t, ready } = useTranslation(nsMedicaidSearch);
  if (!ready) {
    return <></>;
  }
  return (
    <IconAndText
      icon={TimeClockCircleAlternateIcon}
      text={t("count_hours_per_month.label", { ns: nsMedicaidSearch, count: hours })}
    />
  );
};

interface ReportUserLinkAndModalProps extends GridProps {
  reportUserId: string;
  reportUserFirstName: string;
  reportUserLastName?: string;
  reportUserType: string;
  jobPostId?: string;
  assistedUserFirstName?: string;
}
const ReportUserLinkAndModal = ({
  reportUserId,
  reportUserFirstName,
  reportUserLastName,
  reportUserType,
  jobPostId,
  assistedUserFirstName,
  ...gridProps
}: ReportUserLinkAndModalProps) => {
  const { t, ready } = useTranslation(nsCommonFormsBtns);
  const [openReportModal, setOpenReportModal] = useState(false);

  if (!ready) {
    return <></>;
  }
  return (
    <>
      <Grid container {...gridProps}>
        <Grid item>
          <Flag1Icon width="24px" height="24px" style={{ marginRight: "6px" }} color="primary" />
        </Grid>
        <Grid item>
          <MuiLink component="button" onClick={() => setOpenReportModal(true)}>
            <Body>{t("button.report_user", { ns: nsCommonFormsBtns })}</Body>
          </MuiLink>
        </Grid>
      </Grid>
      <UserReportModal
        subjectId={reportUserId}
        subjectUserType={reportUserType as UserType}
        subjectFirstName={reportUserFirstName}
        subjectLastName={reportUserLastName}
        reportSource={jobPostId ? ReportSourceEnum.JOB_POST_MESSAGE : ReportSourceEnum.DIRECT_MESSAGE}
        open={openReportModal}
        onClose={() => setOpenReportModal(false)}
        assistedConsumerFirstName={assistedUserFirstName}
        assistedJobId={assistedUserFirstName ? jobPostId : undefined}
      />
    </>
  );
};

const RegularThreadDetailsSection = (details: ThreadDetailsDTO) => {
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const { isInEnv } = useConfig();
  const { profileDetailExpanded, setProfileDetailExpanded } = useInboxContext();
  const { t, ready } = useTranslation([nsMedicaidInbox, nsCommonFormsBtns, nsMedicaidTagsEnums, nsCommonAlt]);

  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    if (details.otherUser.id && details.otherUser.hasImg) {
      const profileImage = `https://s3.us-west-2.amazonaws.com/${
        isInEnv(EnvName.PROD) ? "production.carina.profile.photos" : "carina.profile.photos"
      }/${details.otherUser.id}.jpg`;
      imageExists(profileImage, (exists) => {
        if (exists) {
          setImageUrl(profileImage);
        }
      });
    }
  }, []);

  const handleExpandClick = () => {
    setProfileDetailExpanded(!profileDetailExpanded);
  };

  const showReportUser: boolean = roleCanBeReported(details.otherUser.roleType);
  const showVerifiedBadge: boolean = roleCanShowVerifiedBadge(details.otherUser.roleType);
  // Show last initial for every role except Consumers
  const showLastInitial: boolean =
    details.otherUser.roleType !== RoleType.CONSUMER && details.otherUser.lastNameInitial.length > 0;
  const showAvailableNowTag: boolean =
    details.providerData?.availability === ProviderProfileTagEnum.SEEKING && !details.jobIsPostedByOtherUser;

  function getAvatarInitials(): string {
    let __initials = details.otherUser.firstName[0];
    if (showLastInitial) {
      __initials += details.otherUser.lastNameInitial[0];
    }
    return __initials;
  }

  const jobIsResolvedText: string = details.jobPostNumber
    ? t("job_has_been_deleted.label", { ns: nsMedicaidInbox, job_num: details.jobPostNumber })
    : t("job_has_been_deleted.generic.label", { ns: nsMedicaidInbox });

  const Summary = () => {
    // ThirdPartyConsumerSummary
    // Renders the summary about a consumer whom the other user posted on behalf for
    // Only renders when:
    // 1. otherUser is a role who can post a job for other people
    // 2. otherUser posted the job post
    if (
      details.consumerData &&
      details.jobIsPostedByOtherUser &&
      roleCanPostJobsForOthers(details.otherUser.roleType)
    ) {
      const __locizePronouns = details.consumerData.pronouns.map((tag) => getLocalizedJobPostTag(tag));

      const __pronouns = __locizePronouns.map((tag) => t(tag.key, { ns: tag.namespace })).join(", ");
      const __consumerFirstName = details.consumerData.consumerFirstName;

      return (
        <>
          {ready && (
            <BodyEmphasis gutterBottom>{t("on_behalf_of_consumer.label", { ns: nsMedicaidInbox })}</BodyEmphasis>
          )}
          <Body style={{ overflowWrap: "anywhere" }}>
            {__consumerFirstName} {__pronouns.length > 0 && `(${__pronouns})`}
          </Body>
        </>
      );
    }

    // ProviderProfileSummary
    // Renders the provider profile summary about the other user
    // Only renders when:
    // 1. otherUser is a role who can have a provider profile
    // 2. otherUser did not post the job post, if it exists
    if (
      details.providerData &&
      roleCanHaveProviderProfile(details.otherUser.roleType) &&
      !details.jobIsPostedByOtherUser
    ) {
      const __locizePronouns = details.providerData.pronouns.map((tag) => getLocalizedProviderProfileTag(tag));
      const __locizeRole = getLocalizedRoleType(details.otherUser.roleType);

      const __pronouns = __locizePronouns.map((tag) => t(tag.key, { ns: tag.namespace })).join(", ");
      const __role = t(__locizeRole.key, { ns: __locizeRole.namespace });
      return (
        <TextAndIcon
          text={[__pronouns, __role].filter((detail) => !!detail).join(` • `)}
          icon={showVerifiedBadge ? CheckBadgeIcon : undefined}
        />
      );
    }

    // ConsumerSummary
    // Only renders when otherUser is a Consumer, because their job post can only be for themselves
    if (details.consumerData && details.otherUser.roleType === RoleType.CONSUMER && details.jobIsPostedByOtherUser) {
      const __locizePronouns = details.consumerData.pronouns.map((tag) => getLocalizedJobPostTag(tag));
      const __locizeRole = getLocalizedRoleType(details.otherUser.roleType);

      const __pronouns = __locizePronouns.map((tag) => t(tag.key, { ns: tag.namespace })).join(", ");
      const __role = t(__locizeRole.key, { ns: __locizeRole.namespace });

      return <Body>{[__pronouns, __role].filter((detail) => !!detail).join(` • `)}</Body>;
    }

    // BasicSummary
    const locizeRoleType = getLocalizedRoleType(details.otherUser.roleType);
    return (
      <TextAndIcon
        text={ready ? t(locizeRoleType.key, { ns: locizeRoleType.namespace }) : ""}
        icon={showVerifiedBadge ? CheckBadgeIcon : undefined}
      />
    );
  };

  const ViewJobOrProfileButton = () => {
    return (
      <>
        {/*
         Show "View Profile" button if:
         1. other user can have a profile
         2. if there's a job post, we own the job post
         */}
        {roleCanHaveProviderProfile(details.otherUser.roleType) && !details.jobIsPostedByOtherUser && (
          <LinkStyledAsButton
            variant="outlined"
            sx={{ backgroundColor: CivColors.white }}
            component={Link}
            to={generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, { id: details.otherUser.id })}
          >
            {t("button.view_profile", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        )}
        {/* Show "Job #" link if we posted the job */}
        {details.jobPostId && !details.jobIsPostedByOtherUser && !details.jobIsResolved && (
          <Trans
            i18nKey="discussing_job.label"
            ns={nsMedicaidInbox}
            parent={(props: PropsWithChildren<any>) => <Body style={{ marginTop: "14px" }}>{props.children}</Body>}
            components={{
              jblnk: <InternalLink to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: details.jobPostId })} />,
            }}
            values={{
              job_num: details.jobPostNumber,
            }}
          />
        )}
        {/* Show "View Job #" button if other user posted the job */}
        {details.jobPostId && details.jobIsPostedByOtherUser && !details.jobIsResolved && (
          <LinkStyledAsButton
            variant="outlined"
            sx={{ backgroundColor: CivColors.white }}
            component={Link}
            to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: details.jobPostId })}
          >
            {t("view_job_num.label", { ns: nsMedicaidInbox, job_num: details.jobPostNumber })}
          </LinkStyledAsButton>
        )}
      </>
    );
  };

  const MobilePanel = () => (
    <Box bgcolor={CivColors.paleBlue} p="16px">
      <Constraint columns={10}>
        <Grid container>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item style={{ paddingRight: 24 }}>
              <Avatar
                src={imageUrl}
                alt={
                  imageUrl
                    ? t("standard.headshot_alt", {
                        ns: nsCommonAlt,
                        name: details.otherUser.firstName + details.otherUser.lastNameInitial,
                      })
                    : ""
                }
                style={{
                  width: "74px",
                  height: "74px",
                  fontSize: "2.0rem",
                }}
              >
                {getAvatarInitials()}
              </Avatar>
            </Grid>

            <Grid item xs>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 12 }}>
                <Grid item>
                  <NameOrgRoleTitle
                    component={BodyTitle}
                    firstName={details.otherUser.firstName}
                    lastInitial={showLastInitial ? details.otherUser.lastNameInitial[0] : undefined}
                    roleType={details.otherUser.roleType}
                    org={details.otherUser.organizationName}
                  />

                  <Summary />

                  {showAvailableNowTag && <AvailableNowTag />}
                  {details.jobIsResolved && (
                    <Box mt={1}>
                      <Body>{jobIsResolvedText}</Body>
                    </Box>
                  )}
                </Grid>

                <Grid item style={{ marginTop: 10, textAlign: "left" }}>
                  <ViewJobOrProfileButton />
                </Grid>
              </Grid>
            </Grid>

            <Collapse in={profileDetailExpanded} style={{ width: "100%" }}>
              <Box pt={2}>
                {details.location && <LocationComponent location={details.location} />}
                {details.phone && <PhoneComponent phone={details.phone} />}
                {details.scheduleNotes && <ScheduleComponent schedule={details.scheduleNotes} />}
                {details.hours && <HoursComponent hours={details.hours} />}
                {showReportUser && (
                  <ReportUserLinkAndModal
                    reportUserId={details.otherUser.id}
                    reportUserType={getUserType(details.otherUser.roleType)}
                    reportUserFirstName={details.otherUser.firstName}
                    reportUserLastName={details.otherUser.lastNameInitial}
                    jobPostId={details.jobPostId}
                    justifyContent="flex-end"
                    alignContent="flex-end"
                    assistedUserFirstName={
                      getUserType(details.otherUser.roleType) === UserType.MedicaidReferralCoordinator
                        ? details.consumerData?.consumerFirstName
                        : undefined
                    }
                  />
                )}
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Constraint>
      <InlineDivider>
        <ExpandMoreButton
          text={
            profileDetailExpanded
              ? t("show_less_info.label", { ns: nsCommonFormsBtns })
              : t("show_more_info.label", { ns: nsCommonFormsBtns })
          }
          expanded={profileDetailExpanded}
          onClick={handleExpandClick}
        />
      </InlineDivider>
    </Box>
  );

  const DesktopPanel = () => (
    <Box bgcolor={CivColors.paleBlue} pt="24px" pb="24px">
      <Constraint columns={14} px={2}>
        <Grid container justifyContent="space-between" spacing={2} wrap="nowrap">
          {/* Column 1 */}
          <Grid item>
            <Grid container wrap="nowrap">
              <Grid item>
                <Avatar
                  src={imageUrl}
                  alt={
                    imageUrl
                      ? t("standard.headshot_alt", {
                          ns: nsCommonAlt,
                          name: details.otherUser.firstName + details.otherUser.lastNameInitial,
                        })
                      : ""
                  }
                  style={{
                    width: "80px",
                    height: "80px",
                    fontSize: "1.8rem",
                  }}
                >
                  {getAvatarInitials()}
                </Avatar>
              </Grid>

              <Grid item>
                <Box pl={2}>
                  <Grid container>
                    <NameOrgRoleTitle
                      component={(props: PropsWithChildren<any>) => (
                        <BodyTitle style={{ marginRight: 10 }}>{props.children}</BodyTitle>
                      )}
                      firstName={details.otherUser.firstName}
                      lastInitial={showLastInitial ? details.otherUser.lastNameInitial[0] : undefined}
                      roleType={details.otherUser.roleType}
                      org={details.otherUser.organizationName}
                    />
                    {showAvailableNowTag && <AvailableNowTag />}
                  </Grid>
                  <Box mb={2}>
                    <Summary />
                  </Box>
                  {showReportUser && (
                    <ReportUserLinkAndModal
                      reportUserId={details.otherUser.id}
                      reportUserType={getUserType(details.otherUser.roleType)}
                      reportUserFirstName={details.otherUser.firstName}
                      reportUserLastName={details.otherUser.lastNameInitial}
                      jobPostId={details.jobPostId}
                      assistedUserFirstName={
                        getUserType(details.otherUser.roleType) === UserType.MedicaidReferralCoordinator
                          ? details.consumerData?.consumerFirstName
                          : undefined
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Column 2 */}
          <Grid item xs="auto">
            {details.location && <LocationComponent location={details.location} />}
            {details.phone && <PhoneComponent phone={details.phone} />}
            {details.hours && <HoursComponent hours={details.hours} />}
          </Grid>

          {/* Column 3 */}
          <Grid item xs="auto" style={{ textAlign: "right" }}>
            <ViewJobOrProfileButton />
            {details.jobIsResolved && <Body>{jobIsResolvedText}</Body>}
          </Grid>
        </Grid>
      </Constraint>
    </Box>
  );

  return desktopSize ? <DesktopPanel /> : <MobilePanel />;
};
export default RegularThreadDetailsSection;
