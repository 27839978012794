import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Box, InputAdornment } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { JobResponsesDTO, Nullable, SortDirection, SortInfo } from "src/generated/api_types";
import { getMuiDataGridLocaleText } from "src/i18n/Languages";
import { nsUniversalDatatables } from "src/i18n/Namespaces";
import { useDebounce } from "src/i18n/Utilities";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import ThreadsForJobClientTabView from "src/pages/inbox/views/ThreadsForJobClientTabView";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import TextField from "src/reusable_view_elements/form_fields/TextField";
import { InternalLink } from "src/reusable_view_elements/Link";
import { BodyTitle } from "src/reusable_view_elements/Typography";
import CivTheme from "src/themes/civilization/CivTheme";
import { convertSortInfoToSortModel, convertSortModelToSortInfo } from "src/utilities/MuiDataGridUtilities";

const AssistiveRoleThreadsByClientView = () => {
  const { t, ready } = useTranslation([nsUniversalDatatables]);
  const { byClientJobNumber, setByClientJobNumber } = useInboxContext();

  const columns: GridColDef[] = [
    { field: "clientName", headerName: t("client.label", { ns: nsUniversalDatatables }), flex: 1 },
    {
      field: "jobNumber",
      headerName: t("job_num.label", { ns: nsUniversalDatatables }),
      flex: 1,
      renderCell: (params) => (
        <InternalLink
          to={generatePath(Medicaid_Coordinator_Dashboard_Routes.myJobDetails, {
            id: params.id,
          })}
        >
          #{params.value}
        </InternalLink>
      ),
    },
    { field: "daysOpen", headerName: t("days_open.label", { ns: nsUniversalDatatables }), flex: 1 },
    { field: "hours", headerName: t("monthly_hours.label", { ns: nsUniversalDatatables }), flex: 1 },
    { field: "numThreads", headerName: t("num_conversations.label", { ns: nsUniversalDatatables }), flex: 1 },
    { field: "unreadMessageCount", headerName: t("new_messages.label", { ns: nsUniversalDatatables }), flex: 1 },
    {
      field: "lastMessageDate",
      headerName: t("last_message.label", { ns: nsUniversalDatatables }),
      flex: 1,
      valueFormatter: (value: number) => format(value, "MM/dd/yyyy"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("actions.label", { ns: nsUniversalDatatables }),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <InternalLink to="#" onClick={() => setByClientJobNumber(params.row.jobNumber)}>
          {t("actions.view_messages.label", { ns: nsUniversalDatatables })}
        </InternalLink>
      ),
    },
  ];

  // Data State
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [rows, setRows] = useState<JobResponsesDTO[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);

  // Pagination State
  const pageSizeOptions = [5, 10, 50, 100];
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  // Sorting State: By default, sort by last message date
  const [sortInfo, setSortInfo] = useState<Nullable<SortInfo>>({
    columnName: "lastMessageDate",
    direction: SortDirection.DESC,
  });

  // Textfield Search State
  const [realtimeSearchQuery, setRealtimeSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(realtimeSearchQuery, 500);

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      const response = await messagingClient.getJobResponses({
        pageNum: page,
        pageSize: pageSize,
        filters: {},
        sort: sortInfo,
        search: debouncedSearchQuery,
        searchMap: {},
      });
      setRows(response.data.content);
      setTotalRows(response.data.totalElements);
      setLoadingData(false);
    })();
  }, [page, pageSize, sortInfo, debouncedSearchQuery]);

  if (byClientJobNumber) {
    return <ThreadsForJobClientTabView />;
  }

  return (
    <Box maxWidth={CivTheme.breakpoints.values.xl} marginX="auto" px={2} py={4}>
      <BodyTitle style={{ marginBottom: "24px" }}>
        {t("table.messages_by_client.title", { ns: nsUniversalDatatables, defaultValue: "Messages by Client" })}
      </BodyTitle>
      <TextField
        label={t("actions.search_by_client_or_job.label", {
          ns: nsUniversalDatatables,
          defaultValue: "Search by client name or job #",
        })}
        onChange={(event) => setRealtimeSearchQuery(event.target.value)}
        value={realtimeSearchQuery}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
        style={{ maxWidth: "400px", marginBottom: "32px" }}
      />
      <Box
        height="800px" // This fixed height allows the header to stick (per design requirements) and show overlays
      >
        <DataGrid
          localeText={getMuiDataGridLocaleText()}
          loading={loadingData || !ready}
          columns={columns}
          rows={rows}
          rowCount={totalRows}
          disableRowSelectionOnClick
          disableColumnFilter
          pageSizeOptions={pageSizeOptions}
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(paginationModel) => {
            setPage(paginationModel.page);
            setPageSize(paginationModel.pageSize);
          }}
          sortingMode="server"
          sortModel={convertSortInfoToSortModel(sortInfo)}
          onSortModelChange={(model) => {
            setSortInfo(convertSortModelToSortInfo(model));
          }}
        />
      </Box>
    </Box>
  );
};

export default AssistiveRoleThreadsByClientView;
