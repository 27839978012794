import { PhoneRounded } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, BoxProps, IconButton, Tooltip as MuiTooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link as RouterLink } from "react-router-dom";
import {
  ChatTranslateIcon,
  FireworksPeopleWatchIcon,
  PinLocation1Icon,
  TimeClockCircleAlternateIcon,
} from "src/assets/icons/StreamlineIcons";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { useNotification } from "src/context/NotificationContext";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import { MedicaidProviderProfile_DTO_Response_ProfileDetails, ProviderProfileTagEnum } from "src/generated/api_types";
import {
  nsCommonAlt,
  nsCommonAria,
  nsCommonFormsBtns,
  nsCommonToasts,
  nsCommonTooltips,
  nsMedicaidProviderProfile,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedProviderProfileTag } from "src/i18n/TagHelpers";
import { formatPhoneNumber } from "src/pages/agencyAdmin/dashboard/consumers/ConsumersList";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import {
  getORProviderNumber,
  getTranslatedProviderCred,
} from "src/pages/medicaidAccount/sharedComponents/ProviderProfileDetailsContent";
import { getTagsObject } from "src/pages/medicaidAccount/sharedComponents/ProviderProfileTagHelpers";
import { getProviderProfile } from "src/pages/medicaidAccount/sharedComponents/Queries";
import Avatar from "src/reusable_view_elements/Avatar";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import CredentialBadge from "src/reusable_view_elements/CredentialBadge";
import { ViewAPIResBannerControlEnum } from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import IconListItem from "src/reusable_view_elements/IconListItem";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NonbinaryPopover from "src/reusable_view_elements/popovers/NonbinaryPopover";
import Section from "src/reusable_view_elements/Section";
import SideBySideJobPostAndProfile from "src/reusable_view_elements/SideBySideJobPostAndProfile";
import { Body, BodyEmphasis, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { imageExists, insertBetween } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";

const ThreadOtherUserDetails = ({ profileId }: { profileId: string }) => {
  const { showSnackbar, closeSnackbar, useMainSnackbar } = useNotification();
  const { isInEnv } = useConfig();
  const { userSession, isAssistiveRoleView, isFunderState } = useUserSession();
  const { reportResponseView, setReportResponseView } = useMedicaidContext();
  const { jobPostIdForComparison } = useInboxContext();
  const { t, ready } = useTranslation([
    nsMedicaidProviderProfile,
    nsCommonFormsBtns,
    nsCommonTooltips,
    nsCommonToasts,
    nsMedicaidTagsEnums,
    nsCommonAria,
    nsCommonAlt,
  ]);

  const [providerProfile, setProviderProfile] = useState<MedicaidProviderProfile_DTO_Response_ProfileDetails>();
  const [isLoadingProfile, setLoadingProfile] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [isComparisonViewOpen, setComparisonViewOpen] = useState<boolean>(false);

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/

  useEffect(() => {
    // make sure user report banner is hidden on component mount
    setReportResponseView(ViewAPIResBannerControlEnum.HIDDEN);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!userSession) {
          throw Error("Missing user session");
        }

        setLoadingProfile(true);

        const resProviderProfile = await getProviderProfile(profileId);
        setProviderProfile(resProviderProfile.data);

        if (resProviderProfile.data.hasImg) {
          const profileImage = `https://s3.us-west-2.amazonaws.com/${
            isInEnv(EnvName.PROD) ? "production.carina.profile.photos" : "carina.profile.photos"
          }/${profileId}.jpg`;
          imageExists(profileImage, (exists) => {
            if (exists) {
              setImageUrl(profileImage);
            } else {
              setImageUrl(undefined);
            }
          });
        } else {
          setImageUrl(undefined);
        }
      } catch (e) {
        showSnackbar(
          t(
            "provider_details.error.something_wrong_loading",
            "Something went wrong while loading provider profile details. Please try again.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } finally {
        setLoadingProfile(false);
      }
    })();
  }, [profileId]);

  useEffect(() => {
    if (reportResponseView !== ViewAPIResBannerControlEnum.HIDDEN) scrollToId("nav-bar-carina-logo");
  }, [reportResponseView]);

  /**
   * --------- LOCALIZATION HELPER FUNCTIONS
   */

  function getTranslatedProviderTag(tag: ProviderProfileTagEnum): string {
    return t(getLocalizedProviderProfileTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  /**
   * ----------- END LOCALIZATION HELPER FUNCTIONS
   */

  if (!ready) {
    return (
      <Section height="100vh" bgcolor={CivColors.white}>
        <Constraint columns={8} textAlign="center">
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  if (isLoadingProfile) {
    return (
      <Section height="100vh" bgcolor={CivColors.white}>
        <Constraint columns={8} textAlign="center">
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  if (!providerProfile) {
    return (
      <Section bgcolor={CivColors.lightGray} minHeight="80vh">
        <Constraint columns={8} textAlign="center">
          <SectionTitle>{t("provider_profile_cannot_found.label", { ns: nsMedicaidProviderProfile })}</SectionTitle>
        </Constraint>
      </Section>
    );
  }

  const isProviderProfileOwner = userSession?.id && userSession?.id === providerProfile?.provider.id;
  const tagsObj = getTagsObject(providerProfile);

  const ProviderGenderTags = () => {
    return (
      <Body>
        {insertBetween(
          ", ", // Insert a comma between each array item; avoid using join method because it will stringify the popover component
          tagsObj.providerGenderTag.map((tag) =>
            tag === ProviderProfileTagEnum.NONBINARY ? (
              <NonbinaryPopover key={tag}>{getTranslatedProviderTag(tag)}</NonbinaryPopover>
            ) : (
              getTranslatedProviderTag(tag)
            ),
          ),
        )}
      </Body>
    );
  };

  const GoToProfileButton = (props: BoxProps) => {
    const profilePath = profileId
      ? generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, {
          id: profileId,
        })
      : "";
    return (
      <Box {...props}>
        <MuiTooltip
          title={t("icon.open_profile_new_window", { ns: nsCommonAria })}
          enterTouchDelay={0}
          leaveTouchDelay={8000}
        >
          <IconButton size="large" component={RouterLink} to={profilePath} target="_blank">
            <LaunchIcon />
          </IconButton>
        </MuiTooltip>
      </Box>
    );
  };

  return (
    <Box sx={{ background: CivColors.white, p: "32px 20px 24px 23px", height: "auto" }} textAlign="center">
      <GoToProfileButton sx={{ textAlign: "right", marginTop: "-24px", marginRight: "-12px" }} />
      <Avatar
        src={imageUrl}
        alt={
          imageUrl
            ? t("standard.headshot_alt", {
                ns: nsCommonAlt,
                name: providerProfile.provider.firstName + providerProfile.provider.lastName.charAt(0),
              })
            : ""
        }
        style={{
          width: "88px",
          height: "88px",
          margin: "40px auto 24px auto",
          fontSize: "3.2rem",
        }}
      >
        {providerProfile.provider.firstName.charAt(0)}
        {providerProfile.provider.lastName.charAt(0)}
      </Avatar>
      <BodyTitle sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
        {providerProfile.provider.firstName}{" "}
        {isAssistiveRoleView() ? providerProfile.provider.lastName : providerProfile.provider.lastName.charAt(0) + "."}
      </BodyTitle>
      {tagsObj.pronounTags.length > 0 && (
        <Body mt="8px">{tagsObj.pronounTags.map((tag) => getTranslatedProviderTag(tag)).join(", ")}</Body>
      )}

      <Box
        bgcolor={CivColors.coreGold}
        borderRadius="4px"
        p="6px 16px"
        m="16px 8px 8px 8px"
        width="fit-content"
        justifySelf="center"
      >
        <BodyEmphasis>{getTranslatedProviderTag(providerProfile.availability)}</BodyEmphasis>
      </Box>

      <IconListItem icon={FireworksPeopleWatchIcon} primary={<ProviderGenderTags />} sx={{ py: "8px", mt: "24px" }} />

      <IconListItem
        icon={PinLocation1Icon}
        primary={
          <Body>
            {providerProfile.geoDataCityState?.city}, {providerProfile.geoDataCityState?.state}
          </Body>
        }
        sx={{ paddingY: "8px" }}
      />

      <IconListItem
        icon={TimeClockCircleAlternateIcon}
        primary={
          <Body>
            {providerProfile.hours}{" "}
            {providerProfile.hoursFlexible ? t("summary.flexible.label", { ns: nsMedicaidProviderProfile }) : ""}
          </Body>
        }
        sx={{ paddingY: "8px" }}
      />
      <IconListItem
        icon={ChatTranslateIcon}
        primary={<Body>{tagsObj.languageTags.map(getTranslatedProviderTag).join(", ")}</Body>}
        sx={{ paddingY: "8px" }}
      />
      {providerProfile.phone && (
        <IconListItem
          icon={PhoneRounded}
          primary={<Body>{formatPhoneNumber(providerProfile.phone)}</Body>}
          sx={{ paddingY: "8px" }}
        />
      )}

      {providerProfile.credentials
        .filter((credential) => credential.badge)
        .map((credential, i) => (
          <>
            <CredentialBadge
              name={t(getTranslatedProviderCred(credential).nameKey, {
                ns: nsMedicaidProviderProfile,
                context: "short",
              })}
              key={credential.code + i}
            />
            {isAssistiveRoleView() &&
              isFunderState(FunderState.Oregon) &&
              !!providerProfile.providerNumbers?.length && (
                <BodyEmphasis style={{ marginBottom: "16px" }}>
                  {getORProviderNumber(credential, providerProfile.providerNumbers)}
                </BodyEmphasis>
              )}
          </>
        ))}

      {isAssistiveRoleView() && isFunderState(FunderState.Washington) && !!providerProfile.providerNumbers?.length && (
        <BodyEmphasis style={{ marginBottom: "16px" }}>
          ProviderOne #: {providerProfile.providerNumbers[0].providerNumber}
        </BodyEmphasis>
      )}

      <SideBySideJobPostAndProfile
        onClose={() => setComparisonViewOpen(false)}
        open={isComparisonViewOpen}
        providerId={providerProfile.provider.id}
        jobPostId={jobPostIdForComparison}
      />
      <Button variant="outlined" type="button" onClick={() => setComparisonViewOpen(true)}>
        {t("compare_with_job.label", { ns: nsMedicaidProviderProfile })}
      </Button>

      {isProviderProfileOwner && <Box bgcolor={CivColors.lightGray} height={80} />}
    </Box>
  );
};

export default ThreadOtherUserDetails;
