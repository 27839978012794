import { Box, Tabs } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { Permission } from "src/generated/api_types";
import { nsMedicaidInbox } from "src/i18n/Namespaces";
import { InboxTabState, useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { getUserNewMessageSettings } from "src/pages/medicaidAccount/consumerAccount/Queries";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { UserReportResponseBanner } from "src/pages/medicaidAccount/sharedComponents/ReportUserModal";
import {
  BannerUseSiteEnum,
  SETTINGS_BANNER_ENABLED_KEY,
  SettingsBanner,
  ViewBannerControlEnum,
} from "src/pages/medicaidAccount/sharedComponents/SettingsBanner";
import { getDashboardPortalTitle } from "src/pages/supportAdminAccount/portal/SupportPortalMain";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import Constraint from "src/reusable_view_elements/Constraint";
import { ViewAPIResBannerControlEnum } from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import InboxTab from "src/reusable_view_elements/InboxTab";
import DashboardNavbar from "src/reusable_view_elements/navbars/DashboardNavbar";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { scrollToId } from "src/utilities/ScrollToId";

export const DesktopMinWidth = `(min-width:${theme.breakpoints.values.md}px)`;

interface InboxProps {
  tab: InboxTabState;
}

const Inbox = (props: PropsWithChildren<InboxProps>) => {
  const {
    tab,
    setTab,
    setPageNumber,
    setTotalUnreadCount,
    totalUnreadCount,
    resetSearchAndPagination,
    setSelectedThreadId,
    setInboxSidePanelUserId,
    setWereThreadsFiltered,
    setContextOtherUserId,
    setByClientJobNumber,
  } = useInboxContext();
  const {
    reportResponseView,
    setReportResponseView,
    settingsBannerView,
    setSettingsBannerView,
    setIsInitialProvidersSearch,
    setIsInitialJobsSearch,
  } = useMedicaidContext();
  const { isUserType, hasPermission, isAssistiveRoleView } = useUserSession();
  const { program } = useSupportAdminContext();
  const location = useLocation();
  const { t, ready } = useTranslation(nsMedicaidInbox);

  const isBannerEnabled =
    hasPermission(Permission.SETTINGS_ACCESS) && localStorage.getItem(SETTINGS_BANNER_ENABLED_KEY) !== "false";

  useEffect(() => {
    setReportResponseView(ViewAPIResBannerControlEnum.HIDDEN);
    setSettingsBannerView(undefined);

    setTab(props.tab);

    if (location.key) {
      // We want to prevent double querying getUnreadThreadCount() when we jump to a thread;
      // location.key will be null if there is no app history: https://stackoverflow.com/a/70532858/7158628
      messagingClient
        .getUnreadThreadCount({
          selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
        })
        .then((res) => {
          setTotalUnreadCount(res.data); // global unread messages count (i.e. shown in navbar)
        })
        .catch(() => {
          // It's okay to not show error to user in this case
        });
    }

    if (isBannerEnabled) {
      getUserNewMessageSettings()
        .then((res) => {
          if (!res.data.smsEnabled && !res.data.emailEnabled) {
            setSettingsBannerView(ViewBannerControlEnum.VISIBLE_GENERIC);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(`Unable to retrieve user's new message settings. Error: ${err}`);
        });
    }
  }, []);

  useEffect(() => {
    if (reportResponseView !== ViewAPIResBannerControlEnum.HIDDEN) scrollToId("nav-bar-carina-logo");
  }, [reportResponseView]);

  useEffect(() => {
    if (settingsBannerView !== ViewBannerControlEnum.HIDDEN) scrollToId("settings-banner");
  }, [settingsBannerView]);

  const allThreadsTabLabel = () => {
    if (!ready) return "";
    return isAssistiveRoleView()
      ? t("inbox.label", { ns: nsMedicaidInbox })
      : t("tabs.all.label", { ns: nsMedicaidInbox });
  };

  return (
    <>
      <Helmet>
        <title>Carina | Inbox</title>
      </Helmet>

      <SettingsBanner
        view={settingsBannerView}
        setView={setSettingsBannerView}
        useSite={BannerUseSiteEnum.INBOX}
        bannerEnabled={isBannerEnabled}
      />

      {/* NavBars */}
      {isUserType(UserType.UserSupportManager) && (
        <DashboardNavbar
          title={getDashboardPortalTitle(UserType.UserSupportManager)}
          withProgramDropdown
          searchTriggerFn={() => {
            setIsInitialProvidersSearch(true);
            setIsInitialJobsSearch(true);
          }}
        />
      )}
      <MedicaidNavbar />

      <main id="main-content">
        {/* Report User Banner */}
        <UserReportResponseBanner view={reportResponseView} setView={setReportResponseView} />

        {/* Tabs */}
        <Box borderBottom={`1px solid ${CivColors.mediumGray}`} bgcolor={CivColors.lightGray} pt="14px">
          <Constraint columns={12}>
            <Tabs
              value={tab}
              aria-label="inbox tabs"
              indicatorColor="primary"
              onChange={(e, newTab) => {
                setTab(newTab);
              }}
              onClick={() => {
                setSelectedThreadId(undefined); // Reset this state, used by the middle panel for assistive role view
                setInboxSidePanelUserId(undefined); // Reset this state, used by the right panel for assistive role view
                setWereThreadsFiltered(false); // Reset
                setContextOtherUserId(undefined); // Reset this state, used by the 'threadsWithUser' view
                setByClientJobNumber(undefined); // Reset this state, used by the 'By Client - View Messages' view
                resetSearchAndPagination();
                setPageNumber(1); // Aim at 1st page when switching tabs
              }}
            >
              <InboxTab
                badgeNumber={isAssistiveRoleView() && totalUnreadCount > 0 ? totalUnreadCount : undefined}
                component={Link}
                label={allThreadsTabLabel()}
                aria-label="all threads"
                to={MEDICAID_INBOX_ROUTES.allThreads}
                value={InboxTabState.all_threads}
              />
              {!isAssistiveRoleView() && (
                <InboxTab
                  badgeNumber={totalUnreadCount > 0 ? totalUnreadCount : undefined}
                  component={Link}
                  label={ready && t("tabs.unread.label", { ns: nsMedicaidInbox })}
                  aria-label="unread messages"
                  to={MEDICAID_INBOX_ROUTES.unreadThreads}
                  value={InboxTabState.unread_threads}
                />
              )}
              {isAssistiveRoleView() && (
                <InboxTab
                  component={Link}
                  label={t("tabs.by_client.label", { ns: nsMedicaidInbox, defaultValue: "By Client" })}
                  aria-label="threads by client"
                  to={MEDICAID_INBOX_ROUTES.clientThreads}
                  value={InboxTabState.client_threads}
                />
              )}
              <InboxTab
                component={Link}
                label={ready && t("tabs.favorites.label", { ns: nsMedicaidInbox })}
                aria-label="favorite threads"
                to={MEDICAID_INBOX_ROUTES.starredThreads}
                value={InboxTabState.starred_threads}
              />
              <InboxTab
                component={Link}
                label={ready && t("tabs.archived.label", { ns: nsMedicaidInbox })}
                aria-label="archived threads"
                to={MEDICAID_INBOX_ROUTES.archivedThreads}
                value={InboxTabState.archived_threads}
              />
            </Tabs>
          </Constraint>
        </Box>

        {/* Inbox Content Views */}
        {props.children}
      </main>
    </>
  );
};

export default Inbox;
