import { alpha, Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useUserSession } from "src/context/UserSessionContext";
import { ThreadFilterEnum } from "src/generated/api_types";
import { nsMedicaidInbox } from "src/i18n/Namespaces";
import InventoryIcon from "@mui/icons-material/Inventory";
import { EmptyContainer, EmptyInbox } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListContainer from "src/pages/inbox/inboxComponents/ThreadListContainer";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import ViewThread from "src/pages/inbox/views/ViewThread";
import Constraint from "src/reusable_view_elements/Constraint";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const ArchivedThreadsTabView = () => {
  const { isAssistiveRoleView } = useUserSession();
  const { totalThreads, loadingThreads, searchedTerm, wereThreadsSearched, setWereThreadsSearched } = useInboxContext();
  const { t, ready } = useTranslation(nsMedicaidInbox);

  useEffect(() => {
    setWereThreadsSearched(false);
  }, []);
  if (isAssistiveRoleView()) {
    if (wereThreadsSearched && loadingThreads === false && totalThreads === 0 && searchedTerm.length === 0) {
      return (
        <Constraint
          height="809px"
          columns={12}
          sx={{ marginBottom: "40px", border: `1px solid ${alpha(CivColors.mediumGray, 0.5)}` }}
        >
          <Box height="100%" width="100%" alignContent="center" textAlign="center">
            {ready ? (
              <>
                <SectionTitle>{t("no_conversations_in_tab.description", { ns: nsMedicaidInbox })}</SectionTitle>
              </>
            ) : (
              <LoadingCircle />
            )}
          </Box>
        </Constraint>
      );
    }
    return (
      <Constraint
        maxWidth="1280px"
        columns={12}
        sx={{ marginBottom: "40px", border: `1px solid ${alpha(CivColors.mediumGray, 0.5)}` }}
      >
        <Grid container sx={{ minHeight: "809px" }}>
          {/************************************** Left Panel *****************************************/}
          <Grid item xs={3} sx={{ height: "100%" }}>
            <ThreadListContainer
              threadFilter={ThreadFilterEnum.ARCHIVED}
              loadingText={ready ? t("loading.all_conversations.label", { ns: nsMedicaidInbox }) : ""}
            >
              <EmptyInbox title={t("you_have_zero_messages.label", { ns: nsMedicaidInbox })} />
            </ThreadListContainer>
          </Grid>

          {/************************************** Middle Panel ***************************************/}
          <Grid item xs={9} sx={{ borderLeft: `1px solid ${alpha(CivColors.mediumGray, 0.5)}`, height: "auto" }}>
            <ViewThread />
          </Grid>
        </Grid>
      </Constraint>
    );
  }

  return (
    <ThreadListContainer
      threadFilter={ThreadFilterEnum.ARCHIVED}
      loadingText={ready ? t("loading.archived_conversations.label", { ns: nsMedicaidInbox }) : ""}
    >
      <EmptyContainer>
        <SectionTitle>{t("you_have_zero_messages.label_archived", { ns: nsMedicaidInbox })}</SectionTitle>
        <Trans
          i18nKey="you_have_zero_messages.description_archived"
          ns={nsMedicaidInbox}
          parent={Body}
          components={{
            archvicon: <InventoryIcon />,
          }}
        />
      </EmptyContainer>
    </ThreadListContainer>
  );
};

export default ArchivedThreadsTabView;
