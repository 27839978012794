import { FormControl, FormHelperText, Grid, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FastFieldProps } from "formik";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { QuestionCircleIcon } from "src/assets/icons/StreamlineIcons";
import { nsCommonTooltips } from "src/i18n/Namespaces";
import { ControlLabel, ControllableFastField, Radio } from "src/reusable_view_elements/form_fields/index";
import { DescriptiveText } from "src/reusable_view_elements/form_fields/TextField";
import Popover from "src/reusable_view_elements/Popover";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const StyledRadioControl = styled(FormControl)({
  "& .MuiFormHelperText-root": {
    marginTop: 4,
    marginLeft: 0,
    marginBottom: 0,
    lineHeight: 1.2,
    color: CivColors.coreGray,
    "&.Mui-disabled": {
      color: CivColors.mediumGray,
    },
    "&.Mui-error": {
      color: CivColors.deepRose,
    },
  },
  "& .MuiFormControlLabel-label": {
    margin: 0,
    padding: "12px 12px 12px 0",
    lineHeight: 1.2,
  },
});

export interface RadioGroupOption<T> {
  key: T;
  optionLabel: string;
  tooltipBody?: string;
  locizeKey?: string;
  namespace?: string;
}

interface FormRadioGroupFieldProps<T> {
  name: string;
  label?: string;
  options: RadioGroupOption<T>[];
  disabled?: boolean;
  labelAlwaysOnTop?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  withTooltip?: boolean;
  helperText?: string;
}

export function FormRadioGroupField<T>(props: FormRadioGroupFieldProps<T>) {
  const { name, label, options, withTooltip, labelAlwaysOnTop, onChange, ...other } = props;
  const { t, ready } = useTranslation();

  return (
    <ControllableFastField name={props.name} languagePeek={props.label ?? "--"}>
      {({ field, meta }: FastFieldProps<T>) => (
        <StyledRadioControl error={Boolean(meta.error)} fullWidth disabled={props.disabled ?? false}>
          <Grid container alignItems="center">
            {props.label && (
              <Grid item xs={12} md={labelAlwaysOnTop ? 12 : 3}>
                <BodyEmphasis>{props.label}</BodyEmphasis>
              </Grid>
            )}
            <Grid item xs={12} md={props.label ? 9 : 12}>
              <RadioGroup
                name={props.name}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  if (onChange) onChange(e);
                }}
                onBlur={field.onBlur}
                {...other}
              >
                {props.options.map(({ key, optionLabel, tooltipBody, locizeKey, namespace }, uniqueKey) => (
                  <ControlLabel
                    key={uniqueKey}
                    value={key}
                    style={{
                      fontVariantLigatures: "no-contextual",
                      alignItems: "flex-start",
                    }}
                    control={
                      <Radio
                        inputProps={{
                          // @ts-ignore
                          "data-testid": `${props.name}.${key}`,
                        }}
                      />
                    }
                    label={
                      withTooltip && tooltipBody ? (
                        <>
                          <Body style={{ display: "inline", marginRight: "6px" }}>
                            {ready && locizeKey ? t(locizeKey, { ns: namespace }) : optionLabel}
                          </Body>
                          <Popover
                            content={tooltipBody}
                            contentId={`${props.name}-popover`}
                            aria-label={t(`popover.${key}`, { ns: nsCommonTooltips })}
                          >
                            <QuestionCircleIcon />
                          </Popover>
                        </>
                      ) : ready && locizeKey ? (
                        t(locizeKey, { ns: namespace })
                      ) : (
                        optionLabel
                      )
                    }
                    checked={`${field.value}` === `${key}`}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
          <FormHelperText component={"span"}>
            <DescriptiveText
              helperText={meta.touched && meta.error ? meta.error : props.helperText}
              isFormikError={!!(meta.touched && meta.error)}
              isLengthError={false}
              disabled={props.disabled}
            />
          </FormHelperText>
        </StyledRadioControl>
      )}
    </ControllableFastField>
  );
}

FormRadioGroupField.defaultProps = {
  label: "",
  labelAlwaysOnTop: false,
  onChange: undefined,
  withTooltip: false,
};
