import { enUS, esES, ruRU } from "@mui/x-data-grid/locales";
import i18next from "i18next";
import _ from "lodash";
import { LocizeLanguage } from "src/generated/api_types";

/**
 * To add a new language:
 * 1. add the ISO tag to the TargetLanguages set
 * 2. add the label for UI to the LanguageLabel dictionary
 * 3. add the corresponding LocizeLanguage to LangKeyByUserLangPref
 * 4. update getMuiDataGridLocaleText() so that DataGrid will use the correct locale text
 **/

// Language tags follow ISO norm (BCP 47): language-REGION
export const SourceLanguage = "en-US";
export const TargetLanguages = ["es-MX", "ru"];
const LanguageTags = [SourceLanguage, ...TargetLanguages];

// UI Labels
export const LanguageLabel: { [key: string]: string } = {
  "en-US": "English",
  "es-MX": "Español",
  ru: "русский",
};

// Converts current language tag to the equivalent MUI DataGrid locale
export function getMuiDataGridLocaleText() {
  switch (i18next.language) {
    case "es-MX":
      return esES.components.MuiDataGrid.defaultProps.localeText;
    case "ru":
      return ruRU.components.MuiDataGrid.defaultProps.localeText;
    case "en-US":
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

// User language preference mapping to locize language key
export const LangKeyByUserLangPref: { [key in LocizeLanguage]: string } = {
  [LocizeLanguage.English]: "en-US",
  [LocizeLanguage.Spanish]: "es-MX",
  [LocizeLanguage.Russian]: "ru",
};

// Locize language key mapping to user language preference
export const LangPrefByKey = _.invert(LangKeyByUserLangPref);

export const LOCALSTORAGE_LANGUAGE_KEY = "defaultLanguageCode";
const URL_PARAM_LANGUAGE_KEY = "lng";

// Returns the respective valid language tag. Can also be used to check for validity
function getValidLanguageTag(tag: string, strictCheck: boolean) {
  return LanguageTags.find((key) => {
    // Check for exactness if strict is ON
    if (strictCheck) {
      return key === tag;
    }
    // If the tag excludes a hyphen, then leave out matching the region
    if (!tag.includes("-")) {
      return key.split("-")[0].toLowerCase() === tag.toLowerCase();
    }
    return key.toLowerCase() === tag.toLowerCase();
  });
}

function changeHTMLDocumentLanguage(tag: string) {
  document.documentElement.lang = tag;
}

function saveLanguageToStorage(language: string): string {
  const validKey = getValidLanguageTag(language, false) || SourceLanguage;
  localStorage.setItem(LOCALSTORAGE_LANGUAGE_KEY, validKey);
  return validKey;
}

export function changeLanguage(language: string, callback: () => void): string | void {
  const validKey = getValidLanguageTag(language, false) || SourceLanguage;
  i18next
    .changeLanguage(validKey)
    .then(() => {
      localStorage.setItem(LOCALSTORAGE_LANGUAGE_KEY, validKey);
      callback();
    })
    .finally(() => {
      changeHTMLDocumentLanguage(validKey);
      return validKey;
    });
}

export function initializeLanguage(): string {
  // If the URL has a valid language param, save to browser storage and return it
  const params = new URLSearchParams(window.location.search);
  const queryValue = params.get(URL_PARAM_LANGUAGE_KEY);

  // If the browser storage has a valid language param, save to browser storage and return it
  const storedKey = localStorage.getItem(LOCALSTORAGE_LANGUAGE_KEY);

  // Otherwise, default to English
  const validKey = saveLanguageToStorage(queryValue || storedKey || SourceLanguage);
  changeHTMLDocumentLanguage(validKey);
  return validKey;
}

export function isUsingRussian(): boolean {
  return i18next.language === "ru";
}
