/**************************************************
 *                                                 *
 * This is a generated file. Please do not modify. *
 *                                                 *
 ***************************************************/
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as BedSingle } from "./Streamline/Bed-Single.svg";
import { ReactComponent as BreakfastCereal } from "./Streamline/Breakfast-Cereal.svg";
import { ReactComponent as MultipleActionsCheck1 } from "./Streamline/Multiple-Actions-Check-1.svg";
import { ReactComponent as SingleNeutral } from "./Streamline/Single-Neutral.svg";
import { ReactComponent as DrugsPillsBox } from "./Streamline/Drugs-Pills-Box.svg";
import { ReactComponent as TimeClockCircle } from "./Streamline/Time-Clock-Circle.svg";
import { ReactComponent as TripPins } from "./Streamline/Trip-Pins.svg";
import { ReactComponent as LoveHeartHandsHold3 } from "./Streamline/Love-Heart-Hands-Hold-3.svg";
import { ReactComponent as CurrencyDollarBubble } from "./Streamline/Currency-Dollar-Bubble.svg";
import { ReactComponent as RoadSignSideRoadLeft1Alternate } from "./Streamline/Road-Sign-Side-Road-Left-1-Alternate.svg";
import { ReactComponent as SingleManActionsStar } from "./Streamline/Single-Man-Actions-Star.svg";
import { ReactComponent as SingleUserActionsChat } from "./Streamline/Single-User-Actions-Chat.svg";
import { ReactComponent as ChatTranslate } from "./Streamline/Chat-Translate.svg";
import { ReactComponent as FireworksPeopleWatch } from "./Streamline/Fireworks-People-Watch.svg";
import { ReactComponent as Flag1 } from "./Streamline/Flag-1.svg";
import { ReactComponent as ShirtPlain } from "./Streamline/Shirt-Plain.svg";
import { ReactComponent as QuestionCircle } from "./Streamline/Question-Circle.svg";
import { ReactComponent as TextOptionTranslate } from "./Streamline/Text-Option-Translate.svg";
import { ReactComponent as ToolsWrench } from "./Streamline/Tools-Wrench.svg";
import { ReactComponent as FamilyHome } from "./Streamline/Family-Home.svg";
import { ReactComponent as TimeClockCircleAlternate } from "./Streamline/Time-Clock-Circle-Alternate.svg";
import { ReactComponent as MoneyWalletOpen } from "./Streamline/Money-Wallet-Open.svg";
import { ReactComponent as CarRetro2 } from "./Streamline/Car-Retro-2.svg";
import { ReactComponent as AwardTrophyStar1 } from "./Streamline/Award-Trophy-Star-1.svg";
import { ReactComponent as MultipleCircle } from "./Streamline/Multiple-Circle.svg";
import { ReactComponent as PasswordDesktopApproved } from "./Streamline/Password-Desktop-Approved.svg";
import { ReactComponent as OpenSidePanel } from "./Streamline/Open-Side-Panel.svg";
import { ReactComponent as MessagesBubbleHeart1Alternate } from "./Streamline/Messages-Bubble-Heart-1-Alternate.svg";
import { ReactComponent as CleaningSpray } from "./Streamline/Cleaning-Spray.svg";
import { ReactComponent as PhoneActionsInfo } from "./Streamline/Phone-Actions-Info.svg";
import { ReactComponent as BathroomTubTowel } from "./Streamline/Bathroom-Tub-Towel.svg";
import { ReactComponent as InterfaceBookmarkOutline } from "./Streamline/Interface-Bookmark-Outline.svg";
import { ReactComponent as InterfaceBookmark } from "./Streamline/Interface-Bookmark.svg";
import { ReactComponent as JobChooseCandidate1 } from "./Streamline/Job-Choose-Candidate-1.svg";
import { ReactComponent as RemoveCircleAlternate } from "./Streamline/Remove-Circle-Alternate.svg";
import { ReactComponent as MapsPin1 } from "./Streamline/Maps-Pin-1.svg";
import { ReactComponent as CloseSidePanel } from "./Streamline/Close-Side-Panel.svg";
import { ReactComponent as ConversationChat2Alternate } from "./Streamline/Conversation-Chat-2-Alternate.svg";
import { ReactComponent as CertifiedRibbon1 } from "./Streamline/Certified-Ribbon-1.svg";
import { ReactComponent as Lock1 } from "./Streamline/Lock-1.svg";
import { ReactComponent as ToysTeddyBear } from "./Streamline/Toys-Teddy-Bear.svg";
import { ReactComponent as ConversationChat2 } from "./Streamline/Conversation-Chat-2.svg";
import { ReactComponent as TaskChecklistCheck } from "./Streamline/Task-Checklist-Check.svg";
import { ReactComponent as AlertAlarmBellRing } from "./Streamline/Alert-Alarm-Bell-Ring.svg";
import { ReactComponent as TimeClockHand1 } from "./Streamline/Time-Clock-Hand-1.svg";
import { ReactComponent as JobSearchProfile } from "./Streamline/Job-Search-Profile.svg";
import { ReactComponent as CheckBadge } from "./Streamline/Check-Badge.svg";
import { ReactComponent as Hyperlink2 } from "./Streamline/Hyperlink-2.svg";
import { ReactComponent as Calendar } from "./Streamline/Calendar.svg";
import { ReactComponent as LaundryMachine2 } from "./Streamline/Laundry-Machine-2.svg";
import { ReactComponent as PinLocation1 } from "./Streamline/Pin-Location-1.svg";
import { ReactComponent as CheckCircle1 } from "./Streamline/Check-Circle-1.svg";
import { ReactComponent as AnimalProductsTurkey } from "./Streamline/Animal-Products-Turkey.svg";
import { ReactComponent as ToiletSeat } from "./Streamline/Toilet-Seat.svg";
import { ReactComponent as BusinessDealHandshake } from "./Streamline/Business-Deal-Handshake.svg";
import { ReactComponent as ShoppingCartFull } from "./Streamline/Shopping-Cart-Full.svg";
import { ReactComponent as Baby1 } from "./Streamline/Baby-1.svg";
import { ReactComponent as Bus2 } from "./Streamline/Bus-2.svg";

export const BedSingleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <BedSingle />
  </SvgIcon>
);
export const BreakfastCerealIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <BreakfastCereal />
  </SvgIcon>
);
export const MultipleActionsCheck1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <MultipleActionsCheck1 />
  </SvgIcon>
);
export const SingleNeutralIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <SingleNeutral />
  </SvgIcon>
);
export const DrugsPillsBoxIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <DrugsPillsBox />
  </SvgIcon>
);
export const TimeClockCircleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <TimeClockCircle />
  </SvgIcon>
);
export const TripPinsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <TripPins />
  </SvgIcon>
);
export const LoveHeartHandsHold3Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <LoveHeartHandsHold3 />
  </SvgIcon>
);
export const CurrencyDollarBubbleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CurrencyDollarBubble />
  </SvgIcon>
);
export const RoadSignSideRoadLeft1AlternateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <RoadSignSideRoadLeft1Alternate />
  </SvgIcon>
);
export const SingleManActionsStarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <SingleManActionsStar />
  </SvgIcon>
);
export const SingleUserActionsChatIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <SingleUserActionsChat />
  </SvgIcon>
);
export const ChatTranslateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ChatTranslate />
  </SvgIcon>
);
export const FireworksPeopleWatchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <FireworksPeopleWatch />
  </SvgIcon>
);
export const Flag1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Flag1 />
  </SvgIcon>
);
export const ShirtPlainIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ShirtPlain />
  </SvgIcon>
);
export const QuestionCircleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <QuestionCircle />
  </SvgIcon>
);
export const TextOptionTranslateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <TextOptionTranslate />
  </SvgIcon>
);
export const ToolsWrenchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ToolsWrench />
  </SvgIcon>
);
export const FamilyHomeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <FamilyHome />
  </SvgIcon>
);
export const TimeClockCircleAlternateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <TimeClockCircleAlternate />
  </SvgIcon>
);
export const MoneyWalletOpenIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <MoneyWalletOpen />
  </SvgIcon>
);
export const CarRetro2Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CarRetro2 />
  </SvgIcon>
);
export const AwardTrophyStar1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <AwardTrophyStar1 />
  </SvgIcon>
);
export const MultipleCircleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <MultipleCircle />
  </SvgIcon>
);
export const PasswordDesktopApprovedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <PasswordDesktopApproved />
  </SvgIcon>
);
export const OpenSidePanelIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <OpenSidePanel />
  </SvgIcon>
);
export const MessagesBubbleHeart1AlternateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <MessagesBubbleHeart1Alternate />
  </SvgIcon>
);
export const CleaningSprayIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CleaningSpray />
  </SvgIcon>
);
export const PhoneActionsInfoIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <PhoneActionsInfo />
  </SvgIcon>
);
export const BathroomTubTowelIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <BathroomTubTowel />
  </SvgIcon>
);
export const InterfaceBookmarkOutlineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <InterfaceBookmarkOutline />
  </SvgIcon>
);
export const InterfaceBookmarkIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <InterfaceBookmark />
  </SvgIcon>
);
export const JobChooseCandidate1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <JobChooseCandidate1 />
  </SvgIcon>
);
export const RemoveCircleAlternateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <RemoveCircleAlternate />
  </SvgIcon>
);
export const MapsPin1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <MapsPin1 />
  </SvgIcon>
);
export const CloseSidePanelIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CloseSidePanel />
  </SvgIcon>
);
export const ConversationChat2AlternateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ConversationChat2Alternate />
  </SvgIcon>
);
export const CertifiedRibbon1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CertifiedRibbon1 />
  </SvgIcon>
);
export const Lock1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Lock1 />
  </SvgIcon>
);
export const ToysTeddyBearIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ToysTeddyBear />
  </SvgIcon>
);
export const ConversationChat2Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ConversationChat2 />
  </SvgIcon>
);
export const TaskChecklistCheckIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <TaskChecklistCheck />
  </SvgIcon>
);
export const AlertAlarmBellRingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <AlertAlarmBellRing />
  </SvgIcon>
);
export const TimeClockHand1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <TimeClockHand1 />
  </SvgIcon>
);
export const JobSearchProfileIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <JobSearchProfile />
  </SvgIcon>
);
export const CheckBadgeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CheckBadge />
  </SvgIcon>
);
export const Hyperlink2Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Hyperlink2 />
  </SvgIcon>
);
export const CalendarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Calendar />
  </SvgIcon>
);
export const LaundryMachine2Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <LaundryMachine2 />
  </SvgIcon>
);
export const PinLocation1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <PinLocation1 />
  </SvgIcon>
);
export const CheckCircle1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CheckCircle1 />
  </SvgIcon>
);
export const AnimalProductsTurkeyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <AnimalProductsTurkey />
  </SvgIcon>
);
export const ToiletSeatIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ToiletSeat />
  </SvgIcon>
);
export const BusinessDealHandshakeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <BusinessDealHandshake />
  </SvgIcon>
);
export const ShoppingCartFullIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ShoppingCartFull />
  </SvgIcon>
);
export const Baby1Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Baby1 />
  </SvgIcon>
);
export const Bus2Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Bus2 />
  </SvgIcon>
);
