import { Grid, GridProps, SvgIconProps } from "@mui/material";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";

export interface IconAndBodyEmphasisProps extends GridProps {
  icon: React.ComponentType<SvgIconProps>;
  iconColor: string;
  title: string;
}

export const IconAndBodyEmphasis: React.FC<IconAndBodyEmphasisProps> = ({
  icon: IconComponent,
  title,
  iconColor,
  sx,
  ...restGridProps
}) => {
  return (
    <>
      <Grid container justifyContent="center" sx={{ marginBottom: "20px", ...sx }} {...restGridProps}>
        <Grid item>
          <IconComponent
            sx={{
              marginRight: 1,
              "& path": {
                fill: iconColor,
              },
            }}
          />
        </Grid>
        <Grid item>
          <BodyEmphasis>{title}</BodyEmphasis>
        </Grid>
      </Grid>
    </>
  );
};
