import { Box, Grid, Link as MuiLink, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { MessageResponseDTO, RoleType, ThreadDetailsDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidInbox } from "src/i18n/Namespaces";
import { getGenericError } from "src/i18n/Utilities";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { DesktopMinWidth } from "src/pages/inbox/Inbox";
import ReplyToThreadSection from "src/pages/inbox/inboxComponents/ReplyToThreadSection";
import SurveyLinkBanner from "src/pages/inbox/inboxComponents/SurveyLinkBanner";
import ThreadDetailsSection from "src/pages/inbox/inboxComponents/ThreadDetailsSection";
import ThreadMessage from "src/pages/inbox/inboxComponents/ThreadMessage";
import { InboxTabState, useInboxContext } from "src/pages/inbox/InboxContext";
import { showSurveyBanner } from "src/pages/inbox/InboxHelpers";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import Constraint from "src/reusable_view_elements/Constraint";
import { ViewAPIResBannerControlEnum } from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { scrollToId } from "src/utilities/ScrollToId";

const SystemDeleteInformation = () => {
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const { t, ready } = useTranslation([nsMedicaidInbox]);

  return (
    <Box bgcolor={CivColors.lightGray} textAlign="center" p="16px" my="24px" mx={desktopSize ? "24px" : "16px"}>
      {ready && <Body>{t("messages_over_year_old.description", { ns: nsMedicaidInbox })}</Body>}
    </Box>
  );
};

const RegularViewThread = () => {
  const { threadId } = useParams<{ jobPostId: string; threadId: string }>();
  const { showSnackbar } = useNotification();
  const { userSession, isUserType } = useUserSession();
  const { setReportResponseView } = useMedicaidContext();
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const history = useHistory();
  const { tab, setTotalUnreadCount } = useInboxContext();
  const { program } = useSupportAdminContext();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsCommonToasts]);

  const [loadingDetails, setLoadingDetails] = useState<boolean>();
  const [loadingMessages, setLoadingMessages] = useState<boolean>();
  const [messages, setMessages] = useState<MessageResponseDTO[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [details, setDetails] = useState<ThreadDetailsDTO>();

  useEffect(() => {
    setLoadingDetails(true);
    messagingClient
      .getThreadDetails({ threadId })
      .then((res) => {
        setDetails(res.data);
      })
      .catch(() => {
        showSnackbar(getGenericError(t), "error");
      })
      .finally(() => {
        setLoadingDetails(false);
      });

    // Load messages
    setLoadingMessages(true);
    messagingClient
      .getMessageListForThread({ threadId })
      .then((res) => {
        setMessages(res.data.messageList);
        setUnreadCount(res.data.unreadMessageCount);

        // Auto-scroll to first unread message or last message
        if (res.data.unreadMessageCount > 0) {
          const firstUnread = res.data.messageList.length - res.data.unreadMessageCount;
          scrollToId(firstUnread.toString());
        } else {
          const lastMessage = res.data.messageList.length - 1;
          scrollToId(lastMessage.toString());
        }
      })
      .catch(() => {
        showSnackbar(getGenericError(t), "error");
      })
      .finally(() => {
        setLoadingMessages(false);
      });

    // Hide User Report banner
    setReportResponseView(ViewAPIResBannerControlEnum.HIDDEN);
  }, []);

  useEffect(() => {
    if (unreadCount > 0) {
      messagingClient
        .getUnreadThreadCount({
          selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
        })
        .then((res) => {
          setTotalUnreadCount(res.data); // global unread messages count (i.e. shown in navbar)
        })
        .catch(() => {
          // It's okay to not show error to user in this case
        });
    }
  }, [unreadCount]);

  const GoBackLinkComponent = () => {
    let __link: string = MEDICAID_INBOX_ROUTES.allThreads;
    let __text: string = t("button.back_to_inbox", { ns: nsCommonFormsBtns });
    if (tab === InboxTabState.unread_threads) __link = MEDICAID_INBOX_ROUTES.unreadThreads;
    if (tab === InboxTabState.starred_threads) __link = MEDICAID_INBOX_ROUTES.starredThreads;
    if (tab === InboxTabState.archived_threads) __link = MEDICAID_INBOX_ROUTES.archivedThreads;

    // @ts-ignore
    const previousLocation: string | undefined = history.location.state?.from;
    if (previousLocation && previousLocation.includes("homecare/medicaid/inbox/threads/user") && details) {
      __link = generatePath(MEDICAID_INBOX_ROUTES.threadsWithUser, { userId: details.otherUser.id });
      __text = t("button.back_to_inbox", { ns: nsCommonFormsBtns });
    }

    return (
      <Box p="16px" bgcolor={CivColors.white}>
        <Constraint columns={14}>
          <MuiLink component="button" onClick={() => history.push(__link)}>
            <Grid container alignItems="center">
              <ChevronLeftRoundedIcon style={{ marginRight: "8px" }} />
              <Body>{__text}</Body>
            </Grid>
          </MuiLink>
        </Constraint>
      </Box>
    );
  };

  return (
    <>
      {ready && <GoBackLinkComponent />}

      {details && showSurveyBanner(details.otherUser.roleType, isUserType) && <SurveyLinkBanner threadId={threadId} />}

      {!loadingDetails && details && <ThreadDetailsSection {...details} />}

      <Constraint columns={14}>
        <SystemDeleteInformation />
        {loadingMessages && loadingDetails && (
          <LoadingCircle text={ready ? t("loading_conversation.label", { ns: nsMedicaidInbox }) : ""} />
        )}
        {!loadingMessages &&
          messages
            .sort((msg1, msg2) => msg1.createdAt - msg2.createdAt)
            .map((message, i) => (
              <ThreadMessage
                key={i}
                id={i.toString()}
                messageOwner={message.sender.id === userSession?.id}
                avatarId={message.sender.roleType === RoleType.PROVIDER ? message.sender.id : undefined}
                hasImg={message.sender.roleType === RoleType.PROVIDER ? message.sender.hasImg : undefined}
                date={message.createdAt}
                firstName={message.sender.firstName}
                lastInitial={
                  message.sender.roleType !== RoleType.CONSUMER && message.sender.id !== userSession?.id
                    ? message.sender.lastNameInitial
                    : undefined
                }
                roleType={message.sender.roleType}
                organization={desktopSize ? message.sender.organizationName : undefined}
                message={message.messageText}
                expand={messages.length === i + 1 || messages.length === i + 2 || messages.length - unreadCount < i + 1}
              />
            ))}
        {details && (
          <ReplyToThreadSection
            threadId={threadId}
            starred={details.starred}
            archived={details.archived}
            messages={messages}
            setMessages={setMessages}
            setStarred={(newStatus: boolean) => {
              // Update details object so that it updates the DOM for user
              setDetails({
                ...details,
                starred: newStatus,
              });
            }}
            setArchived={(newStatus: boolean) => {
              // Update details object so that it updates the DOM for user
              setDetails({
                ...details,
                archived: newStatus,
              });
            }}
          />
        )}
      </Constraint>
    </>
  );
};

export default RegularViewThread;
