import { Box, Grid, IconButton, Menu, useMediaQuery } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { MessageResponseDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidInbox } from "src/i18n/Namespaces";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import InventoryIcon from "@mui/icons-material/Inventory";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { DesktopMinWidth } from "src/pages/inbox/Inbox";
import ConfirmationDialog from "src/pages/inbox/inboxComponents/ConfirmationDialog";
import { InboxTabState, useInboxContext } from "src/pages/inbox/InboxContext";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import Form from "src/reusable_view_elements/form_fields/Form";
import MenuItem from "src/reusable_view_elements/MenuItem";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";
import { object, string } from "yup";

interface ReplyToThreadSectionProps {
  threadId: string;
  starred: boolean;
  archived: boolean;
  messages: MessageResponseDTO[];
  setMessages: (messages: MessageResponseDTO[]) => void;
  setStarred: (newStatus: boolean) => void;
  setArchived: (newStatus: boolean) => void;
}

const ReplyToThreadSection = (props: ReplyToThreadSectionProps) => {
  const { tab, setTab, resetSearchAndPagination } = useInboxContext();
  const { showSnackbar } = useNotification();
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const { userSession } = useUserSession();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsCommonToasts]);

  const [showMessageField, setShowMessageField] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = useState<boolean>(false);
  const [showUnfavoriteModal, setShowUnfavoriteModal] = useState<boolean>(false);

  const [showActionsMenu, setShowActionsMenu] = useState<boolean>(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);

  async function toggleFavoriteThread() {
    try {
      if (props.starred) {
        await messagingClient.unStarThread({ threadId: props.threadId });
        props.setStarred(false);
        //TODO: Close the 'Unarchive modal' before showing the snackbar. See AssistiveRoleThreadDetailsSection.tsx
        showSnackbar(t("inbox_thread.success.removed_from_favorites", { ns: nsCommonToasts }), "success");
      } else {
        await messagingClient.starThread({ threadId: props.threadId });
        props.setStarred(true);
        showSnackbar(t("inbox_thread.success.added_to_favorites", { ns: nsCommonToasts }), "success");
      }
    } catch (e: unknown) {
      showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
    }
  }

  async function archiveThread() {
    try {
      await messagingClient.archiveThread({ threadId: props.threadId });
      setShowArchiveModal(false);
      props.setArchived(true);
      resetSearchAndPagination();
      setTab(InboxTabState.archived_threads);
      showSnackbar(t("inbox_thread.success.archived", { ns: nsCommonToasts }), "success");
    } catch (e: unknown) {
      showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
    }
  }

  async function unarchiveThread() {
    try {
      await messagingClient.unArchiveThread({ threadId: props.threadId });
      setShowUnarchiveModal(false);
      props.setArchived(false);
      resetSearchAndPagination();
      setTab(InboxTabState.all_threads);
      showSnackbar(t("inbox_thread.success.unarchived", { ns: nsCommonToasts }), "success");
    } catch (e: unknown) {
      showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
    }
  }

  interface ReplyField {
    messageText: string;
  }

  const handleReply = async (values: ReplyField, { resetForm }: FormikHelpers<ReplyField>) => {
    try {
      const res = await messagingClient.sendMessage({
        threadId: props.threadId,
        messageText: values.messageText,
      });
      const successfullySentMsg = res.data;

      // Update the list of messages with sent message
      if (props.messages) {
        const updatedMessageList = [...props.messages, successfullySentMsg];

        props.setMessages(updatedMessageList);

        // Scroll down so that most recently sent message is in view
        const idOfNewMsg = updatedMessageList.length.toString();
        scrollToId(idOfNewMsg);
      }
      resetForm(); // Clear the reply box
    } catch (e) {
      setShowError(true);
    }
  };

  const messagesFromUser = props.messages.filter((msg) => msg.sender.id === userSession?.id).length;
  const canShowArchiveBtn = messagesFromUser > 0;

  const REPLY_MAX_LENGTH = 500;
  const validationSchema = object({
    messageText: string()
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(REPLY_MAX_LENGTH, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
  });

  const ThreadActionsMenu = () => (
    <Menu
      id="thread actions menu"
      anchorEl={actionsMenuAnchor}
      keepMounted // mounted in the DOM for accessibility
      open={showActionsMenu}
      onClose={() => setShowActionsMenu(false)}
    >
      {canShowArchiveBtn && (
        <MenuItem
          Icon={InventoryIcon}
          style={{ minWidth: "185px" }}
          aria-label="archive thread"
          onClick={() => {
            setShowActionsMenu(false);
            setShowArchiveModal(true);
          }}
        >
          {t("button.archive", { ns: nsCommonFormsBtns })}
        </MenuItem>
      )}
      <MenuItem
        Icon={StarOutlineRoundedIcon}
        SelectedIcon={StarRoundedIcon}
        style={{ minWidth: "185px" }}
        selected={props.starred}
        aria-label="favorite thread"
        onClick={async () => {
          setShowActionsMenu(false);
          if (tab === InboxTabState.starred_threads) {
            setShowUnfavoriteModal(true);
          } else {
            await toggleFavoriteThread();
          }
        }}
      >
        {props.starred
          ? t("button.remove_from_favorites", { ns: nsCommonFormsBtns })
          : t("button.favorite", { ns: nsCommonFormsBtns })}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <ConfirmationDialog
        title={t("are_you_sure_archive_conversation.label", { ns: nsMedicaidInbox })}
        body={t("are_you_sure_archive_conversation.description", { ns: nsMedicaidInbox })}
        isDialogOpen={showArchiveModal}
        onYesClick={archiveThread}
        onNoClick={() => setShowArchiveModal(false)}
      />

      <ConfirmationDialog
        title={t("are_you_sure_unarchive_conversation.label", { ns: nsMedicaidInbox })}
        body={t("are_you_sure_unarchive_conversation.description", { ns: nsMedicaidInbox })}
        isDialogOpen={showUnarchiveModal}
        onYesClick={unarchiveThread}
        onNoClick={() => setShowUnarchiveModal(false)}
      />

      <ConfirmationDialog
        title={t("are_you_sure_unfavorite_conversation.label", { ns: nsMedicaidInbox })}
        body={t("are_you_sure_unfavorite_conversation.description", { ns: nsMedicaidInbox })}
        isDialogOpen={showUnfavoriteModal}
        onYesClick={toggleFavoriteThread}
        onNoClick={() => setShowUnfavoriteModal(false)}
      />

      {ready && <ThreadActionsMenu />}

      {showError && ready && (
        <Box bgcolor={CivColors.coreOrange} color={CivColors.white} p="14px">
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Box />
            <Body align="center">{t("message_not_sent_error.label", { ns: nsMedicaidInbox })}</Body>
            <IconButton aria-label="close error message" onClick={() => setShowError(false)} size="large">
              <CloseRoundedIcon htmlColor={CivColors.white} />
            </IconButton>
          </Grid>
        </Box>
      )}
      <Box py="24px" mx={desktopSize ? "24px" : "16px"}>
        {showMessageField && !props.archived && ready && (
          <Formik initialValues={{ messageText: "" }} validationSchema={validationSchema} onSubmit={handleReply}>
            {({ isSubmitting }) => {
              return (
                <Form localizationReady={ready}>
                  <Box>
                    <Body color="textSecondary" paragraph>
                      {t("never_share_phi_thread_details_view.description", { ns: nsMedicaidInbox })}
                    </Body>
                    <FormTextField
                      name="messageText"
                      label={t("field.message.label", { ns: nsCommonFormsBtns })}
                      placeholder={t("field.message.placeholder", { ns: nsCommonFormsBtns })}
                      helperText={""}
                      multiline
                      maximumlength={REPLY_MAX_LENGTH}
                      required={isRequiredField("messageText", validationSchema)}
                    />
                    <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: "24px" }}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          type="button"
                          onClick={() => {
                            setShowMessageField(false);
                            setShowError(false);
                          }}
                        >
                          {t("button.cancel", { ns: nsCommonFormsBtns })}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {isSubmitting
                            ? t("button.sending", { ns: nsCommonFormsBtns })
                            : t("button.send", { ns: nsCommonFormsBtns })}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}

        {!showMessageField && !props.archived && ready && (
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
              <Grid container alignItems="center">
                <Button
                  variant="text"
                  type="button"
                  sx={{ leftMargin: 0, paddingLeft: 2, paddingRight: 0 }}
                  aria-label="show more actions button"
                  aria-haspopup="true"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setActionsMenuAnchor(event.currentTarget);
                    setShowActionsMenu(!showActionsMenu);
                  }}
                  startIcon={
                    <CloseRoundedIcon style={{ transform: showActionsMenu ? "rotate(0deg)" : "rotate(45deg)" }} />
                  }
                >
                  <Body>{t("button.more_actions", { ns: nsCommonFormsBtns })}</Body>
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" type="button" onClick={() => setShowMessageField(true)}>
                {t("button.reply", { ns: nsCommonFormsBtns })}
              </Button>
            </Grid>
          </Grid>
        )}

        {props.archived && (
          <Grid container justifyContent="flex-end" alignItems="center">
            <Button variant="text" type="button" onClick={() => setShowUnarchiveModal(true)}>
              {t("button.unarchive_conversation", { ns: nsCommonFormsBtns })}
            </Button>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ReplyToThreadSection;
