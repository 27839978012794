import { SendRounded } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Grid, IconButton } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MessageResponseDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidInbox } from "src/i18n/Namespaces";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { scrollToId } from "src/utilities/ScrollToId";
import { object, string } from "yup";

interface AssistiveRoleReplyToThreadSectionProps {
  threadId: string;
  archived: boolean;
  messages: MessageResponseDTO[];
  setMessages: (messages: MessageResponseDTO[]) => void;
  otherUserDisplayName: string;
}

const AssistiveRoleReplyToThreadSection = (props: AssistiveRoleReplyToThreadSectionProps) => {
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsCommonToasts]);
  const { inboxSidePanelUserId } = useInboxContext();
  const [showError, setShowError] = useState<boolean>(false);

  interface ReplyField {
    messageText: string;
  }

  const handleReply = async (values: ReplyField, { resetForm }: FormikHelpers<ReplyField>) => {
    try {
      const res = await messagingClient.sendMessage({
        threadId: props.threadId,
        messageText: values.messageText,
      });
      const successfullySentMsg = res.data;

      // Update the list of messages with sent message
      if (props.messages) {
        const updatedMessageList = [...props.messages, successfullySentMsg];

        props.setMessages(updatedMessageList);

        // Scroll down so that most recently sent message is in view
        const idOfNewMsg = updatedMessageList.length.toString();
        scrollToId(idOfNewMsg);
      }
      resetForm(); // Clear the reply box
    } catch (e) {
      setShowError(true);
    }
  };

  const REPLY_MAX_LENGTH = 500;
  const validationSchema = object({
    messageText: string().max(REPLY_MAX_LENGTH, ({ max, value }) =>
      t("field.generic.count_char_over_limit.label", {
        ns: nsCommonFormsBtns,
        count: value.length - max,
      }),
    ),
  });

  return (
    <>
      {showError && ready && (
        <Box bgcolor={CivColors.coreOrange} color={CivColors.white} p="14px">
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Box />
            <Body align="center">{t("message_not_sent_error.label", { ns: nsMedicaidInbox })}</Body>
            <IconButton aria-label="close error message" size="large">
              <CloseRoundedIcon htmlColor={CivColors.white} />
            </IconButton>
          </Grid>
        </Box>
      )}
      <Box mx={0}>
        {!props.archived && ready && (
          <Formik initialValues={{ messageText: "" }} validationSchema={validationSchema} onSubmit={handleReply}>
            {({ isSubmitting, errors, values }) => {
              return (
                <Form localizationReady={ready}>
                  <Grid sx={{ paddingX: "12px" }} container>
                    <Grid item xs={11}>
                      <FormTextField
                        name="messageText"
                        placeholder={t("field.message.placeholder", {
                          ns: nsCommonFormsBtns,
                          context: "assistive",
                          name: props.otherUserDisplayName,
                        })}
                        helperText={""}
                        controlledRerender={inboxSidePanelUserId}
                        multiline
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          // TODO: Refactor TextField to enable proper display/hide of helper text
                          "& > span": {
                            display: errors.messageText ? "grid" : "none",
                            "& > div": {
                              "& > p": {
                                display: "none",
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} alignContent="center" textAlign="end">
                      <IconButton
                        type="submit"
                        disabled={isSubmitting || !values.messageText}
                        sx={{ height: "48px", width: "48px" }}
                      >
                        <SendRounded htmlColor={CivColors.coreDarkNavy} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}
      </Box>
    </>
  );
};

export default AssistiveRoleReplyToThreadSection;
