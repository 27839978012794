import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { nsMedicaidJobPost } from "src/i18n/Namespaces";
import JobPostDetailsContent from "src/pages/medicaidAccount/sharedComponents/JobPostDetailsContent";
import Footer from "src/reusable_view_elements/Footer";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import CivColors from "src/themes/civilization/CivColors";

const JobDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t, ready } = useTranslation([nsMedicaidJobPost]);

  return (
    <>
      <Helmet>
        <title>
          {ready
            ? t("browser_tab_title.job_details", { ns: nsMedicaidJobPost })
            : "Carina | Medicaid Provider Profile Details"}
        </title>
      </Helmet>
      <MedicaidNavbar />
      <main id="main-content" style={{ backgroundColor: CivColors.lightGray }}>
        <JobPostDetailsContent jobPostId={id} />
      </main>
      <Footer />
    </>
  );
};

export default JobDetails;
