import { Redirect, useParams } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { InboxTabState, useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import AssistiveRoleViewThread from "src/pages/inbox/views/AssistiveRoleViewThread";
import RegularViewThread from "src/pages/inbox/views/RegularViewThread";

const ViewThread = () => {
  const { isAssistiveRoleView } = useUserSession();
  const { setSelectedThreadId, setContextOtherUserId, setSearchQuery, setInboxSidePanelUserId, tab } =
    useInboxContext();
  const { threadId } = useParams<{ threadId: string }>();

  if (isAssistiveRoleView()) {
    if (!threadId) return <AssistiveRoleViewThread />;
    setSelectedThreadId(threadId);
    setContextOtherUserId(undefined);
    setSearchQuery("");
    setInboxSidePanelUserId(undefined);
    const route = (tb: InboxTabState): string => {
      switch (tb) {
        case InboxTabState.all_threads:
          return MEDICAID_INBOX_ROUTES.allThreads;
        case InboxTabState.archived_threads:
          return MEDICAID_INBOX_ROUTES.archivedThreads;
        case InboxTabState.starred_threads:
          return MEDICAID_INBOX_ROUTES.starredThreads;
        case InboxTabState.client_threads:
          return MEDICAID_INBOX_ROUTES.clientThreads;
        default:
          return MEDICAID_INBOX_ROUTES.allThreads;
      }
    };
    return <Redirect to={route(tab)} />;
  }

  return <RegularViewThread />;
};

export default ViewThread;
