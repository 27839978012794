import { Box, Grid, Link as MuiLink, List, useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import {
  CalendarIcon,
  ChatTranslateIcon,
  CheckBadgeIcon,
  CheckCircle1Icon,
  FireworksPeopleWatchIcon,
  Flag1Icon,
  JobChooseCandidate1Icon,
  PinLocation1Icon,
  QuestionCircleIcon,
  RemoveCircleAlternateIcon,
  SingleUserActionsChatIcon,
  TimeClockCircleAlternateIcon,
} from "src/assets/icons/StreamlineIcons";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { useNotification } from "src/context/NotificationContext";
import { FunderState, UserType, useUserSession } from "src/context/UserSessionContext";
import {
  MedicaidProviderProfile_DTO_Response_ProfileDetails,
  Permission,
  ProviderCredential_DTO_Response_Details,
  ProviderNumber_DTO_Response_Details,
  ProviderNumberTypeEnum,
  ProviderProfileTagEnum,
  ProviderProfileTagEnum_Category,
  ReportSourceEnum,
  TravelDataDTO,
  TravelType,
} from "src/generated/api_types";
import {
  nsCommonAlt,
  nsCommonAria,
  nsCommonFormsBtns,
  nsCommonToasts,
  nsCommonTooltips,
  nsMedicaidProviderProfile,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedProviderProfileTag } from "src/i18n/TagHelpers";
import { ROUTES } from "src/MainRouter";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { getNextId, ProviderListOrigin, useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { Medicaid_Provider_Dashboard_Routes } from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import { Medicaid_Proxy_Provider_Routes } from "src/pages/medicaidAccount/ProxyProvider/ProxyProviderRouter";
import FirstMessageDialog, { FirstMessageContext } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import { getTagsObject } from "src/pages/medicaidAccount/sharedComponents/ProviderProfileTagHelpers";
import {
  bookmarkProfile,
  getDirectionsData,
  getProviderProfile,
  unBookmarkProfile,
} from "src/pages/medicaidAccount/sharedComponents/Queries";
import { UserReportModal, UserReportResponseBanner } from "src/pages/medicaidAccount/sharedComponents/ReportUserModal";
import {
  AgeRangeOptions,
  CareFrequencyOptions,
  MovementSkillsOptions,
  PetOptions,
  TransportSkillsOptions,
  TypeOfSupportOptions,
  WorkEnvOptions,
} from "src/pages/onboarding/step_pages/MedicaidProfile";
import Avatar from "src/reusable_view_elements/Avatar";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import CredentialBadge from "src/reusable_view_elements/CredentialBadge";
import { ViewAPIResBannerControlEnum } from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import IconListItem from "src/reusable_view_elements/IconListItem";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MessageFollowUpButton from "src/reusable_view_elements/MessageFollowUpButton";
import Popover from "src/reusable_view_elements/Popover";
import LgbtPopover from "src/reusable_view_elements/popovers/LgbtPopover";
import NonbinaryPopover from "src/reusable_view_elements/popovers/NonbinaryPopover";
import NurseDelegationPopover from "src/reusable_view_elements/popovers/NurseDelegationPopover";
import Section from "src/reusable_view_elements/Section";
import Separator from "src/reusable_view_elements/Separator";
import { Snackbar } from "src/reusable_view_elements/Snackbar";
import TravelInformation from "src/reusable_view_elements/TravelInformation";
import {
  Body,
  BodyEmphasis,
  BodyEmphasisHeading,
  BodyTitle,
  SectionTitle,
} from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { imageExists, insertBetween } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";

const GeneralSkillsOptionsWithoutTooltips = [
  { value: ProviderProfileTagEnum.HOUSEKEEPING, label: "Housekeeping" },
  { value: ProviderProfileTagEnum.LAUNDRY, label: "Laundry" },
  { value: ProviderProfileTagEnum.MEAL_PREP, label: "Meal preparation" },
  { value: ProviderProfileTagEnum.SHOPPING, label: "Shopping" },
  { value: ProviderProfileTagEnum.PERSONAL_CARE, label: "Hygiene and personal care" },
  { value: ProviderProfileTagEnum.MEDS_MGMT, label: "Medication management" },
  { value: ProviderProfileTagEnum.BEHAVIOR_CHALLENGE, label: "Behavioral health" },
];

interface ProviderProfileDetailsContentProps {
  profileId: string;
  isDrawer?: boolean;
  hideActions?: boolean; // Hide call-to-action buttons such as Save, Message, Report User
  setFirstName?: (value: string) => void; // For passing state back up the tree if needed
  setLastName?: (value: string) => void;
}

export const getTranslatedProviderCred = (credential: ProviderCredential_DTO_Response_Details) => {
  switch (credential.code) {
    case "UNKNOWN":
      return {
        nameKey: "training_credential.label_unknown",
        descriptionKey: "training_credential.description_unknown",
      };
    case "AP":
      return {
        nameKey: "training_credential.label_ap",
        descriptionKey: "training_credential.description_ap",
      };
    case "HM":
      return {
        nameKey: "training_credential.label_hm",
        descriptionKey: "training_credential.description_hm",
      };
    case "LP":
      return {
        nameKey: "training_credential.label_lp",
        descriptionKey: "training_credential.description_lp",
      };
    case "NC":
      return {
        nameKey: "training_credential.label_nc",
        descriptionKey: "training_credential.description_nc",
      };
    case "OSPI":
      return {
        nameKey: "training_credential.label_ospi",
        descriptionKey: "training_credential.description_ospi",
      };
    case "RN":
      return {
        nameKey: "training_credential.label_rn",
        descriptionKey: "training_credential.description_rn",
      };
    case "COMPLIANTLIMITED":
      return {
        nameKey: "training_credential.label_compliantlimited",
        descriptionKey: "training_credential.description_compliantlimited",
      };
    case "COMPLIANTRESPITE":
      return {
        nameKey: "training_credential.label_compliantrespite",
        descriptionKey: "training_credential.description_compliantrespite",
      };
    case "NONCOMPLIANT":
      return {
        nameKey: "training_credential.label_noncompliant",
        descriptionKey: "training_credential.description_noncompliant",
      };
    case "UNCERTIFIED":
      return {
        nameKey: "training_credential.label_uncertified",
        descriptionKey: "training_credential.description_uncertified",
      };
    case "FAMILY":
      return {
        nameKey: "training_credential.label_family",
        descriptionKey: "training_credential.description_family",
      };
    case "NEW":
      return {
        nameKey: "training_credential.label_new",
        descriptionKey: "training_credential.description_new",
      };
    case "EXEMPT":
      return {
        nameKey: "training_credential.label_exempt",
        descriptionKey: "training_credential.description_exempt",
      };
    case "HCW":
      return {
        nameKey: "training_credential.label_hcw",
        descriptionKey: "training_credential.description_hcw",
      };
    case "PSW":
      return {
        nameKey: "training_credential.label_psw",
        descriptionKey: "training_credential.description_psw",
      };
    case "PCA":
      return {
        nameKey: "training_credential.label_pca",
        descriptionKey: "training_credential.description_pca",
      };
    case "ADVANCED":
      return {
        nameKey: "training_credential.label_advanced",
        descriptionKey: "training_credential.description_advanced",
      };
    case "I&E":
      return {
        nameKey: "training_credential.label_i&e",
        descriptionKey: "training_credential.description_i&e",
      };
    default:
      return {
        nameKey: "training_credential.label_unknown",
        descriptionKey: "training_credential.description_unknown",
      };
  }
};

export const getORProviderNumber = (
  credential: ProviderCredential_DTO_Response_Details,
  providerNumbers: ProviderNumber_DTO_Response_Details[],
) => {
  return providerNumbers.map((providerNumber) => {
    if (providerNumber.providerNumberType === ProviderNumberTypeEnum.HCW && credential.code === "HCW")
      return `HCW #: ${providerNumber.providerNumber}`;
    if (providerNumber.providerNumberType === ProviderNumberTypeEnum.PSW && credential.code === "PSW")
      return `PSW #: ${providerNumber.providerNumber}`;
    if (providerNumber.providerNumberType === ProviderNumberTypeEnum.PCA && credential.code === "PCA")
      return `PCA #: ${providerNumber.providerNumber}`;
  });
};

const ProviderProfileDetailsContent = ({
  profileId,
  isDrawer = false,
  hideActions = false,
  setFirstName,
  setLastName,
}: ProviderProfileDetailsContentProps) => {
  const { showSnackbar, closeSnackbar, useMainSnackbar } = useNotification();
  const { isInEnv } = useConfig();
  const { userSession, isUserType, isFunderState, isAssistiveRoleView } = useUserSession();
  const {
    providerListOrigin,
    providerIdList,
    providerProfileList,
    setProviderProfileList,
    reportResponseView,
    setReportResponseView,
  } = useMedicaidContext();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const { t, ready } = useTranslation([
    nsMedicaidProviderProfile,
    nsCommonFormsBtns,
    nsCommonTooltips,
    nsCommonToasts,
    nsMedicaidTagsEnums,
    nsCommonAria,
    nsCommonAlt,
  ]);

  const [providerProfile, setProviderProfile] = useState<MedicaidProviderProfile_DTO_Response_ProfileDetails>();
  const [isLoadingProfile, setLoadingProfile] = useState(false);
  const [isLoadingTravel, setLoadingTravel] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [carTravel, setCarTravel] = useState<TravelDataDTO>();
  const [busTravel, setBusTravel] = useState<TravelDataDTO>();
  const [showMessageModal, setMessageModal] = useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = useState(false);

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/
  /************************************************* *************************************************
   The next functions update the providerProfile object without having to
   make a call to the BE. This prevents rerendering the page, which would break the focus sequence of
   any open 'first message success snackbar'
   ************************************************* *************************************************/
  const updateProviderProfileAfterFirstMessage = (threadId?: string) => {
    if (!threadId || !providerProfile) return;
    const updatedProviderProfile = { ...providerProfile, threadIds: [threadId] };
    setProviderProfile(updatedProviderProfile);
  };

  const updateProfileAfterBookmarking = (isBookmarked: boolean) => {
    const updatedProviderProfileList = providerProfileList?.map((profile) => {
      return profile.entity.provider.id === profileId
        ? { ...profile, entity: { ...profile.entity, bookmarked: isBookmarked } }
        : profile;
    });
    setProviderProfileList(updatedProviderProfileList);
  };
  /************************************************* *********************************************************/

  useEffect(() => {
    // make sure user report banner is hidden on component mount
    setReportResponseView(ViewAPIResBannerControlEnum.HIDDEN);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!userSession) {
          throw Error("Missing user session");
        }

        setLoadingProfile(true);

        const resProviderProfile = await getProviderProfile(profileId);
        setProviderProfile(resProviderProfile.data);
        if (setFirstName) {
          setFirstName(resProviderProfile.data.provider.firstName);
        }
        if (setLastName) {
          setLastName(resProviderProfile.data.provider.lastName);
        }

        if (resProviderProfile.data.hasImg) {
          const profileImage = `https://s3.us-west-2.amazonaws.com/${
            isInEnv(EnvName.PROD) ? "production.carina.profile.photos" : "carina.profile.photos"
          }/${profileId}.jpg`;
          imageExists(profileImage, (exists) => {
            if (exists) {
              setImageUrl(profileImage);
            } else {
              setImageUrl(undefined);
            }
          });
        } else {
          setImageUrl(undefined);
        }

        if (isUserType(UserType.Consumer)) {
          setLoadingTravel(true);
          const resCarDirections = await getDirectionsData(
            TravelType.CAR,
            userSession.geoData.id,
            resProviderProfile.data.geoDataCityState.id,
          );
          setCarTravel(resCarDirections.data);

          const resBusDirections = await getDirectionsData(
            TravelType.BUS,
            userSession.geoData.id,
            resProviderProfile.data.geoDataCityState.id,
          );
          setBusTravel(resBusDirections.data);
        }
      } catch (e) {
        showSnackbar(
          t(
            "provider_details.error.something_wrong_loading",
            "Something went wrong while loading provider profile details. Please try again.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } finally {
        setLoadingProfile(false);
        if (isUserType(UserType.Consumer)) {
          setLoadingTravel(false);
        }
      }
    })();
  }, [profileId]);

  useEffect(() => {
    if (reportResponseView !== ViewAPIResBannerControlEnum.HIDDEN) scrollToId("nav-bar-carina-logo");
  }, [reportResponseView]);

  /**
   * --------- LOCALIZATION HELPER FUNCTIONS
   */

  function getTranslatedProviderTag(tag: ProviderProfileTagEnum): string {
    return t(getLocalizedProviderProfileTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  const getTranslatedLastUpdated = (timestamp?: number): string => {
    if (timestamp) {
      const rightNow = new Date().getTime();
      const difInTime = rightNow - timestamp;
      const difInDays = difInTime / (1000 * 3600 * 24);
      return t("updated_count_days_ago", { ns: nsMedicaidProviderProfile, count: Math.floor(difInDays) });
    }
    return t("updated_unknown.description", { ns: nsMedicaidProviderProfile });
  };

  /**
   * ----------- END LOCALIZATION HELPER FUNCTIONS
   */

  if (!ready) {
    return (
      <Section minHeight="100vh">
        <Constraint columns={8} textAlign="center">
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  if (isLoadingProfile) {
    return (
      <Section height="100vh">
        <Constraint columns={8} textAlign="center">
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  if (!providerProfile) {
    return (
      <Section minHeight="80vh">
        <Constraint columns={8} textAlign="center">
          <SectionTitle>{t("provider_profile_cannot_found.label", { ns: nsMedicaidProviderProfile })}</SectionTitle>
        </Constraint>
      </Section>
    );
  }

  const isProviderProfileOwner = userSession?.id && userSession?.id === providerProfile?.provider.id;
  const tagsObj = getTagsObject(providerProfile);

  const icon = (option: ProviderProfileTagEnum, category: string) => {
    if (
      (category === "typeOfCare" && tagsObj.typeOfCareTag.includes(option)) ||
      (category === "typeOfSupport" && tagsObj.typeOfSupportTags.includes(option)) ||
      (category === "ageRange" && tagsObj.ageRangeTags.includes(option)) ||
      (category === "generalSkills" && tagsObj.generalSkillTags.includes(option)) ||
      (category === "movementSkills" && tagsObj.movementSkillTags.includes(option)) ||
      (category === "transportSkills" && tagsObj.transportSkillTags.includes(option)) ||
      (category === "generalWorkEnvironment" && tagsObj.generalWorkEnvironmentTags.includes(option)) ||
      (category === "workEnvironment" && tagsObj.workEnvironmentTags.includes(option)) ||
      (category === "pets" && tagsObj.petTags.includes(option))
    ) {
      return <CheckCircle1Icon titleAccess={t("icon.checked.label", { ns: nsCommonAria })} />;
    } else {
      return <RemoveCircleAlternateIcon titleAccess={t("icon.unchecked.label", { ns: nsCommonAria })} />;
    }
  };

  const handleSave = async () => {
    try {
      if (providerProfile.bookmarked) {
        await unBookmarkProfile(providerProfile.id);
        updateProfileAfterBookmarking(false);
      } else {
        await bookmarkProfile(providerProfile.id);
        updateProfileAfterBookmarking(true);
      }
      setProviderProfile({ ...providerProfile, bookmarked: !providerProfile.bookmarked });
    } catch (e) {
      showSnackbar(
        t("error.processing_bookmark", "Error processing bookmark request. Please try again later.", {
          ns: nsCommonToasts,
        }),
        "error",
      );
    }
  };

  const ProviderGenderTags = () => {
    return (
      <Body>
        {insertBetween(
          ", ", // Insert a comma between each array item; avoid using join method because it will stringify the popover component
          tagsObj.providerGenderTag.map((tag) =>
            tag === ProviderProfileTagEnum.NONBINARY ? (
              <NonbinaryPopover key={tag}>{getTranslatedProviderTag(tag)}</NonbinaryPopover>
            ) : (
              getTranslatedProviderTag(tag)
            ),
          ),
        )}
      </Body>
    );
  };

  const PreferredConsumerGenderTags = () => {
    const containsAllGenders =
      tagsObj.consumerGenderTag.includes(ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT) ||
      tagsObj.consumerGenderTag.length >= 3;

    if (containsAllGenders) {
      return <Body>{getTranslatedProviderTag(ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT)}</Body>;
    }

    return (
      <Body>
        {insertBetween(
          ", ",
          tagsObj.consumerGenderTag.map((tag) =>
            tag === ProviderProfileTagEnum.NONBINARY_CLIENT ? (
              <NonbinaryPopover key={tag}>{getTranslatedProviderTag(tag)}</NonbinaryPopover>
            ) : (
              getTranslatedProviderTag(tag)
            ),
          ),
        )}
      </Body>
    );
  };

  const TrainingPopoverContent = () => (
    <>
      {userSession?.funder.segment === "HC Medicaid-WA" && (
        <Body paragraph>{t("training_information.description.wa", { ns: nsCommonTooltips })}</Body>
      )}
      {userSession?.funder.segment === "HC Medicaid-OR" && (
        <Body paragraph>{t("training_information.description.or", { ns: nsCommonTooltips })}</Body>
      )}
      <Body>
        <Trans
          t={t}
          i18nKey="training_information.description.if_you_have_questions"
          ns={nsCommonTooltips}
          components={{ cntctemail: <InternalLink to={ROUTES.contactUs} target="_blank" /> }}
        />
      </Body>
    </>
  );

  return (
    <>
      <FirstMessageDialog
        recipientId={providerProfile.provider.id}
        recipientFirstName={providerProfile.provider.firstName}
        recipientLastName={providerProfile.provider.lastName}
        recipientRole={providerProfile.provider.role as UserType}
        context={FirstMessageContext.PROVIDER_PROFILE}
        pronouns={providerProfile.tags
          .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.PRONOUN)
          .sort((a, b) => a.order - b.order)
          .map((tagObj) => t(tagObj.tag.toLowerCase(), { ns: nsMedicaidTagsEnums }))}
        isDialogOpen={showMessageModal}
        onCloseDialog={(threadId) => {
          updateProviderProfileAfterFirstMessage(threadId);
          setMessageModal(false);
        }}
      />
      <UserReportResponseBanner view={reportResponseView} setView={setReportResponseView} />
      <Section minimizeBottomGutter textAlign="center">
        <Constraint columns={6}>
          <Avatar
            src={imageUrl}
            alt={
              imageUrl
                ? t("standard.headshot_alt", {
                    ns: nsCommonAlt,
                    name: providerProfile.provider.firstName + providerProfile.provider.lastName.charAt(0),
                  })
                : ""
            }
            style={{
              width: "185px",
              height: "185px",
              margin: "40px auto 24px auto",
              fontSize: "3.2rem",
            }}
          >
            {providerProfile.provider.firstName.charAt(0)}
            {providerProfile.provider.lastName.charAt(0)}
          </Avatar>
          <SectionTitle>
            {providerProfile.provider.firstName}{" "}
            {isAssistiveRoleView()
              ? providerProfile.provider.lastName
              : providerProfile.provider.lastName.charAt(0) + "."}
          </SectionTitle>
          {tagsObj.pronounTags.length > 0 && (
            <BodyEmphasis paragraph>
              {tagsObj.pronounTags.map((tag) => getTranslatedProviderTag(tag)).join(", ")}
            </BodyEmphasis>
          )}

          {!isDrawer &&
            providerProfile.credentials
              .filter((credential) => credential.badge)
              .map((credential, i) => (
                <CredentialBadge
                  name={t(getTranslatedProviderCred(credential).nameKey, { ns: nsMedicaidProviderProfile })}
                  margin="0px auto"
                  key={credential.code + i}
                />
              ))}

          <Body>
            <b>{getTranslatedProviderTag(providerProfile.availability)}</b>{" "}
            {getTranslatedLastUpdated(providerProfile.updatedAt)}
          </Body>
          {!hideActions && (
            <ProfileActionButtons
              setMessageModal={setMessageModal}
              providerProfile={providerProfile}
              isProviderProfileOwner={isProviderProfileOwner}
              handleSave={handleSave}
            />
          )}
        </Constraint>
      </Section>

      {isUserType(UserType.Consumer) && (
        <Section textAlign="center" minimizeBottomGutter>
          <Constraint columns={6}>
            {!isLoadingTravel && (
              <TravelInformation
                translationNamespace={nsMedicaidProviderProfile}
                carTravelDTO={carTravel}
                busTravelDTO={busTravel}
              />
            )}
            {isLoadingTravel && (
              <Section height="100vh">
                <LoadingCircle />
              </Section>
            )}
            <Body>{t("travel_time.description", { ns: nsMedicaidProviderProfile })}</Body>
            <Separator line style={{ padding: "48px 0px" }} />
          </Constraint>
        </Section>
      )}

      <Section sidePadding>
        <Constraint columns={6}>
          <BodyTitle>{t("summary.label", { ns: nsMedicaidProviderProfile })}</BodyTitle>
          <List>
            <IconListItem
              icon={PinLocation1Icon}
              primary={<BodyEmphasis>{t("summary.location.label", { ns: nsMedicaidProviderProfile })}</BodyEmphasis>}
              secondary={
                <Body>
                  {providerProfile.geoDataCityState?.city}, {providerProfile.geoDataCityState?.state}
                </Body>
              }
            />
            <IconListItem
              icon={ChatTranslateIcon}
              primary={<BodyEmphasis>{t("summary.languages.label", { ns: nsMedicaidProviderProfile })}</BodyEmphasis>}
              secondary={<Body>{tagsObj.languageTags.map(getTranslatedProviderTag).join(", ")}</Body>}
            />
            <IconListItem
              icon={FireworksPeopleWatchIcon}
              primary={
                <BodyEmphasis>{t("summary.providers_gender.label", { ns: nsMedicaidProviderProfile })}</BodyEmphasis>
              }
              secondary={<ProviderGenderTags />}
            />
            {tagsObj.pronounTags.length > 0 && (
              <IconListItem
                icon={SingleUserActionsChatIcon}
                primary={
                  <BodyEmphasis>
                    {t("summary.providers_pronouns.label", { ns: nsMedicaidProviderProfile })}
                  </BodyEmphasis>
                }
                secondary={<Body>{tagsObj.pronounTags.map((tag) => getTranslatedProviderTag(tag)).join(", ")}</Body>}
              />
            )}
            <IconListItem
              icon={JobChooseCandidate1Icon}
              primary={
                <BodyEmphasis>
                  {t("summary.preferred_consumer_gender.label_v2", { ns: nsMedicaidProviderProfile })}
                </BodyEmphasis>
              }
              secondary={<PreferredConsumerGenderTags />}
            />
            <IconListItem
              icon={TimeClockCircleAlternateIcon}
              primary={
                <BodyEmphasis>{t("summary.monthly_hours.label", { ns: nsMedicaidProviderProfile })}</BodyEmphasis>
              }
              secondary={
                <Body>
                  {providerProfile.hours}{" "}
                  {providerProfile.hoursFlexible ? t("summary.flexible.label", { ns: nsMedicaidProviderProfile }) : ""}
                </Body>
              }
            />
            <IconListItem
              icon={CalendarIcon}
              primary={<BodyEmphasis>{t("summary.schedule.label", { ns: nsMedicaidProviderProfile })}</BodyEmphasis>}
              secondary={
                <Body style={{ overflowWrap: "anywhere" }}>
                  {providerProfile.schedulingNotes}{" "}
                  {providerProfile.schedulingFlexible
                    ? t("summary.flexible.label", { ns: nsMedicaidProviderProfile })
                    : ""}
                </Body>
              }
            />
          </List>
          <Separator line style={{ padding: "48px 0px" }} />
        </Constraint>
      </Section>

      <Section sidePadding>
        <Constraint columns={6}>
          <BodyTitle gutterBottom>{t("training_information.label", { ns: nsMedicaidProviderProfile })}</BodyTitle>
          <Grid container>
            <Body paragraph style={{ color: CivColors.coreGray, marginRight: "6px" }}>
              {t("training_information.last_updated.label", {
                ns: nsMedicaidProviderProfile,
                date: format(providerProfile.lastTrainingUpdate, "MM/dd/yyyy"),
              })}
            </Body>
            <Popover
              contentTitle={t("popover.training_information.label", { ns: nsCommonTooltips })}
              aria-label={t("popover.training_information.label", { ns: nsCommonTooltips })}
              content={<TrainingPopoverContent />}
              contentId="training-info-popover"
            >
              <QuestionCircleIcon />
            </Popover>
          </Grid>
          {/*WA providers can only have one provider number and it's shown at top of Training Info section*/}
          {isAssistiveRoleView() &&
            isFunderState(FunderState.Washington) &&
            !!providerProfile.providerNumbers?.length && (
              <BodyEmphasis style={{ marginBottom: "16px" }}>
                ProviderOne #: {providerProfile.providerNumbers[0].providerNumber}
              </BodyEmphasis>
            )}
          <Body paragraph>
            {providerProfile.credentials.map((credential, i) => (
              <Box key={credential.code + i}>
                {credential.badge && (
                  <CredentialBadge
                    name={t(getTranslatedProviderCred(credential).nameKey, { ns: nsMedicaidProviderProfile })}
                  />
                )}
                {/*OR providers can have multiple provider numbers and they're shown with associated cred*/}
                {isAssistiveRoleView() &&
                  isFunderState(FunderState.Oregon) &&
                  !!providerProfile.providerNumbers?.length && (
                    <BodyEmphasis style={{ marginBottom: "16px" }}>
                      {getORProviderNumber(credential, providerProfile.providerNumbers)}
                    </BodyEmphasis>
                  )}
                <Body paragraph>
                  {t(getTranslatedProviderCred(credential).descriptionKey, { ns: nsMedicaidProviderProfile })}
                </Body>
              </Box>
            ))}
          </Body>
          <Separator line style={{ padding: "48px 0px" }} />
        </Constraint>
      </Section>

      <Section sidePadding>
        <Constraint columns={6}>
          <BodyTitle paragraph>{t("caregiving_prefs.label", { ns: nsMedicaidProviderProfile })}</BodyTitle>
          <BodyEmphasisHeading>
            {t("caregiving_prefs.care_frequency.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {CareFrequencyOptions.map((cfObj) => (
              <IconListItem
                icon={() => icon(cfObj.value, "typeOfCare")}
                secondary={<Body>{getTranslatedProviderTag(cfObj.value)}</Body>}
                key={cfObj.label}
              />
            ))}
          </List>

          <BodyEmphasisHeading>
            {t("caregiving_prefs.consumer_i_care_for.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {AgeRangeOptions.map((cpObj) => (
              <IconListItem
                icon={() => icon(cpObj.value, "ageRange")}
                secondary={<Body>{getTranslatedProviderTag(cpObj.value)}</Body>}
                key={cpObj.value}
              />
            ))}
          </List>
          <BodyEmphasisHeading>
            {t("caregiving_prefs.care_support_i_provide.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {TypeOfSupportOptions.map((cpObj) => (
              <IconListItem
                icon={() => icon(cpObj.value, "typeOfSupport")}
                secondary={<Body>{getTranslatedProviderTag(cpObj.value)}</Body>}
                key={cpObj.value}
              />
            ))}
          </List>
          <Separator line style={{ padding: "48px 0px" }} />
        </Constraint>
      </Section>

      <Section sidePadding>
        <Constraint columns={6}>
          <BodyTitle paragraph>{t("care_skills.label", { ns: nsMedicaidProviderProfile })}</BodyTitle>
          <BodyEmphasisHeading>
            {t("skills_and_experience.general_care.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {GeneralSkillsOptionsWithoutTooltips.map((gsObj) => (
              <IconListItem
                icon={() => icon(gsObj.value, "generalSkills")}
                secondary={<Body>{getTranslatedProviderTag(gsObj.value)}</Body>}
                key={gsObj.label}
              />
            ))}
            <IconListItem
              icon={() => icon(ProviderProfileTagEnum.NURSE_DELEGATION, "generalSkills")}
              secondary={
                <NurseDelegationPopover>
                  <Body>{getTranslatedProviderTag(ProviderProfileTagEnum.NURSE_DELEGATION)}</Body>
                </NurseDelegationPopover>
              }
            />
          </List>
          <BodyEmphasisHeading>
            {t("skills_and_experience.movement.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {MovementSkillsOptions.map((msObj) => (
              <IconListItem
                icon={() => icon(msObj.value, "movementSkills")}
                secondary={<Body>{getTranslatedProviderTag(msObj.value)}</Body>}
                key={msObj.label}
              />
            ))}
          </List>
          <BodyEmphasisHeading>
            {t("skills_and_experience.transportation.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {TransportSkillsOptions.map((tsObj) => (
              <IconListItem
                icon={() => icon(tsObj.value, "transportSkills")}
                secondary={<Body>{getTranslatedProviderTag(tsObj.value)}</Body>}
                key={tsObj.label}
              />
            ))}
          </List>
          <Separator line style={{ padding: "48px 0px" }} />
        </Constraint>
      </Section>

      <Section sidePadding>
        <Constraint columns={6}>
          <BodyTitle paragraph>{t("work_environment.label", { ns: nsMedicaidProviderProfile })}</BodyTitle>
          <BodyEmphasisHeading>
            {t("work_environment.general.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {[
              {
                value: ProviderProfileTagEnum.MULTIGENERATIONAL_HOUSEHOLDS,
                label: t("multigenerational_households", { ns: nsMedicaidTagsEnums }),
              },
              {
                value: ProviderProfileTagEnum.MULTILINGUAL_HOUSEHOLDS,
                label: t("multilingual_households", { ns: nsMedicaidTagsEnums }),
              },
              {
                value: ProviderProfileTagEnum.LGBTQIA_HOUSEHOLDS,
                label: (
                  <LgbtPopover>
                    <Body>{t("lgbtqia_households", { ns: nsMedicaidTagsEnums })}</Body>
                  </LgbtPopover>
                ),
              },
            ].map((envObj, i) => (
              <IconListItem
                icon={() => icon(envObj.value, "generalWorkEnvironment")}
                secondary={<Body>{envObj.label}</Body>}
                key={i}
              />
            ))}
          </List>
          <BodyEmphasisHeading>
            {t("work_environment.scents_smoking_pref.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {WorkEnvOptions.map((envObj) => (
              <IconListItem
                icon={() => icon(envObj.value, "workEnvironment")}
                secondary={<Body>{getTranslatedProviderTag(envObj.value)}</Body>}
                key={envObj.label}
              />
            ))}
          </List>
          <Box p={1} />
          <BodyEmphasisHeading>
            {t("work_environment.pets_comfortable_with.label", { ns: nsMedicaidProviderProfile })}
          </BodyEmphasisHeading>
          <List>
            {PetOptions.map((petObj) => (
              <IconListItem
                icon={() => icon(petObj.value, "pets")}
                secondary={<Body>{getTranslatedProviderTag(petObj.value)}</Body>}
                key={petObj.label}
              />
            ))}
          </List>
          <Separator line style={{ padding: "48px 0px" }} />
        </Constraint>
      </Section>

      <Section sidePadding>
        <Constraint columns={6}>
          <BodyTitle>{t("caregiving_superpowers.label", { ns: nsMedicaidProviderProfile })}</BodyTitle>
          <List>
            {tagsObj.specializedSkillTags.map((option) => (
              <IconListItem
                icon={CheckBadgeIcon}
                secondary={<Body>{getTranslatedProviderTag(option)}</Body>}
                key={option}
              />
            ))}
          </List>
          <Box p={1} />
          <Box display="flex" alignItems="center">
            <BodyTitle paragraph>{t("hobbies_and_further_info.label", { ns: nsMedicaidProviderProfile })}</BodyTitle>
          </Box>
          <List>
            <IconListItem
              icon={CheckBadgeIcon}
              secondary={<Body style={{ overflowWrap: "anywhere" }}>{providerProfile.furtherIntroNotes}</Body>}
            />
          </List>
        </Constraint>
      </Section>

      {!hideActions && (
        <Section sidePadding pt={4} textAlign="center">
          <Constraint columns={6}>
            <ProfileActionButtons
              setMessageModal={setMessageModal}
              providerProfile={providerProfile}
              isProviderProfileOwner={isProviderProfileOwner}
              handleSave={handleSave}
            />
          </Constraint>
        </Section>
      )}

      {profileId && !hideActions && !isDrawer && (
        <Section pt={2} pb={2}>
          <Constraint columns={6}>
            <Grid container justifyContent="center" spacing={desktopSize ? 4 : 2}>
              {providerListOrigin === ProviderListOrigin.FIND_PROVIDERS && (
                <Grid item>
                  <LinkStyledAsButton variant="outlined" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
                    {t("button.back_to_results", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
              )}
              {providerListOrigin === ProviderListOrigin.INBOX && (
                <Grid item>
                  <LinkStyledAsButton variant="outlined" component={Link} to={MEDICAID_ACCOUNT_ROUTES.inbox}>
                    {t("button.back_to_inbox", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
              )}
              {providerListOrigin === ProviderListOrigin.FIND_PROVIDERS && providerIdList.length > 1 && (
                <Grid item>
                  <LinkStyledAsButton
                    variant="outlined"
                    sx={{ marginLeft: "12px" }}
                    component={Link}
                    to={generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, {
                      id: getNextId(profileId, providerIdList),
                    })}
                  >
                    {t("button.next", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
              )}
            </Grid>
          </Constraint>
        </Section>
      )}

      {!isProviderProfileOwner && !hideActions ? (
        <Section>
          <Constraint columns={6}>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Flag1Icon width="24px" height="24px" color="primary" />
              <MuiLink component="button" onClick={() => setOpenReportModal(true)}>
                <Body style={{ marginLeft: 9 }}>{t("button.report_user", { ns: nsCommonFormsBtns })}</Body>
              </MuiLink>
              <UserReportModal
                subjectId={providerProfile.provider.id}
                subjectUserType={providerProfile.provider.role as UserType}
                subjectFirstName={providerProfile.provider.firstName}
                subjectLastName={providerProfile.provider.lastName}
                reportSource={ReportSourceEnum.PROVIDER_PROFILE}
                open={openReportModal}
                onClose={() => setOpenReportModal(false)}
              />
            </Box>
          </Constraint>
        </Section>
      ) : (
        <Box height={80} />
      )}
    </>
  );
};

const ProfileActionButtons = (props: {
  setMessageModal: Dispatch<SetStateAction<boolean>>;
  isProviderProfileOwner?: boolean | "";
  handleSave: () => Promise<void>;
  providerProfile: MedicaidProviderProfile_DTO_Response_ProfileDetails;
}) => {
  const { setMessageModal, isProviderProfileOwner, handleSave, providerProfile } = props;
  const { isUserType, impersonatorIsUserType, hasPermission } = useUserSession();
  const { t } = useTranslation();
  const [msgBtnWasClicked, setMsgBtnWasClicked] = useState(false);
  const { useMainSnackbar, closeSnackbar } = useNotification();

  const enableMessageSnackbar = () => {
    closeSnackbar();
    setMsgBtnWasClicked(true);
  };

  if (isProviderProfileOwner) {
    return (
      <Box p={3}>
        <LinkStyledAsButton
          variant="contained"
          component={Link}
          to={
            isUserType(UserType.Provider)
              ? Medicaid_Provider_Dashboard_Routes.editMyProfile
              : Medicaid_Proxy_Provider_Routes.editMyProfile
          }
        >
          {t("button.edit_profile", { ns: nsCommonFormsBtns })}
        </LinkStyledAsButton>
      </Box>
    );
  }
  return (
    <Box display="flex" justifyContent="center" p={3}>
      <Button variant="contained" type="button" sx={{ marginRight: "12px" }} onClick={handleSave}>
        {providerProfile.bookmarked
          ? t("button.unsave", { ns: nsCommonFormsBtns })
          : t("button.save", { ns: nsCommonFormsBtns })}
      </Button>
      {hasPermission(Permission.MESSAGING_ACCESS) &&
        !impersonatorIsUserType(UserType.UserSupportAgent) &&
        providerProfile.threadIds && (
          <>
            <MessageFollowUpButton
              style={{ marginLeft: 12 }}
              onMessageClick={() => {
                enableMessageSnackbar();
                setMessageModal(true);
              }}
              threadIds={providerProfile.threadIds}
              otherUserId={providerProfile.provider.id}
            />
            <Snackbar
              isEnabled={msgBtnWasClicked && !useMainSnackbar}
              onCloseCallback={() => setMsgBtnWasClicked(false)}
            />
          </>
        )}
    </Box>
  );
};

export default ProviderProfileDetailsContent;
